import React from "react"

export default function PC() {
  return (
    <svg
      width="414"
      height="530"
      xmlns="http://www.w3.org/2000/svg"
      className="pc-screen-svg"
    >
      <defs>
        <clipPath id="clip0">
          <rect
            id="svg_1"
            fill="white"
            transform="matrix(-1,0,0,1,632,0) "
            height="588"
            width="632"
          />
        </clipPath>
        <clipPath id="clip1">
          <rect
            id="svg_2"
            fill="white"
            transform="matrix(0.866025,-0.5,2.20305e-8,1,86,343.785) "
            height="25.1603"
            width="41.5692"
          />
        </clipPath>
        <clipPath id="clip2">
          <rect
            id="svg_3"
            fill="white"
            transform="matrix(0.866025,-0.5,2.20305e-8,1,86,343.785) "
            height="25.1603"
            width="41.5692"
          />
        </clipPath>
        <clipPath id="clip3">
          <rect
            id="svg_4"
            fill="white"
            transform="matrix(-0.866025,0.5,2.20305e-8,1,122.594,403) "
            height="39.737"
            width="39.945"
          />
        </clipPath>
        <clipPath id="clip4">
          <rect
            id="svg_5"
            fill="white"
            transform="matrix(-0.866025,0.5,2.20305e-8,1,122.594,403) "
            height="39.737"
            width="39.945"
          />
        </clipPath>
      </defs>
      <g>
        <title>background</title>
        <rect
          fill="none"
          id="canvas_background"
          height="532"
          width="416"
          y="-1"
          x="-1"
        />
      </g>
      <g>
        <title>Layer 1</title>
        <g id="Graphic">
          <g id="svg_6">
            <g id="PC 1">
              <g id="Monitor 1">
                <g id="XMLID 274">
                  <g id="XMLID 285">
                    <path
                      fill="#375AD7"
                      d="m215.639,413.605l-42.205,-24.456c-4.407,-2.539 -6.544,-5.746 -6.544,-9.087c0,-3.341 0,-5.88 0,-5.88l50.886,-28.865c2.27,-1.337 4.14,-4.544 4.14,-7.35l0,-52.119l73.725,42.63l0,52.119c0,2.672 -1.87,5.88 -4.141,7.35l-44.341,25.524c-8.682,5.078 -22.839,5.078 -31.52,0.134z"
                      id="XMLID 286"
                    />
                  </g>
                  <g opacity="0.5" id="XMLID 283">
                    <path
                      fill="black"
                      d="m215.639,413.605l-42.205,-24.456c-4.407,-2.539 -6.544,-5.746 -6.544,-9.087c0,-3.341 0,-5.88 0,-5.88l50.886,-28.865c2.27,-1.337 4.14,-4.544 4.14,-7.35l0,-52.119l73.725,42.63l0,52.119c0,2.672 -1.87,5.88 -4.141,7.35l-44.341,25.524c-8.682,5.078 -22.839,5.078 -31.52,0.134z"
                      opacity="0.5"
                      id="XMLID 284"
                    />
                  </g>
                  <path
                    fill="#3252C4"
                    d="m277.878,389.951l0,5.88l13.489,-7.751c2.27,-1.336 4.14,-4.543 4.14,-7.35l0,-52.118l-14.023,-8.152c-0.267,3.609 -0.668,7.217 -0.935,10.825l9.883,5.746l0,40.759c0,2.673 -1.87,6.014 -4.14,7.35l-8.414,4.811z"
                    id="XMLID 282"
                  />
                  <path
                    fill="white"
                    d="m277.877,389.951l0,5.88l13.49,-7.751c2.27,-1.337 4.14,-4.544 4.14,-7.35l0,-52.118l-14.024,-8.152c-0.267,3.608 -0.668,7.216 -0.935,10.824l9.884,5.747l0,40.759c0,2.672 -1.87,6.013 -4.141,7.35l-8.414,4.811z"
                    opacity="0.7"
                    id="XMLID 281"
                  />
                  <path
                    fill="#375AD7"
                    d="m290.565,377.791c0,2.672 -1.869,5.88 -4.14,7.35l-39.133,22.584c-8.681,5.078 -22.838,5.078 -31.519,0l-42.205,-24.455c-8.681,-5.079 -8.681,-13.23 0,-18.175l38.999,-22.718c2.271,-1.336 4.14,-4.544 4.14,-7.35l0,-40.759l73.725,42.63l0,40.893l0.133,0z"
                    id="XMLID 280"
                  />
                  <path
                    fill="white"
                    d="m290.565,377.791c0,2.673 -1.869,5.88 -4.14,7.35l-39.133,22.585c-8.681,5.078 -22.838,5.078 -31.519,0l-42.205,-24.456c-8.681,-5.078 -8.681,-13.23 0,-18.174l38.999,-22.719c2.271,-1.336 4.14,-4.543 4.14,-7.35l0,-40.759l73.725,42.63l0,40.893l0.133,0z"
                    opacity="0.3"
                    id="XMLID 279"
                  />
                  <path
                    fill="#375AD7"
                    d="m204.019,347.321l8.548,-4.944c2.27,-1.336 4.14,-4.544 4.14,-7.35l0,-40.759l73.725,42.63l0,40.759c0,2.673 -1.87,5.88 -4.141,7.35l-8.414,4.811l-73.858,-42.497z"
                    opacity="0.7"
                    id="XMLID 278"
                  />
                  <path
                    fill="white"
                    d="m204.019,347.322l8.548,-4.945c2.27,-1.336 4.14,-4.543 4.14,-7.35l0,-40.759l73.725,42.63l0,40.759c0,2.673 -1.87,5.88 -4.141,7.35l-8.414,4.811l-73.858,-42.496z"
                    opacity="0.6"
                    id="XMLID 277"
                  />
                  <path
                    fill="#375AD7"
                    d="m166.89,374.182c0,0 0,2.539 0,5.88c0,3.341 2.137,6.548 6.544,9.087l42.205,24.456c4.14,2.405 9.616,3.608 15.092,3.741l0,-5.88c-5.476,-0.133 -10.818,-1.336 -15.092,-3.741l-42.205,-24.456c-4.407,-2.539 -6.544,-5.88 -6.544,-9.087z"
                    id="XMLID 276"
                  />
                  <path
                    fill="black"
                    d="m166.89,374.182c0,0 0,2.539 0,5.88c0,3.341 2.137,6.549 6.545,9.088l42.204,24.455c4.141,2.406 9.617,3.608 15.092,3.742l0,-5.88c-5.475,-0.134 -10.818,-1.336 -15.092,-3.742l-42.204,-24.455c-4.408,-2.539 -6.545,-5.88 -6.545,-9.088z"
                    opacity="0.2"
                    id="XMLID 275"
                  />
                </g>
                <path
                  fill="#375AD7"
                  d="m408.898,433.918c-2.671,1.603 -7.346,1.47 -10.15,-0.134c-36.195,-20.981 -248.954,-143.926 -285.282,-164.774c-2.938,-1.737 -5.209,-5.746 -5.209,-9.087l0,-249.9c0,-3.34088 2.271,-7.34997 4.942,-8.95361c2.671,-1.60364 7.346,-1.47 10.151,0.13364l285.281,164.90697c2.938,1.738 5.209,5.747 5.209,9.088l0,249.766c0,3.474 -2.137,7.35 -4.942,8.954z"
                  id="XMLID 273"
                />
                <path
                  fill="white"
                  d="m408.898,433.918c-2.671,1.603 -7.346,1.47 -10.15,-0.134c-36.195,-20.981 -248.954,-143.926 -285.282,-164.774c-2.938,-1.737 -5.209,-5.746 -5.209,-9.087l0,-249.9c0,-3.34088 2.271,-7.34997 4.942,-8.95361c2.671,-1.60364 7.346,-1.47 10.151,0.13364l285.281,164.90697c2.938,1.738 5.209,5.747 5.209,9.088l0,249.766c0,3.474 -2.137,7.35 -4.942,8.954z"
                  opacity="0.3"
                  id="XMLID 272"
                />
                <path
                  fill="#27DEBF"
                  d="m398.748,433.784l-285.282,-164.774c-2.938,-1.737 -5.208,-5.746 -5.208,-9.087l0,-249.8998c0,-3.34092 2.404,-4.67728 5.208,-3.07364l285.282,164.77344c2.938,1.737 5.209,5.747 5.209,9.087l0,249.767c0.133,3.474 -2.271,4.811 -5.209,3.207z"
                  id="XMLID 271"
                />
                <path
                  fill="#375AD7"
                  d="m398.748,433.784l-285.282,-164.774c-2.938,-1.737 -5.208,-5.746 -5.208,-9.087l0,-249.8998c0,-3.34092 2.404,-4.67728 5.208,-3.07364l285.282,164.77344c2.938,1.737 5.209,5.747 5.209,9.087l0,249.767c0.133,3.474 -2.271,4.811 -5.209,3.207z"
                  id="XMLID 270"
                />
                <path
                  fill="white"
                  d="m398.748,433.784l-285.282,-164.774c-2.938,-1.737 -5.208,-5.746 -5.208,-9.087l0,-249.8998c0,-3.34092 2.404,-4.67728 5.208,-3.07364l285.282,164.77344c2.938,1.737 5.209,5.747 5.209,9.087l0,249.767c0.133,3.474 -2.271,4.811 -5.209,3.207z"
                  opacity="0.5"
                  id="XMLID 269"
                />
                <path
                  fill="#375AD7"
                  d="m399.549,434.185c2.939,1.336 6.945,1.203 9.483,-0.134c2.671,-1.603 4.942,-5.612 4.942,-8.953l0,-28.064l-9.884,5.747l0,28.063c0,2.94 -2.003,4.277 -4.541,3.341z"
                  id="XMLID 268"
                />
                <path
                  fill="white"
                  d="m399.549,434.186c2.939,1.336 6.945,1.203 9.483,-0.134c2.671,-1.603 4.942,-5.613 4.942,-8.953l0,-28.064l-9.884,5.746l0,28.064c0,2.94 -2.003,4.276 -4.541,3.341z"
                  opacity="0.7"
                  id="XMLID 267"
                />
                <path
                  fill="#375AD7"
                  d="m404.09,402.646l0,28.064c0,3.341 -2.404,4.677 -5.208,3.074l-285.282,-164.774c-2.938,-1.737 -5.209,-5.746 -5.209,-9.087l0,-28.064l295.699,170.787z"
                  id="XMLID 266"
                />
                <g opacity="0.5" id="XMLID 265">
                  <path
                    fill="white"
                    d="m404.09,402.646l0,28.064c0,3.341 -2.404,4.677 -5.208,3.074l-285.282,-164.774c-2.938,-1.737 -5.209,-5.746 -5.209,-9.087l0,-28.064l295.699,170.787z"
                    opacity="0.5"
                    id="Vector"
                  />
                </g>
                <path
                  fill="#DDF1FF"
                  d="m394.207,381.933l0,-197.782c0,-2.004 -1.469,-4.41 -3.205,-5.479l-269.789,-155.8199c-1.736,-1.0691 -3.205,-0.1337 -3.205,1.8709l0,197.782c0,2.004 1.469,4.41 3.205,5.479l269.789,155.82c1.736,0.935 3.205,0.134 3.205,-1.871z"
                  id="XMLID 264"
                />
                <path
                  fill="#375AD7"
                  d="m256.107,328.612c2.671,1.604 4.942,5.346 4.942,8.553c0,3.207 -2.271,4.41 -4.942,2.807c-2.671,-1.604 -4.942,-5.346 -4.942,-8.553c0,-3.074 2.271,-4.41 4.942,-2.807z"
                  id="XMLID 263"
                />
                <path
                  fill="white"
                  d="m256.107,328.612c2.671,1.604 4.942,5.346 4.942,8.553c0,3.207 -2.271,4.41 -4.942,2.806c-2.671,-1.603 -4.942,-5.345 -4.942,-8.552c0,-3.074 2.271,-4.41 4.942,-2.807z"
                  opacity="0.5"
                  id="XMLID 262"
                />
                <path
                  fill="#375AD7"
                  d="m402.488,175.732c0.934,1.604 1.469,3.475 1.602,5.212l0,221.703l9.884,-5.747l0,-221.702c0,-1.738 -0.668,-3.609 -1.603,-5.212l-9.883,5.746z"
                  id="XMLID 261"
                />
                <path
                  fill="white"
                  d="m402.488,175.732c0.934,1.604 1.469,3.475 1.602,5.212l0,221.703l9.884,-5.746l0,-221.703c0,-1.737 -0.668,-3.608 -1.603,-5.212l-9.883,5.746z"
                  opacity="0.7"
                  id="XMLID 260"
                />
              </g>
            </g>
            <g id="Keyboard 2">
              <g id="Keyboard 3">
                <g id="XMLID 252">
                  <path
                    fill="#375AD7"
                    d="m14.633,398.771c0,-0.802 0.534,-1.737 1.602,-2.272l66.913,-38.621c2.271,-1.336 5.743,-1.336 8.014,0l204.345,118.269c1.068,0.668 1.602,1.47 1.602,2.271c0,0.802 0,6.148 0,7.083c0,0.802 -0.534,1.737 -1.602,2.272l-66.913,38.621c-2.271,1.336 -5.743,1.203 -8.014,0l-204.345,-118.001c-1.068,-0.668 -1.602,-1.47 -1.602,-2.272c0,-0.935 0,-6.548 0,-7.35z"
                    id="XMLID 258"
                  />
                  <path
                    fill="white"
                    d="m14.633,398.771c0,-0.802 0.534,-1.737 1.602,-2.272l66.913,-38.621c2.271,-1.336 5.743,-1.336 8.014,0l204.345,118.269c1.068,0.668 1.602,1.47 1.602,2.271c0,0.802 0,6.148 0,7.083c0,0.802 -0.534,1.737 -1.602,2.272l-66.913,38.621c-2.271,1.336 -5.743,1.203 -8.014,0l-204.345,-118.001c-1.068,-0.668 -1.602,-1.47 -1.602,-2.272c0,-0.935 0,-6.548 0,-7.35z"
                    opacity="0.5"
                    id="XMLID 257"
                  />
                  <path
                    fill="#375AD7"
                    d="m228.594,519.178l66.913,-38.488c2.271,-1.336 2.271,-3.341 0,-4.543l-204.345,-118.269c-2.27,-1.336 -5.743,-1.336 -8.013,0l-66.913,38.621c-2.271,1.337 -2.271,3.341 0,4.678l204.345,118.001c2.27,1.202 5.876,1.202 8.013,0z"
                    id="XMLID 256"
                  />
                  <path
                    fill="white"
                    d="m228.594,519.178l66.913,-38.488c2.271,-1.336 2.271,-3.341 0,-4.543l-204.345,-118.269c-2.27,-1.336 -5.743,-1.336 -8.013,0l-66.913,38.621c-2.271,1.337 -2.271,3.341 0,4.678l204.345,118.001c2.27,1.202 5.876,1.202 8.013,0z"
                    opacity="0.3"
                    id="XMLID 255"
                  />
                  <path
                    fill="#375AD7"
                    d="m224.855,520.113l0,7.216c1.335,0 2.671,-0.401 3.739,-0.935l66.913,-38.621c1.069,-0.668 1.603,-1.47 1.603,-2.272l0,-7.083c0,0.802 -0.534,1.604 -1.603,2.272l-66.913,38.487c-1.068,0.535 -2.404,0.936 -3.739,0.936z"
                    id="XMLID 254"
                  />
                  <path
                    fill="white"
                    d="m224.854,520.114l0,7.216c1.336,0 2.671,-0.401 3.74,-0.935l66.913,-38.621c1.068,-0.668 1.602,-1.47 1.602,-2.272l0,-7.083c0,0.802 -0.534,1.604 -1.602,2.272l-66.913,38.487c-1.069,0.535 -2.404,0.936 -3.74,0.936z"
                    opacity="0.7"
                    id="XMLID 253"
                  />
                </g>
                <g id="Group">
                  <path
                    fill="#8ABCF0"
                    d="m139.377,407.591l-7.613,4.41c-0.133,0.134 -0.401,0.134 -0.534,0.134l-7.079,-3.876l8.281,-4.677l6.945,4.009z"
                    id="Vector_2"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m130.295,402.245l-7.613,4.41c-0.133,0 -0.267,0.134 -0.401,0.134l-0.267,0l-6.811,-3.875l8.147,-4.678l6.945,4.009z"
                    id="Vector_3"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m154.87,416.545l-7.613,4.41c-0.133,0 -0.267,0.133 -0.4,0.133l-11.353,-6.414l8.147,-4.677l11.219,6.548z"
                    id="Vector_4"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m164.085,421.89l-7.613,4.41c-0.133,0 -0.267,0.134 -0.401,0.134l-7.078,-3.876l8.147,-4.677l6.945,4.009z"
                    id="Vector_5"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m206.023,491.247l-7.079,-3.875l8.147,-4.678l6.945,4.009l-7.613,4.41c-0.133,0.134 -0.267,0.134 -0.4,0.134z"
                    id="Vector_6"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m173.167,427.102l-7.613,4.41c-0.133,0.133 -0.401,0.133 -0.534,0.133l-7.079,-3.875l8.147,-4.677l7.079,4.009z"
                    id="Vector_7"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m122.148,409.595l6.945,4.01l-7.613,4.41c-0.134,0.133 -0.267,0.133 -0.534,0.133l-7.079,-3.875l8.281,-4.678z"
                    id="Vector_8"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m147.791,445.544l-7.078,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c-0.134,0.134 -0.267,0.134 -0.401,0.134z"
                    id="Vector_9"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m111.731,412.803l-7.079,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c0,0.134 -0.267,0.134 -0.4,0.134z"
                    id="Vector_10"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m156.873,450.889l-7.078,-3.875l8.147,-4.678l6.945,4.009l-7.613,4.41c0,0.134 -0.267,0.134 -0.401,0.134z"
                    id="Vector_11"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m166.088,456.235l-7.079,-3.876l8.148,-4.677l6.945,4.009l-7.613,4.41c0,0.134 -0.267,0.134 -0.401,0.134z"
                    id="Vector_12"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m129.36,434.987l-14.825,-8.419l18.565,-10.558l7.879,4.544l-9.749,5.613c-0.134,0.133 -0.267,0.267 -0.267,0.401c0,0.133 0.133,0.267 0.267,0.4l6.143,3.475l-7.612,4.41c0,0.134 -0.268,0.134 -0.401,0.134z"
                    id="Vector_13"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m191.599,437.793l-7.613,4.41c-0.134,0 -0.267,0.133 -0.401,0.133l-7.079,-3.875l8.148,-4.677l6.945,4.009z"
                    id="Vector_14"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m138.575,440.332l-7.078,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c-0.134,0.134 -0.267,0.134 -0.401,0.134z"
                    id="Vector_15"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m243.152,488.709l-7.079,-3.876l8.147,-4.677l6.946,4.009l-7.613,4.41c-0.134,0.134 -0.267,0.134 -0.401,0.134z"
                    id="Vector_16"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m265.056,480.156l-7.613,4.41c-0.134,0 -0.267,0.133 -0.401,0.133l-0.267,0l-6.811,-3.875l8.147,-4.677l6.945,4.009z"
                    id="Vector_17"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m230.33,493.386l-0.267,0l-6.811,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c0,0 -0.267,0.134 -0.401,0.134z"
                    id="Vector_18"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m224.32,501.804l-7.078,-3.875l8.147,-4.677l6.945,4.009l-7.613,4.41c-0.133,0.133 -0.267,0.133 -0.401,0.133z"
                    id="Vector_19"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m255.84,474.81l-7.613,4.41c-0.134,0 -0.267,0.134 -0.401,0.134l-7.078,-3.875l8.147,-4.678l6.945,4.009z"
                    id="Vector_20"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m215.105,496.592l-7.079,-3.875l8.147,-4.677l6.945,4.009l-7.613,4.41c0,0 -0.133,0.133 -0.4,0.133z"
                    id="Vector_21"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m228.327,458.908l-7.613,4.41c-0.133,0 -0.267,0.133 -0.4,0.133l-7.079,-3.875l8.147,-4.678l6.945,4.01z"
                    id="Vector_22"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m219.111,453.696l-7.613,4.41c-0.133,0.133 -0.267,0.133 -0.534,0.133l-7.078,-3.875l8.147,-4.677l7.078,4.009z"
                    id="Vector_23"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m209.896,448.35l-7.613,4.41c-0.134,0.134 -0.401,0.134 -0.535,0.134l-7.078,-3.876l8.147,-4.677l7.079,4.009z"
                    id="Vector_24"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m237.542,464.253l-7.612,4.41c-0.134,0.133 -0.401,0.133 -0.535,0.133l-7.078,-3.875l8.147,-4.677l7.078,4.009z"
                    id="Vector_25"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m200.813,443.005l-7.612,4.41c0,0 -0.267,0.133 -0.401,0.133l-7.079,-3.875l8.147,-4.677l6.945,4.009z"
                    id="Vector_26"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m246.625,469.598l-7.613,4.41c-0.134,0 -0.267,0.134 -0.401,0.134l-7.079,-3.875l8.147,-4.678l6.946,4.009z"
                    id="Vector_27"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m182.383,432.447l-7.613,4.41c-0.134,0 -0.267,0.134 -0.401,0.134l-7.078,-3.875l8.147,-4.678l6.945,4.009z"
                    id="Vector_28"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m159.41,431.244l-7.613,4.41c-0.133,0.134 -0.4,0.134 -0.534,0.134l-0.267,0l-6.812,-3.875l8.148,-4.678l7.078,4.009z"
                    id="Vector_29"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m109.994,390.619l-7.613,4.41c-0.133,0.134 -0.4,0.134 -0.534,0.134l-7.079,-3.875l8.147,-4.678l7.079,4.009z"
                    id="Vector_30"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m100.778,385.274l-7.612,4.41c-0.134,0.133 -0.268,0.133 -0.535,0.133l-7.078,-3.875l8.147,-4.677l7.078,4.009z"
                    id="Vector_31"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m168.626,436.456l-7.613,4.41c-0.133,0.134 -0.401,0.134 -0.534,0.134l-7.079,-3.875l8.147,-4.678l7.079,4.009z"
                    id="Vector_32"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m91.562,379.929l-7.612,4.41c-0.134,0.133 -0.401,0.133 -0.535,0.133l-7.078,-3.875l8.147,-4.678l7.078,4.01z"
                    id="Vector_33"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m142.182,430.443l-0.267,0l-6.812,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c-0.133,0.134 -0.267,0.134 -0.4,0.134z"
                    id="Vector_34"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m99.576,396.633l-7.613,4.41c-0.133,0.134 -0.4,0.134 -0.534,0.134l-7.079,-3.876l8.148,-4.677l7.078,4.009z"
                    id="Vector_35"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m81.278,385.942l-7.613,4.41c-0.133,0.134 -0.4,0.134 -0.534,0.134l-7.078,-3.876l8.147,-4.677l7.078,4.009z"
                    id="Vector_36"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m82.214,395.831l-7.079,-3.875l8.148,-4.678l6.945,4.009l-7.613,4.41c0,0 -0.134,0.134 -0.401,0.134z"
                    id="Vector_37"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m54.033,391.154c-0.134,0 -0.267,0.133 -0.401,0.133l-0.267,0l-6.811,-3.875l18.564,-10.558l6.945,4.01l-18.03,10.29z"
                    id="Vector_38"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m177.842,441.802l-7.613,4.41c-0.134,0.133 -0.401,0.133 -0.534,0.133l-0.267,0l-6.812,-3.875l8.147,-4.677l7.079,4.009z"
                    id="Vector_39"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m71.93,401.845l-0.267,0l-6.812,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c-0.133,0 -0.267,0.134 -0.4,0.134z"
                    id="Vector_40"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m62.714,396.5l-7.079,-3.876l8.148,-4.677l6.945,4.009l-7.613,4.41c0,0.134 -0.267,0.134 -0.401,0.134z"
                    id="Vector_41"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m223.786,468.262l-7.613,4.41c-0.133,0.134 -0.4,0.134 -0.534,0.134l-7.079,-3.876l8.148,-4.677l7.078,4.009z"
                    id="Vector_42"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m242.083,478.953l-7.612,4.41c-0.134,0.134 -0.401,0.134 -0.535,0.134l-0.267,0l-6.811,-3.876l8.147,-4.677l7.078,4.009z"
                    id="Vector_43"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m212.032,482.695l-7.078,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c0,0.134 -0.267,0.134 -0.401,0.134z"
                    id="Vector_44"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m221.115,488.04l-7.079,-3.875l8.147,-4.677l6.945,4.009l-7.612,4.41c0,0.133 -0.134,0.133 -0.401,0.133z"
                    id="Vector_45"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m202.818,477.483l-0.268,0l-6.811,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c-0.133,0 -0.267,0.134 -0.4,0.134z"
                    id="Vector_46"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m187.058,447.147l-7.613,4.41c-0.134,0.134 -0.267,0.134 -0.534,0.134l-0.268,0l-6.811,-3.876l8.147,-4.677l7.079,4.009z"
                    id="Vector_47"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m184.52,466.792l-7.079,-3.875l8.147,-4.678l6.945,4.009l-7.613,4.41c-0.133,0.134 -0.4,0.134 -0.4,0.134z"
                    id="Vector_48"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m193.602,472.137l-7.079,-3.875l8.147,-4.677l6.945,4.009l-7.613,4.41c0,0.133 -0.133,0.133 -0.4,0.133z"
                    id="Vector_49"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m205.354,457.705l-7.612,4.41c-0.134,0.133 -0.401,0.133 -0.535,0.133l-7.078,-3.875l8.147,-4.677l7.078,4.009z"
                    id="Vector_50"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m232.868,473.607l-7.613,4.411c-0.134,0.133 -0.401,0.133 -0.534,0.133l-7.079,-3.875l8.147,-4.678l7.079,4.009z"
                    id="Vector_51"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m196.14,452.359l-7.613,4.41c-0.134,0.134 -0.401,0.134 -0.534,0.134l-7.079,-3.876l8.147,-4.677l7.079,4.009z"
                    id="Vector_52"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m214.57,463.05l-7.613,4.41c-0.133,0.134 -0.4,0.134 -0.534,0.134l-0.267,0l-6.811,-3.876l8.147,-4.677l7.078,4.009z"
                    id="Vector_53"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m175.304,461.58l-7.079,-3.875l8.281,-4.678l6.945,4.009l-7.613,4.41c-0.267,0 -0.4,0.134 -0.534,0.134z"
                    id="Vector_54"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m121.079,397.034l-7.613,4.41c-0.133,0 -0.267,0.133 -0.4,0.133l-7.079,-3.875l8.147,-4.678l6.945,4.01z"
                    id="Vector_55"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m267.193,481.492l6.945,4.009l-7.613,4.41c-0.134,0 -0.267,0.134 -0.401,0.134l-0.267,0l-6.811,-3.876l8.147,-4.677z"
                    id="Vector_56"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m253.436,485.501l10.284,6.014l-7.613,4.41c-0.133,0 -0.267,0.133 -0.4,0.133l-0.267,0l-10.151,-5.88l8.147,-4.677z"
                    id="Vector_57"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m278.411,473.34l8.415,4.811l-7.613,4.41c-0.134,0.133 -0.401,0.133 -0.534,0.133l-8.548,-4.811l8.28,-4.543z"
                    id="Vector_58"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m234.738,498.597l8.414,4.811l-7.613,4.41c-0.134,0.134 -0.401,0.134 -0.534,0.134l-8.548,-4.811l8.281,-4.544z"
                    id="Vector_59"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m240.748,490.044l12.688,7.35l-7.613,4.41c-0.133,0 -0.267,0.134 -0.4,0.134l-0.268,0l-12.554,-7.216l8.147,-4.678z"
                    id="Vector_60"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m264.121,474.275l-7.079,-3.875l8.147,-4.677l6.945,4.009l-7.613,4.41c-0.133,0.133 -0.4,0.133 -0.4,0.133z"
                    id="Vector_61"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m232.734,509.422l-7.612,4.41c-0.134,0.133 -0.268,0.133 -0.535,0.133l-10.417,-5.88l8.147,-4.677l10.417,6.014z"
                    id="Vector_62"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m254.905,468.93l-7.079,-3.875l8.147,-4.678l6.945,4.01l-7.612,4.41c-0.134,0.133 -0.268,0.133 -0.401,0.133z"
                    id="Vector_63"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m214.17,445.41l-0.267,0l-6.812,-3.875l8.148,-4.678l6.945,4.009l-7.613,4.41c0,0.134 -0.267,0.134 -0.401,0.134z"
                    id="Vector_64"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m204.954,440.198l-7.078,-3.875l8.147,-4.677l6.945,4.009l-7.613,4.41c0,0.133 -0.267,0.133 -0.401,0.133z"
                    id="Vector_65"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m223.385,450.756l-0.267,0l-6.811,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c-0.134,0.134 -0.401,0.134 -0.401,0.134z"
                    id="Vector_66"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m236.474,458.373l-0.267,0l-6.812,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c0,0.134 -0.133,0.134 -0.4,0.134z"
                    id="Vector_67"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m245.69,463.718l-7.079,-3.875l8.147,-4.677l6.945,4.009l-7.613,4.41c0,0 -0.267,0.133 -0.4,0.133z"
                    id="Vector_68"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m50.026,403.983l-7.612,4.41c-0.134,0 -0.268,0.134 -0.401,0.134l-0.267,0l-6.812,-3.876l8.147,-4.677l6.945,4.009z"
                    id="Vector_69"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m97.974,431.646l-7.613,4.41c-0.134,0.133 -0.401,0.133 -0.534,0.133l-7.079,-3.875l8.281,-4.678l6.945,4.01z"
                    id="Vector_70"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m68.457,414.54l-7.613,4.41c-0.133,0.133 -0.401,0.133 -0.534,0.133l-0.267,0l-15.894,-9.22l8.147,-4.678l16.161,9.355z"
                    id="Vector_71"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m88.758,426.3l-7.613,4.41c-0.134,0.134 -0.401,0.134 -0.534,0.134l-7.079,-3.876l8.147,-4.677l7.079,4.009z"
                    id="Vector_72"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m79.542,420.955l-7.613,4.41c-0.133,0.133 -0.401,0.133 -0.534,0.133l-7.079,-3.875l8.147,-4.677l7.079,4.009z"
                    id="Vector_73"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m195.872,434.853l-0.267,0l-6.811,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c-0.134,0.134 -0.401,0.134 -0.401,0.134z"
                    id="Vector_74"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m44.282,388.615l6.946,4.009l-18.031,10.424c-0.133,0 -0.267,0.133 -0.401,0.133l-0.267,0l-6.811,-3.875l18.564,-10.691z"
                    id="Vector_75"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m209.495,496.058l-7.613,4.41c-0.133,0.134 -0.4,0.134 -0.534,0.134l-8.548,-4.811l8.147,-4.677l8.548,4.944z"
                    id="Vector_76"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m198.944,489.911l-7.613,4.41c-0.133,0.134 -0.4,0.134 -0.534,0.134l-0.267,0l-52.221,-30.068l8.147,-4.678l52.488,30.202z"
                    id="Vector_77"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m196.807,485.902l-7.079,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c-0.133,0.134 -0.267,0.134 -0.4,0.134z"
                    id="Vector_78"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m220.18,502.205l-7.613,4.41c-0.134,0.134 -0.401,0.134 -0.534,0.134l-8.548,-4.811l8.147,-4.677l8.548,4.944z"
                    id="Vector_79"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m144.318,458.373l-7.612,4.41c-0.134,0.134 -0.401,0.134 -0.535,0.134l-0.267,0l-8.147,-4.678l8.147,-4.677l8.414,4.811z"
                    id="Vector_80"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m123.083,446.078l-7.613,4.41c-0.133,0.134 -0.401,0.134 -0.534,0.134l-0.267,0l-8.147,-4.678l8.147,-4.677l8.414,4.811z"
                    id="Vector_81"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m133.634,452.225l-7.613,4.41c-0.134,0 -0.267,0.134 -0.401,0.134l-8.547,-4.811l8.147,-4.677l8.414,4.944z"
                    id="Vector_82"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m112.398,439.931l-7.612,4.41c-0.134,0.133 -0.401,0.133 -0.535,0.133l-0.267,0l-10.15,-5.879l8.147,-4.678l10.417,6.014z"
                    id="Vector_83"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m102.515,407.457l-0.267,0l-6.812,-3.875l8.147,-4.677l6.945,4.009l-7.613,4.41c0,0.133 -0.133,0.133 -0.4,0.133z"
                    id="Vector_84"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m91.028,424.83l-0.267,0l-6.811,-3.875l8.147,-4.678l6.945,4.009l-7.613,4.41c-0.133,0.134 -0.267,0.134 -0.401,0.134z"
                    id="Vector_85"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m123.35,443.539l-19.099,-10.958l8.147,-4.677l18.965,10.958l-7.613,4.41c0,0.133 -0.133,0.267 -0.4,0.267z"
                    id="Vector_86"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m70.728,413.07l-0.267,0l-6.812,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c-0.133,0.134 -0.267,0.134 -0.4,0.134z"
                    id="Vector_87"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m61.512,407.724l-0.267,0l-6.812,-3.875l8.147,-4.677l6.945,4.009l-7.612,4.41c-0.134,0.133 -0.268,0.133 -0.401,0.133z"
                    id="Vector_88"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m132.566,448.751l-7.079,-3.875l8.147,-4.678l6.945,4.009l-7.613,4.41c0,0.134 -0.267,0.134 -0.4,0.134z"
                    id="Vector_89"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m81.146,407.056l-0.267,0l-6.812,-3.875l8.147,-4.678l6.945,4.009l-7.613,4.41c-0.133,0.134 -0.4,0.134 -0.4,0.134z"
                    id="Vector_90"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m178.376,475.345l-7.078,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c0,0.134 -0.134,0.134 -0.401,0.134z"
                    id="Vector_91"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m141.781,454.097l-7.078,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c-0.133,0.134 -0.267,0.134 -0.401,0.134z"
                    id="Vector_92"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m187.592,480.69l-0.267,0l-6.812,-3.875l8.147,-4.678l6.945,4.01l-7.612,4.41c0,0 -0.267,0.133 -0.401,0.133z"
                    id="Vector_93"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m169.294,470l-7.079,-3.876l8.148,-4.677l6.945,4.009l-7.747,4.41c0,0.134 -0.133,0.134 -0.267,0.134z"
                    id="Vector_94"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m160.078,464.654l-0.267,0l-6.811,-3.875l8.147,-4.678l6.945,4.009l-7.613,4.41c-0.134,0.134 -0.267,0.134 -0.401,0.134z"
                    id="Vector_95"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m150.862,459.442l-0.267,0l-6.811,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c0,0.134 -0.133,0.134 -0.401,0.134z"
                    id="Vector_96"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m173.434,421.89l-7.079,-3.875l8.147,-4.677l6.945,4.009l-7.613,4.41c0,0.133 -0.267,0.133 -0.4,0.133z"
                    id="Vector_97"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m52.297,402.513l-0.267,0l-6.812,-3.875l8.147,-4.678l6.946,4.01l-7.613,4.41c0,0.133 -0.134,0.133 -0.401,0.133z"
                    id="Vector_98"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m155.137,411.333l-7.079,-3.875l8.147,-4.678l6.945,4.009l-7.612,4.41c-0.134,0.134 -0.268,0.134 -0.401,0.134z"
                    id="Vector_99"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m134.702,399.573l-7.078,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c-0.134,0.134 -0.267,0.134 -0.401,0.134z"
                    id="Vector_100"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m143.918,404.918l-7.078,-3.875l8.147,-4.678l6.945,4.01l-7.613,4.41c-0.134,0 -0.267,0.133 -0.401,0.133z"
                    id="Vector_101"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m182.65,427.236l-7.079,-3.876l8.147,-4.677l6.945,4.009l-7.613,4.41c-0.133,0.134 -0.267,0.134 -0.4,0.134z"
                    id="Vector_102"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m164.219,416.678l-0.267,0l-6.812,-3.875l8.147,-4.678l6.945,4.01l-7.612,4.41c0,0.133 -0.268,0.133 -0.401,0.133z"
                    id="Vector_103"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m125.486,394.227l-7.078,-3.875l8.147,-4.677l6.945,4.009l-7.613,4.41c0,0.133 -0.267,0.133 -0.401,0.133z"
                    id="Vector_104"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m89.559,370.44l-7.078,-3.875l4.407,-2.406l6.945,4.009l-3.873,2.272c-0.134,-0.134 -0.267,0 -0.401,0z"
                    id="Vector_105"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m74.334,379.26l-7.078,-3.875l8.147,-4.677l6.945,4.009l-7.613,4.41c-0.134,0 -0.401,0.133 -0.401,0.133z"
                    id="Vector_106"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m117.073,386.343l-0.267,0l-6.678,-3.876l4.407,-2.405l6.945,4.009l-3.873,2.138c-0.267,0.134 -0.4,0.134 -0.534,0.134z"
                    id="Vector_107"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m107.857,380.997l-0.267,0l-6.678,-3.875l4.408,-2.405l6.945,4.009l-3.874,2.271c-0.133,0 -0.267,0 -0.534,0z"
                    id="Vector_108"
                  />
                  <path
                    fill="#8ABCF0"
                    d="m98.775,375.652l-7.079,-3.875l4.408,-2.406l6.945,4.009l-3.873,2.272c-0.134,0 -0.268,0 -0.401,0z"
                    id="Vector_109"
                  />
                </g>
              </g>
            </g>
            <g id="Group_2">
              <g id="XMLID 239">
                <g opacity="0.8" id="XMLID 250">
                  <g opacity="0.8" id="Group_3">
                    <g opacity="0.8" id="XMLID 303">
                      <path
                        fill="#455A64"
                        d="m88.224,29.6672l0,198.4498c0,2.673 1.87,5.88 4.14,7.083l267.251,154.617c0.535,0.267 0.935,0.401 1.336,0.401c0.133,0 0.401,0 0.534,-0.134c0.134,0 0.267,-0.133 0.401,-0.133c0.133,0 0.133,-0.134 0.267,-0.134c0.133,0 0.133,-0.133 0.267,-0.133c0.134,-0.134 0.134,-0.134 0.267,-0.268c0.267,-0.267 0.534,-0.668 0.801,-1.069c0.268,-0.668 0.401,-1.336 0.401,-2.138l0,-197.247c0,-2.673 -1.87,-5.88 -4.14,-7.083l-267.385,-154.6173c-1.068,-0.6681 -2.003,-0.6681 -2.805,-0.4009c-0.133,0 -0.267,0.1337 -0.4,0.2673c-0.134,0.1336 -0.134,0.1336 -0.267,0.2673l-0.134,0.1336c0,0.1336 -0.133,0.1336 -0.133,0.2673c-0.134,0.1336 -0.134,0.4009 -0.268,0.5345c0,0.1337 -0.133,0.2673 -0.133,0.4009c0,0 0,0 0,0.1337c0,0.1336 0,0.2672 -0.134,0.4009c0.267,0 0.134,0.1336 0.134,0.4009z"
                        opacity="0.6"
                        id="Vector_110"
                      />
                      <path
                        fill="#455A64"
                        d="m90.628,28.0637c-0.134,0 -0.267,0 -0.401,0.1336l-0.134,0l-0.133,0.1336l-0.134,0.1337l-0.133,0.4009l0,0.1336c0,0.1337 0,0.2673 0,0.4009l0,198.45c0,2.138 1.603,4.945 3.472,6.014l267.118,154.751c0.401,0.133 0.534,0.267 0.668,0.267c0,0 0.133,0 0.267,0l0.133,0l0.134,-0.134l0.133,0c0.134,-0.133 0.268,-0.401 0.401,-0.668c0.267,-0.401 0.267,-0.935 0.267,-1.604l0,-197.514c0,-2.138 -1.602,-4.945 -3.472,-6.014l-267.118,-154.4834c-0.4,-0.2673 -0.801,-0.4009 -1.068,-0.4009zm0,-1.3364c0.534,0 1.202,0.1336 1.87,0.5345l267.117,154.6172c2.271,1.336 4.14,4.544 4.14,7.083l0,197.381c0,0.802 -0.133,1.47 -0.4,2.138c-0.134,0.401 -0.401,0.802 -0.802,1.069c-0.133,0.134 -0.133,0.134 -0.267,0.267c-0.133,0 -0.133,0.134 -0.267,0.134c-0.133,0 -0.133,0.134 -0.267,0.134c-0.134,0 -0.267,0.133 -0.401,0.133c-0.133,0 -0.4,0.134 -0.534,0.134c-0.401,0 -0.801,-0.134 -1.336,-0.401l-267.117,-154.751c-2.271,-1.336 -4.14,-4.544 -4.14,-7.083l0,-198.4497c0,-0.1336 0,-0.4009 0,-0.5346c0,-0.1336 0,-0.2672 0.133,-0.4009c0,0 0,0 0,-0.1336c0,-0.1336 0,-0.2673 0.134,-0.4009c0,-0.1336 0.133,-0.4009 0.267,-0.5345c0,-0.1337 0.133,-0.1337 0.133,-0.2673l0.134,-0.1337c0.134,-0.1336 0.134,-0.1336 0.267,-0.2672c0.134,-0.1337 0.267,-0.1337 0.401,-0.2673c0.267,0 0.534,0 0.935,0z"
                        opacity="0.8"
                        id="Vector_111"
                      />
                    </g>
                  </g>
                  <g opacity="0.8" id="Group_4">
                    <g opacity="0.7" id="XMLID 302">
                      <path
                        fill="white"
                        d="m88.224,29.6672l0,198.4498c0,2.673 1.87,5.88 4.14,7.083l267.251,154.617c0.535,0.267 0.935,0.401 1.336,0.401c0.133,0 0.401,0 0.534,-0.134c0.134,0 0.267,-0.133 0.401,-0.133c0.133,0 0.133,-0.134 0.267,-0.134c0.133,0 0.133,-0.133 0.267,-0.133c0.134,-0.134 0.134,-0.134 0.267,-0.268c0.267,-0.267 0.534,-0.668 0.801,-1.069c0.268,-0.668 0.401,-1.336 0.401,-2.138l0,-197.247c0,-2.673 -1.87,-5.88 -4.14,-7.083l-267.385,-154.6173c-1.068,-0.6681 -2.003,-0.6681 -2.805,-0.4009c-0.133,0 -0.267,0.1337 -0.4,0.2673c-0.134,0.1336 -0.134,0.1336 -0.267,0.2673l-0.134,0.1336c0,0.1336 -0.133,0.1336 -0.133,0.2673c-0.134,0.1336 -0.134,0.4009 -0.268,0.5345c0,0.1337 -0.133,0.2673 -0.133,0.4009c0,0 0,0 0,0.1337c0,0.1336 0,0.2672 -0.134,0.4009c0.267,0 0.134,0.1336 0.134,0.4009z"
                        opacity="0.7"
                        id="Vector_112"
                      />
                    </g>
                  </g>
                </g>
                <g id="XMLID 245">
                  <g id="XMLID 249">
                    <path
                      fill="#375AD7"
                      d="m89.694,26.861c0.801,-0.4009 1.736,-0.2673 2.804,0.4009l267.118,154.6171c2.27,1.337 4.14,4.544 4.14,7.083l0,13.497l5.209,-3.073l0,-13.364c0,-2.673 -1.87,-5.88 -4.14,-7.083l-267.118,-154.6171c-1.202,-0.6682 -2.27,-0.6682 -2.938,-0.2673c-0.802,0.4009 -4.274,2.4055 -5.075,2.8064z"
                      id="Vector_113"
                    />
                  </g>
                  <path
                    fill="#375AD7"
                    d="m88.224,29.5336c0,-2.5391 1.87,-3.6082 4.14,-2.4055l267.252,154.7509c2.27,1.336 4.14,4.544 4.14,7.083l0,13.497l-275.532,-159.2945l0,-13.6309z"
                    id="XMLID 248"
                  />
                  <g opacity="0.4" id="XMLID 247">
                    <path
                      fill="white"
                      d="m92.365,27.2619l267.251,154.6171c1.068,0.668 2.137,1.737 2.938,3.074l5.209,-3.074c-0.802,-1.336 -1.737,-2.405 -2.805,-3.073l-267.251,-154.4841c-1.202,-0.6682 -2.271,-0.6682 -2.938,-0.2673c-0.802,0.4009 -4.141,2.4055 -5.076,2.8064c0.668,-0.2673 1.603,-0.2673 2.672,0.4009z"
                      opacity="0.4"
                      id="Vector_114"
                    />
                  </g>
                  <path
                    fill="black"
                    d="m368.965,199.386l0,-13.364c0,-1.336 -0.534,-2.806 -1.202,-4.143l-5.209,3.074c0.801,1.336 1.202,2.806 1.202,4.143l0,13.497l5.209,-3.207z"
                    opacity="0.15"
                    id="XMLID 246"
                  />
                </g>
                <g id="XMLID 241">
                  <path
                    fill="white"
                    d="m113.066,44.9017c1.336,0.8018 2.271,2.94 2.271,4.9445c0,2.0045 -1.069,2.94 -2.271,2.2718c-1.335,-0.8018 -2.27,-2.94 -2.27,-4.9445c0,-2.0046 1.068,-2.94 2.27,-2.2718z"
                    id="XMLID 244"
                  />
                  <path
                    fill="white"
                    d="m104.652,40.0908c1.336,0.8018 2.271,2.94 2.271,4.9445c0,2.0045 -1.069,2.94 -2.271,2.2718c-1.335,-0.8018 -2.27,-2.94 -2.27,-4.9445c0,-2.0046 1.068,-3.0737 2.27,-2.2718z"
                    id="XMLID 243"
                  />
                  <path
                    fill="white"
                    d="m96.371,35.28c1.336,0.8018 2.271,2.94 2.271,4.9445c0,2.0046 -1.069,2.94 -2.271,2.2718c-1.336,-0.8018 -2.27,-2.94 -2.27,-4.9445c-0.134,-2.0046 0.934,-3.0736 2.27,-2.2718z"
                    id="XMLID 242"
                  />
                </g>
                <path
                  fill="#455A64"
                  d="m362.287,389.817c0.133,-0.134 0.267,-0.134 0.534,-0.267c0.401,-0.267 0.801,-0.535 1.335,-0.668c0.268,-0.134 0.401,-0.268 0.668,-0.401c0.401,-0.268 0.802,-0.401 1.069,-0.668c0.4,-0.268 0.668,-0.401 0.935,-0.535c0.267,-0.134 0.4,-0.267 0.534,-0.267c0.267,-0.134 0.534,-0.268 0.668,-0.535c0.267,-0.267 0.4,-0.534 0.534,-0.802c0.267,-0.668 0.534,-1.336 0.534,-2.138l0,-184.151l-5.209,3.074l0,183.884c0,0.801 -0.133,1.47 -0.4,2.138c-0.134,0.401 -0.401,0.802 -0.802,1.069c-0.133,0 -0.267,0.133 -0.4,0.267z"
                  opacity="0.6"
                  id="XMLID 240"
                />
              </g>
              <g id="XMLID 219">
                <g id="XMLID 233">
                  <path
                    fill="#2F4CB7"
                    d="m274.405,273.959c0,0 9.75,-6.281 10.952,-6.281c1.202,0 27.646,-0.936 27.646,-0.936l-10.951,5.88l0,0.535l-27.647,0.802z"
                    id="XMLID 238"
                  />
                  <path
                    fill="#FAFAFA"
                    d="m321.818,292.935l0,-17.372l-47.413,-58.934l0,22.985l27.647,33.009l0,0.534l-27.647,0.936l0,22.985l47.413,-4.143z"
                    id="XMLID 236"
                  />
                  <path
                    fill="#5774DD"
                    d="m274.405,216.629l10.952,-6.281l47.413,58.934l-10.952,6.281l-47.413,-58.934z"
                    id="XMLID 235"
                  />
                  <path
                    fill="#375AD7"
                    d="m332.77,269.281l0,17.24l-10.952,6.414l0,-17.373l10.952,-6.281z"
                    id="XMLID 234"
                  />
                </g>
                <g id="XMLID 228">
                  <path
                    fill="#FAFAFA"
                    d="m245.689,285.585l14.692,8.553l-36.729,-134.438l-14.157,-8.152l36.194,134.037z"
                    id="XMLID 231"
                  />
                  <path
                    fill="#5774DD"
                    d="m209.629,151.281l10.952,-6.281l14.157,8.152l-10.952,6.414l-14.157,-8.285z"
                    id="XMLID 230"
                  />
                  <path
                    fill="#2F4CB7"
                    d="m234.738,153.152l-10.952,6.414l36.728,134.438l10.952,-6.281l-36.728,-134.571z"
                    id="XMLID 229"
                  />
                </g>
                <g id="XMLID 220">
                  <path
                    fill="#2F4CB7"
                    d="m206.557,222.108l-10.952,6.281l-27.647,-33.275l10.952,-6.281l27.647,33.275z"
                    id="XMLID 227"
                  />
                  <g id="XMLID 224">
                    <path
                      fill="#FAFAFA"
                      d="m167.958,195.113l27.646,-1.069l0,-22.985l-47.413,4.143l0,17.372l47.413,58.8l0,-22.985l-27.646,-32.741l0,-0.535z"
                      id="XMLID 225"
                    />
                  </g>
                  <path
                    fill="#5774DD"
                    d="m206.557,164.778l-10.952,6.281l-47.414,4.276l10.952,-6.414l47.414,-4.143z"
                    id="XMLID 223"
                  />
                  <path
                    fill="#375AD7"
                    d="m195.605,171.059l0,22.985l10.952,-6.28l0,-22.986"
                    id="XMLID 222"
                  />
                  <path
                    fill="#375AD7"
                    d="m206.557,222.108l-10.952,6.281l0,22.986l10.952,-6.281l0,-22.986z"
                    id="XMLID 221"
                  />
                </g>
              </g>
            </g>
            <g id="Group_958">
              <g id="XMLID 150">
                <g id="XMLID 206">
                  <g opacity="0.8" id="XMLID 217">
                    <g opacity="0.8" id="Group_9">
                      <g opacity="0.8" id="XMLID 297">
                        <path
                          fill="#455A64"
                          d="m0.944,64.8781l0,198.4499c0,2.673 1.87,5.88 4.141,7.083l113.925,65.749c0.535,0.267 0.935,0.401 1.336,0.401c0.133,0 0.401,0 0.534,-0.134c0.134,0 0.267,-0.133 0.401,-0.133c0.133,0 0.133,-0.134 0.267,-0.134c0.133,0 0.133,-0.134 0.267,-0.134c0.134,-0.133 0.134,-0.133 0.267,-0.267c0.267,-0.267 0.534,-0.668 0.802,-1.069c0.267,-0.668 0.4,-1.336 0.4,-2.138l0,-197.248c0,-2.672 -1.87,-5.88 -4.14,-7.082l-114.059,-65.7493c-1.069,-0.6682 -2.004,-0.6682 -2.805,-0.4009c-0.134,0 -0.267,0.1336 -0.401,0.2672c-0.133,0.1337 -0.133,0.1337 -0.267,0.2673l-0.133,0.1336c0,0.1337 -0.134,0.1337 -0.134,0.2673c-0.134,0.1337 -0.134,0.4009 -0.267,0.5346c0,0.1336 -0.134,0.2672 -0.134,0.4009c0,0 0,0 0,0.1336c0,0.1336 0,0.2673 -0.133,0.4009c0.133,0 0.133,0.1337 0.133,0.4009z"
                          opacity="0.8"
                          id="Vector_121"
                        />
                        <path
                          fill="#455A64"
                          d="m3.348,63.2741c-0.133,0 -0.267,0 -0.4,0.1336l-0.134,0l-0.133,0.1337l-0.134,0.1336l-0.134,0.4009l0,0.1337c0,0.1336 0,0.2672 0,0.4009l0,198.4495c0,2.139 1.603,4.945 3.473,6.014l113.926,65.749c0.4,0.134 0.534,0.267 0.667,0.267c0,0 0.134,0 0.268,0l0.133,0l0.134,-0.133l0.133,0c0.134,-0.134 0.267,-0.401 0.401,-0.668c0.267,-0.401 0.267,-0.936 0.267,-1.604l0,-197.381c0,-2.138 -1.603,-4.944 -3.472,-6.014l-113.926,-65.7486c-0.401,-0.1337 -0.801,-0.2673 -1.069,-0.2673zm0,-1.3364c0.535,0 1.202,0.1337 1.87,0.5346l113.926,65.7487c2.27,1.337 4.14,4.544 4.14,7.083l0,197.381c0,0.802 -0.133,1.47 -0.4,2.138c-0.134,0.401 -0.401,0.802 -0.802,1.069c-0.133,0.134 -0.133,0.134 -0.267,0.268c-0.134,0 -0.134,0.133 -0.267,0.133c-0.134,0 -0.134,0.134 -0.267,0.134c-0.134,0 -0.267,0.133 -0.401,0.133c-0.133,0 -0.401,0.134 -0.534,0.134c-0.401,0 -0.801,-0.134 -1.336,-0.401l-113.925,-65.749c-2.271,-1.336 -4.141,-4.544 -4.141,-7.083l0,-198.5833c0,-0.1336 0,-0.4009 0,-0.5345c0,-0.1336 0,-0.2673 0.134,-0.4009c0,0 0,0 0,-0.1336c0,-0.1337 0,-0.2673 0.133,-0.401c0,-0.1336 0.134,-0.4009 0.268,-0.5345c0,-0.1336 0.133,-0.1336 0.133,-0.2673l0.134,-0.1336c0.133,-0.1336 0.133,-0.1336 0.267,-0.2673c0.133,-0.1336 0.267,-0.1336 0.4,-0.2673c0.268,0 0.535,0 0.935,0z"
                          opacity="0.8"
                          id="Vector_122"
                        />
                      </g>
                    </g>
                    <g opacity="0.8" id="Group_10">
                      <g opacity="0.8" id="XMLID 296">
                        <path
                          fill="white"
                          d="m0.944,64.8781l0,198.4499c0,2.673 1.87,5.88 4.141,7.083l113.925,65.749c0.535,0.267 0.935,0.401 1.336,0.401c0.133,0 0.401,0 0.534,-0.134c0.134,0 0.267,-0.133 0.401,-0.133c0.133,0 0.133,-0.134 0.267,-0.134c0.133,0 0.133,-0.134 0.267,-0.134c0.134,-0.133 0.134,-0.133 0.267,-0.267c0.267,-0.267 0.534,-0.668 0.802,-1.069c0.267,-0.668 0.4,-1.336 0.4,-2.138l0,-197.248c0,-2.672 -1.87,-5.88 -4.14,-7.082l-114.059,-65.7493c-1.069,-0.6682 -2.004,-0.6682 -2.805,-0.4009c-0.134,0 -0.267,0.1336 -0.401,0.2672c-0.133,0.1337 -0.133,0.1337 -0.267,0.2673l-0.133,0.1336c0,0.1337 -0.134,0.1337 -0.134,0.2673c-0.134,0.1337 -0.134,0.4009 -0.267,0.5346c0,0.1336 -0.134,0.2672 -0.134,0.4009c0,0 0,0 0,0.1336c0,0.1336 0,0.2673 -0.133,0.4009c0.133,0 0.133,0.1337 0.133,0.4009z"
                          opacity="0.8"
                          id="Vector_123"
                        />
                      </g>
                      <g id="Group 779">
                        <rect
                          x="188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(-0.866025,-0.5,-2.20305e-8,1,277,167.045) "
                          height="44.9452"
                          width="120.089"
                          id="Rectangle 868"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,178.412,114.995) "
                          rx="2.45832"
                          height="4.91665"
                          width="74.6672"
                          id="Rectangle 843"
                        />
                        <path
                          fill="#375AD7"
                          d="m56.063,121.14c-0.127,0 -0.254,0 -0.254,0c-0.127,0.127 -0.127,0.254 -0.127,0.381l0,2.16c0,0.127 0,0.381 0.127,0.508c0.127,0.127 0.127,0.254 0.254,0.381l1.27,0.763c0.127,0 0.254,0 0.254,0c0.127,-0.127 0.127,-0.127 0.127,-0.381l0,-2.16c0,-0.127 0,-0.382 -0.127,-0.509c-0.127,-0.127 -0.127,-0.254 -0.254,-0.381l-1.27,-0.762z"
                          id="XMLID 198"
                        />
                        <path
                          fill="#375AD7"
                          d="m51.618,118.598c-0.127,0 -0.254,0 -0.254,0c-0.127,0.127 -0.127,0.254 -0.127,0.381l0,2.16c0,0.127 0,0.381 0.127,0.508c0.127,0.127 0.127,0.254 0.254,0.381l1.27,0.763c0.127,0 0.254,0 0.254,0c0.127,-0.128 0.127,-0.128 0.127,-0.382l0,-2.16c0,-0.127 0,-0.381 -0.127,-0.508c-0.127,-0.127 -0.127,-0.254 -0.254,-0.381l-1.27,-0.762z"
                          id="XMLID 196"
                        />
                        <path
                          fill="#375AD7"
                          d="m47.174,116.057c-0.127,0 -0.254,0 -0.254,0c-0.127,0.127 -0.127,0.254 -0.127,0.382l0,2.159c0,0.128 0,0.382 0.127,0.509c0.127,0.127 0.127,0.254 0.254,0.381l1.27,0.762c0.127,0 0.254,0 0.254,0c0.127,-0.127 0.127,-0.127 0.127,-0.381l0,-2.16c0,-0.127 0,-0.381 -0.127,-0.508c-0.127,-0.127 -0.127,-0.254 -0.254,-0.381l-1.27,-0.763z"
                          id="XMLID 194"
                        />
                        <g id="&lt;/&gt;">
                          <path
                            id="svg_7"
                            fill="#375AD7"
                            d="m32.471,110.206c0,0.803 -0.779,0.827 -1.353,0.042l-3.756,-5.143c-0.338,-0.463 -0.541,-1.023 -0.541,-1.497c0,-0.444 0.178,-0.76 0.482,-0.857l3.828,-1.222c0.546,-0.174 1.34,0.734 1.34,1.532c0,0.308 -0.128,0.524 -0.345,0.579l-1.476,0.376c-0.993,0.252 -0.951,1.998 0.08,3.354l1.342,1.765c0.249,0.327 0.399,0.731 0.399,1.071z"
                          />
                          <path
                            id="svg_8"
                            fill="#375AD7"
                            d="m36.763,101.72c0.495,0.286 0.86,1.031 0.745,1.521l-2.734,11.584c-0.082,0.35 -0.391,0.453 -0.745,0.248c-0.495,-0.286 -0.861,-1.032 -0.745,-1.521l2.733,-11.584c0.083,-0.35 0.391,-0.453 0.746,-0.248z"
                          />
                          <path
                            id="svg_9"
                            fill="#375AD7"
                            d="m38.292,113.567c0,-0.341 0.15,-0.571 0.399,-0.611l1.342,-0.215c1.031,-0.165 1.073,-1.863 0.08,-3.262l-1.476,-2.08c-0.217,-0.305 -0.345,-0.669 -0.345,-0.978c0,-0.798 0.795,-0.788 1.341,0.016l3.828,5.643c0.303,0.447 0.481,0.969 0.481,1.413c0,0.473 -0.202,0.8 -0.541,0.873l-3.756,0.805c-0.574,0.123 -1.353,-0.801 -1.353,-1.604z"
                          />
                        </g>
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,224.559,153.928) "
                          rx="2.45832"
                          height="4.91665"
                          width="54.3034"
                          id="Rectangle 844"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,224.559,153.93) "
                          rx="2.45832"
                          height="4.91665"
                          width="54.3034"
                          opacity="0.5"
                          id="Rectangle 860"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,178.412,127.285) "
                          rx="2.45832"
                          height="4.91665"
                          width="33.9396"
                          id="Rectangle 845"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,207.511,161.293) "
                          rx="2.45832"
                          height="4.91665"
                          width="53.6246"
                          id="Rectangle 846"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,207.511,178.503) "
                          rx="2.45832"
                          height="4.91665"
                          width="39.0306"
                          id="Rectangle 847"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,178.412,161.703) "
                          rx="2.45832"
                          height="4.91665"
                          width="21.0426"
                          id="Rectangle 848"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,178.412,144.493) "
                          rx="2.45832"
                          height="4.91665"
                          width="21.0426"
                          id="Rectangle 858"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,178.412,178.912) "
                          rx="2.45832"
                          height="4.91665"
                          width="21.0426"
                          id="Rectangle 849"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,201.927,192.486) "
                          rx="2.45832"
                          height="4.91665"
                          width="43.1033"
                          id="Rectangle 851"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,201.927,209.694) "
                          rx="2.45832"
                          height="4.91665"
                          width="51.5882"
                          id="Rectangle 852"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,197.518,219.44) "
                          rx="2.45832"
                          height="4.91665"
                          width="51.5882"
                          id="Rectangle 853"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,246.016,247.441) "
                          rx="2.45832"
                          height="4.91665"
                          width="16.9698"
                          id="Rectangle 855"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,207.511,178.503) "
                          rx="2.45832"
                          height="4.91665"
                          width="39.0306"
                          opacity="0.5"
                          id="Rectangle 866"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,246.016,247.439) "
                          rx="2.45832"
                          height="4.91665"
                          width="16.9698"
                          opacity="0.5"
                          id="Rectangle 867"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,197.518,236.65) "
                          rx="2.45832"
                          height="4.91665"
                          width="51.5882"
                          id="Rectangle 854"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,197.518,253.856) "
                          rx="2.45832"
                          height="4.91665"
                          width="77.0429"
                          id="Rectangle 856"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,179,260.374) "
                          rx="2.45832"
                          height="4.91665"
                          width="36.648"
                          id="Rectangle 857"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,214.541,281.699) "
                          rx="2.45832"
                          height="4.91665"
                          width="48.3051"
                          id="Rectangle 859"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,178.412,196.12) "
                          rx="2.45832"
                          height="4.91665"
                          width="21.0426"
                          id="Rectangle 850"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,207.511,161.295) "
                          rx="2.45832"
                          height="4.91665"
                          width="53.6246"
                          opacity="0.5"
                          id="Rectangle 861"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,178.412,161.703) "
                          rx="2.45832"
                          height="4.91665"
                          width="21.0426"
                          opacity="0.5"
                          id="Rectangle 862"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,178.412,144.495) "
                          rx="2.45832"
                          height="4.91665"
                          width="21.0426"
                          opacity="0.5"
                          id="Rectangle 863"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,178.412,178.912) "
                          rx="2.45832"
                          height="4.91665"
                          width="21.0426"
                          opacity="0.5"
                          id="Rectangle 864"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,178.412,196.12) "
                          rx="2.45832"
                          height="4.91665"
                          width="21.0426"
                          opacity="0.5"
                          id="Rectangle 865"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID 212">
                    <g id="XMLID 216">
                      <path
                        fill="#375AD7"
                        d="m2.414,62.0722c0.801,-0.401 1.736,-0.2673 2.804,0.4009l113.926,65.7489c2.271,1.337 4.14,4.544 4.14,7.083l0,13.497l5.209,-3.073l0,-13.364c0,-2.673 -1.87,-5.88 -4.14,-7.083l-113.926,-65.7489c-1.202,-0.6682 -2.27,-0.6682 -2.938,-0.2673c-0.801,0.4009 -4.274,2.4054 -5.075,2.8064z"
                        id="Vector_124"
                      />
                    </g>
                    <path
                      fill="#375AD7"
                      d="m0.944,64.745c0,-2.5391 1.87,-3.6082 4.14,-2.4055l113.926,65.7495c2.271,1.336 4.14,4.543 4.14,7.082l0,13.498l-122.072,-70.4267l0,-13.4973l-0.134,0z"
                      id="XMLID 215"
                    />
                    <g opacity="0.4" id="XMLID 214">
                      <path
                        fill="white"
                        d="m5.085,62.6135l113.925,65.7495c1.069,0.668 2.137,1.737 2.938,3.073l5.209,-3.073c-0.801,-1.337 -1.736,-2.406 -2.804,-3.074l-113.926,-65.7491c-1.202,-0.6682 -2.271,-0.6682 -2.938,-0.2673c-0.802,0.4009 -4.141,2.4055 -5.076,2.8064c0.668,-0.1336 1.603,-0.1336 2.672,0.5345z"
                        opacity="0.4"
                        id="Vector_125"
                      />
                    </g>
                    <path
                      fill="black"
                      d="m128.359,145.728l0,-13.363c0,-1.337 -0.534,-2.807 -1.202,-4.143l-5.208,3.074c0.801,1.336 1.202,2.806 1.202,4.142l0,13.498l5.208,-3.208z"
                      opacity="0.15"
                      id="XMLID 213"
                    />
                  </g>
                  <g id="XMLID 208">
                    <path
                      fill="white"
                      d="m25.786,80.1126c1.336,0.8018 2.271,2.94 2.271,4.9445c0,2.0046 -1.069,2.94 -2.271,2.2718c-1.335,-0.8018 -2.27,-2.9399 -2.27,-4.9445c0,-2.0045 1.068,-2.94 2.27,-2.2718z"
                      id="XMLID 211"
                    />
                    <path
                      fill="white"
                      d="m17.372,75.3017c1.336,0.8018 2.271,2.94 2.271,4.9445c0,2.0046 -1.069,2.94 -2.271,2.2719c-1.335,-0.8019 -2.27,-2.94 -2.27,-4.9446c0,-2.0045 1.068,-3.0736 2.27,-2.2718z"
                      id="XMLID 210"
                    />
                    <path
                      fill="white"
                      d="m9.091,70.4909c1.335,0.8018 2.27,2.94 2.27,4.9446c0,2.0045 -1.068,2.94 -2.27,2.2718c-1.336,-0.8018 -2.271,-2.94 -2.271,-4.9446c0,-2.0045 0.935,-3.0736 2.271,-2.2718z"
                      id="XMLID 209"
                    />
                  </g>
                  <path
                    fill="white"
                    d="m121.682,336.16c0.133,-0.134 0.267,-0.134 0.534,-0.267c0.401,-0.267 0.801,-0.535 1.336,-0.668c0.267,-0.134 0.4,-0.268 0.667,-0.401c0.401,-0.268 0.802,-0.401 1.069,-0.668c0.4,-0.268 0.668,-0.401 0.935,-0.535c0.267,-0.134 0.4,-0.267 0.534,-0.267c0.267,-0.134 0.534,-0.268 0.668,-0.535c0.267,-0.267 0.4,-0.534 0.534,-0.802c0.267,-0.668 0.534,-1.336 0.534,-2.138l0,-184.151l-5.209,3.074l0,183.884c0,0.801 -0.133,1.47 -0.4,2.138c-0.134,0.401 -0.401,0.802 -0.802,1.069c-0.133,0 -0.267,0.133 -0.4,0.267c0,-0.134 0,0 0,0z"
                    opacity="0.65"
                    id="XMLID 207"
                  />
                </g>
              </g>
            </g>
            <g id="python_doc">
              <g id="XMLID 163">
                <g id="XMLID 206_2">
                  <g opacity="0.8" id="XMLID 217_2">
                    <g opacity="0.8" id="Group_11">
                      <g opacity="0.8" id="XMLID 297_2">
                        <path
                          fill="#455A64"
                          d="m2.134,64.8859l0,198.4501c0,2.673 1.87,5.88 4.14,7.083l113.926,65.749c0.534,0.267 0.935,0.401 1.335,0.401c0.134,0 0.401,0 0.535,-0.134c0.133,0 0.267,-0.134 0.4,-0.134c0.134,0 0.134,-0.133 0.267,-0.133c0.134,0 0.134,-0.134 0.267,-0.134c0.134,-0.133 0.134,-0.133 0.268,-0.267c0.267,-0.267 0.534,-0.668 0.801,-1.069c0.267,-0.668 0.401,-1.337 0.401,-2.138l0,-197.248c0,-2.672 -1.87,-5.88 -4.141,-7.082l-114.059,-65.7495c-1.068,-0.6682 -2.003,-0.6682 -2.805,-0.4009c-0.133,0 -0.267,0.1336 -0.4,0.2673c-0.134,0.1336 -0.134,0.1336 -0.267,0.2672l-0.134,0.1337c0,0.1336 -0.133,0.1336 -0.133,0.2672c-0.134,0.1337 -0.134,0.4009 -0.268,0.5346c0,0.1336 -0.133,0.2672 -0.133,0.4009c0,0 0,0 0,0.1336c0,0.1337 0,0.2673 -0.134,0.4009c0.134,0 0.134,0.1337 0.134,0.4009z"
                          opacity="0.8"
                          id="Vector_126"
                        />
                        <path
                          fill="#455A64"
                          d="m4.538,63.2819c-0.134,0 -0.267,0 -0.401,0.1337l-0.133,0l-0.134,0.1336l-0.133,0.1336l-0.134,0.4009l0,0.1337c0,0.1336 0,0.2672 0,0.4009l0,198.4497c0,2.138 1.603,4.945 3.472,6.014l113.926,65.749c0.401,0.134 0.534,0.267 0.668,0.267c0,0 0.133,0 0.267,0l0.134,0l0.133,-0.133l0.134,0c0.133,-0.134 0.267,-0.401 0.4,-0.669c0.268,-0.4 0.267,-0.935 0.267,-1.603l0,-197.381c0,-2.138 -1.602,-4.945 -3.472,-6.014l-113.926,-65.7488c-0.4,-0.1336 -0.801,-0.2673 -1.068,-0.2673zm0,-1.3363c0.534,0 1.202,0.1336 1.87,0.5345l113.925,65.7489c2.271,1.337 4.141,4.544 4.141,7.083l0,197.381c0,0.802 -0.134,1.47 -0.401,2.138c-0.134,0.401 -0.401,0.802 -0.801,1.069c-0.134,0.134 -0.134,0.134 -0.268,0.267c-0.133,0 -0.133,0.134 -0.267,0.134c-0.133,0 -0.133,0.134 -0.267,0.134c-0.133,0 -0.267,0.133 -0.4,0.133c-0.134,0 -0.401,0.134 -0.535,0.134c-0.4,0 -0.801,-0.134 -1.335,-0.401l-113.926,-65.749c-2.27,-1.336 -4.14,-4.544 -4.14,-7.083l0,-198.5834c0,-0.1337 0,-0.4009 0,-0.5346c0,-0.1336 0,-0.2673 0.133,-0.4009c0,0 0,0 0,-0.1336c0,-0.1337 0,-0.2673 0.134,-0.4009c0,-0.1337 0.134,-0.401 0.267,-0.5346c0,-0.1336 0.134,-0.1336 0.134,-0.2673l0.133,-0.1336c0.134,-0.1336 0.134,-0.1336 0.267,-0.2673c0.134,-0.1336 0.267,-0.1336 0.401,-0.2672c0.267,0 0.534,0 0.935,0z"
                          opacity="0.8"
                          id="Vector_127"
                        />
                      </g>
                    </g>
                    <g opacity="0.8" id="Group_12">
                      <g opacity="0.8" id="XMLID 296_2">
                        <path
                          fill="white"
                          d="m2.134,64.8859l0,198.4501c0,2.673 1.87,5.88 4.14,7.083l113.926,65.749c0.534,0.267 0.935,0.401 1.335,0.401c0.134,0 0.401,0 0.535,-0.134c0.133,0 0.267,-0.134 0.4,-0.134c0.134,0 0.134,-0.133 0.267,-0.133c0.134,0 0.134,-0.134 0.267,-0.134c0.134,-0.133 0.134,-0.133 0.268,-0.267c0.267,-0.267 0.534,-0.668 0.801,-1.069c0.267,-0.668 0.401,-1.337 0.401,-2.138l0,-197.248c0,-2.672 -1.87,-5.88 -4.141,-7.082l-114.059,-65.7495c-1.068,-0.6682 -2.003,-0.6682 -2.805,-0.4009c-0.133,0 -0.267,0.1336 -0.4,0.2673c-0.134,0.1336 -0.134,0.1336 -0.267,0.2672l-0.134,0.1337c0,0.1336 -0.133,0.1336 -0.133,0.2672c-0.134,0.1337 -0.134,0.4009 -0.268,0.5346c0,0.1336 -0.133,0.2672 -0.133,0.4009c0,0 0,0 0,0.1336c0,0.1337 0,0.2673 -0.134,0.4009c0.134,0 0.134,0.1337 0.134,0.4009z"
                          opacity="0.8"
                          id="Vector_128"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#8ED0FF"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,170,170.796) "
                          rx="5"
                          height="86.1756"
                          width="130.481"
                          id="python_text"
                        />
                        <g id="Group 779_2">
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,177,115.927) "
                            rx="2.58564"
                            height="5.17128"
                            width="78.5342"
                            id="Rectangle 843_2"
                          />
                          <path
                            fill="#375AD7"
                            d="m56.756,122.391c-0.134,0 -0.267,0 -0.267,0c-0.134,0.134 -0.134,0.267 -0.134,0.401l0,2.272c0,0.133 0,0.401 0.134,0.534c0.133,0.134 0.133,0.268 0.267,0.401l1.336,0.802c0.133,0 0.267,0 0.267,0c0.133,-0.134 0.133,-0.134 0.133,-0.401l0,-2.272c0,-0.133 0,-0.401 -0.133,-0.534c-0.134,-0.134 -0.134,-0.267 -0.267,-0.401l-1.336,-0.802z"
                            id="XMLID 198_2"
                          />
                          <path
                            fill="#375AD7"
                            d="m52.081,119.717c-0.133,0 -0.267,0 -0.267,0c-0.134,0.134 -0.134,0.268 -0.134,0.401l0,2.272c0,0.134 0,0.401 0.134,0.535c0.134,0.133 0.134,0.267 0.267,0.4l1.336,0.802c0.133,0 0.267,0 0.267,0c0.133,-0.133 0.133,-0.133 0.133,-0.401l0,-2.271c0,-0.134 0,-0.401 -0.133,-0.535c-0.134,-0.134 -0.134,-0.267 -0.267,-0.401l-1.336,-0.802z"
                            id="XMLID 196_2"
                          />
                          <path
                            fill="#375AD7"
                            d="m47.407,117.045c-0.133,0 -0.267,0 -0.267,0c-0.133,0.134 -0.133,0.268 -0.133,0.401l0,2.272c0,0.134 0,0.401 0.133,0.535c0.134,0.133 0.134,0.267 0.267,0.401l1.336,0.801c0.133,0 0.267,0 0.267,0c0.134,-0.133 0.134,-0.133 0.134,-0.401l0,-2.271c0,-0.134 0,-0.401 -0.134,-0.535c-0.134,-0.134 -0.134,-0.267 -0.267,-0.401l-1.336,-0.802z"
                            id="XMLID 194_2"
                          />
                          <g id="&lt;/&gt;_2">
                            <path
                              id="svg_10"
                              fill="#375AD7"
                              d="m31.943,110.891c0,0.845 -0.82,0.87 -1.423,0.044l-3.951,-5.409c-0.356,-0.487 -0.569,-1.076 -0.569,-1.575c0,-0.466 0.187,-0.799 0.506,-0.901l4.027,-1.286c0.574,-0.183 1.41,0.772 1.41,1.612c0,0.324 -0.136,0.551 -0.364,0.609l-1.659,0.422c-0.993,0.253 -0.951,1.999 0.08,3.355l1.523,2.002c0.261,0.344 0.42,0.769 0.42,1.127z"
                            />
                            <path
                              id="svg_11"
                              fill="#375AD7"
                              d="m36.456,101.965c0.521,0.301 0.905,1.085 0.784,1.6l-2.875,12.184c-0.087,0.368 -0.411,0.476 -0.784,0.261c-0.521,-0.301 -0.905,-1.085 -0.784,-1.6l2.875,-12.184c0.087,-0.368 0.411,-0.476 0.784,-0.261z"
                            />
                            <path
                              id="svg_12"
                              fill="#375AD7"
                              d="m38.065,114.426c0,-0.358 0.158,-0.601 0.419,-0.642l1.523,-0.244c1.032,-0.165 1.073,-1.863 0.081,-3.262l-1.66,-2.339c-0.228,-0.322 -0.363,-0.704 -0.363,-1.029c0,-0.839 0.836,-0.829 1.41,0.017l4.026,5.935c0.319,0.471 0.506,1.019 0.506,1.486c0,0.498 -0.212,0.842 -0.568,0.918l-3.951,0.847c-0.604,0.129 -1.423,-0.843 -1.423,-1.687z"
                            />
                          </g>
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,225.536,156.878) "
                            rx="2.58564"
                            height="5.17128"
                            width="57.1158"
                            opacity="0.5"
                            id="Rectangle 844_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,177,128.854) "
                            rx="2.58564"
                            height="5.17128"
                            width="35.6973"
                            id="Rectangle 845_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,207.605,164.624) "
                            rx="2.58564"
                            height="5.17128"
                            width="56.4018"
                            opacity="0.5"
                            id="Rectangle 846_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,207.605,182.725) "
                            rx="2.58564"
                            height="5.17128"
                            width="41.052"
                            opacity="0.5"
                            id="Rectangle 847_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,177,165.055) "
                            rx="2.58564"
                            height="5.17128"
                            width="22.1324"
                            opacity="0.5"
                            id="Rectangle 848_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,177,146.954) "
                            rx="2.58564"
                            height="5.17128"
                            width="22.1324"
                            opacity="0.5"
                            id="Rectangle 858_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,177,183.154) "
                            rx="2.58564"
                            height="5.17128"
                            width="22.1324"
                            id="Rectangle 849_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,201.732,197.432) "
                            rx="2.58564"
                            height="5.17128"
                            width="45.3356"
                            id="Rectangle 851_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,201.732,215.531) "
                            rx="2.58564"
                            height="5.17128"
                            width="54.26"
                            id="Rectangle 852_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,197.095,225.781) "
                            rx="2.58564"
                            height="5.17128"
                            width="54.26"
                            id="Rectangle 853_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,248.104,255.232) "
                            rx="2.58564"
                            height="5.17128"
                            width="17.8487"
                            opacity="0.5"
                            id="Rectangle 855_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,197.095,243.882) "
                            rx="2.58564"
                            height="5.17128"
                            width="54.26"
                            id="Rectangle 854_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,177,251) "
                            rx="2.58564"
                            height="5.17128"
                            width="81.033"
                            id="Rectangle 856_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,177.618,263) "
                            rx="2.58564"
                            height="5.17128"
                            width="76.6511"
                            id="Rectangle 857_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,177,201.253) "
                            rx="2.58564"
                            height="5.17128"
                            width="22.1324"
                            opacity="0.5"
                            id="Rectangle 850_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,177,183.153) "
                            rx="2.58564"
                            height="5.17128"
                            width="22.1324"
                            opacity="0.5"
                            id="Rectangle 864_2"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID 212_2">
                    <g id="XMLID 216_2">
                      <path
                        fill="#375AD7"
                        d="m3.603,62.08c0.802,-0.4009 1.736,-0.2673 2.805,0.4009l113.925,65.7491c2.271,1.336 4.141,4.544 4.141,7.083l0,13.497l5.209,-3.074l0,-13.363c0,-2.673 -1.87,-5.88 -4.141,-7.083l-113.925,-65.7491c-1.202,-0.6682 -2.271,-0.6682 -2.939,-0.2673c-0.801,0.4009 -4.273,2.4055 -5.075,2.8064z"
                        id="Vector_129"
                      />
                    </g>
                    <path
                      fill="#375AD7"
                      d="m2.134,64.7528c0,-2.5391 1.869,-3.6082 4.14,-2.4055l113.926,65.7487c2.27,1.337 4.14,4.544 4.14,7.083l0,13.497l-122.073,-70.4259l0,-13.4973l-0.133,0z"
                      id="XMLID 215_2"
                    />
                    <g opacity="0.4" id="XMLID 214_2">
                      <path
                        fill="white"
                        d="m6.274,62.6213l113.926,65.7487c1.068,0.669 2.137,1.738 2.938,3.074l5.209,-3.074c-0.802,-1.336 -1.737,-2.405 -2.805,-3.073l-113.926,-65.7493c-1.202,-0.6682 -2.27,-0.6682 -2.938,-0.2673c-0.801,0.4009 -4.14,2.4055 -5.075,2.8064c0.668,-0.1336 1.603,-0.1336 2.671,0.5345z"
                        opacity="0.4"
                        id="Vector_130"
                      />
                    </g>
                    <path
                      fill="black"
                      d="m129.549,145.736l0,-13.364c0,-1.336 -0.534,-2.806 -1.202,-4.142l-5.209,3.073c0.801,1.337 1.202,2.807 1.202,4.143l0,13.497l5.209,-3.207z"
                      opacity="0.15"
                      id="XMLID 213_2"
                    />
                  </g>
                  <g id="XMLID 208_2">
                    <path
                      fill="white"
                      d="m26.976,80.1204c1.335,0.8018 2.27,2.94 2.27,4.9445c0,2.0046 -1.068,2.94 -2.27,2.2719c-1.336,-0.8019 -2.271,-2.94 -2.271,-4.9446c0,-2.0045 1.069,-2.94 2.271,-2.2718z"
                      id="XMLID 211_2"
                    />
                    <path
                      fill="white"
                      d="m18.562,75.3095c1.335,0.8018 2.27,2.94 2.27,4.9446c0,2.0045 -1.068,2.94 -2.27,2.2718c-1.336,-0.8018 -2.271,-2.94 -2.271,-4.9446c0,-2.0045 1.069,-3.0736 2.271,-2.2718z"
                      id="XMLID 210_2"
                    />
                    <path
                      fill="white"
                      d="m10.28,70.4987c1.336,0.8018 2.271,2.94 2.271,4.9446c0,2.0045 -1.069,2.94 -2.271,2.2718c-1.335,-0.8018 -2.27,-2.94 -2.27,-4.9446c0,-2.0045 0.935,-3.0736 2.27,-2.2718z"
                      id="XMLID 209_2"
                    />
                  </g>
                  <path
                    fill="white"
                    d="m122.871,336.168c0.134,-0.134 0.267,-0.134 0.534,-0.267c0.401,-0.268 0.802,-0.535 1.336,-0.669c0.267,-0.133 0.401,-0.267 0.668,-0.4c0.4,-0.268 0.801,-0.401 1.068,-0.669c0.401,-0.267 0.668,-0.401 0.935,-0.534c0.267,-0.134 0.401,-0.267 0.534,-0.267c0.267,-0.134 0.535,-0.268 0.668,-0.535c0.267,-0.267 0.401,-0.535 0.534,-0.802c0.267,-0.668 0.535,-1.336 0.535,-2.138l0,-184.151l-5.209,3.074l0,183.883c0,0.802 -0.134,1.47 -0.401,2.139c-0.133,0.4 -0.401,0.801 -0.801,1.069c-0.134,0 -0.267,0.133 -0.401,0.267c0,-0.134 0,0 0,0z"
                    opacity="0.65"
                    id="XMLID 207_2"
                  />
                </g>
              </g>
            </g>
            <g id="aws_doc">
              <g id="XMLID 162">
                <g id="XMLID 206_3">
                  <g opacity="0.8" id="XMLID 217_3">
                    <g opacity="0.8" id="Group_13">
                      <g opacity="0.8" id="XMLID 297_3">
                        <path
                          fill="#455A64"
                          d="m2.134,64.8859l0,198.4501c0,2.673 1.87,5.88 4.14,7.083l113.926,65.749c0.534,0.267 0.935,0.401 1.335,0.401c0.134,0 0.401,0 0.535,-0.134c0.133,0 0.267,-0.134 0.4,-0.134c0.134,0 0.134,-0.133 0.267,-0.133c0.134,0 0.134,-0.134 0.267,-0.134c0.134,-0.133 0.134,-0.133 0.268,-0.267c0.267,-0.267 0.534,-0.668 0.801,-1.069c0.267,-0.668 0.401,-1.337 0.401,-2.138l0,-197.248c0,-2.672 -1.87,-5.88 -4.141,-7.082l-114.059,-65.7495c-1.068,-0.6682 -2.003,-0.6682 -2.805,-0.4009c-0.133,0 -0.267,0.1336 -0.4,0.2673c-0.134,0.1336 -0.134,0.1336 -0.267,0.2672l-0.134,0.1337c0,0.1336 -0.133,0.1336 -0.133,0.2672c-0.134,0.1337 -0.134,0.4009 -0.268,0.5346c0,0.1336 -0.133,0.2672 -0.133,0.4009c0,0 0,0 0,0.1336c0,0.1337 0,0.2673 -0.134,0.4009c0.134,0 0.134,0.1337 0.134,0.4009z"
                          opacity="0.8"
                          id="Vector_131"
                        />
                        <path
                          fill="#455A64"
                          d="m4.538,63.2819c-0.134,0 -0.267,0 -0.401,0.1337l-0.133,0l-0.134,0.1336l-0.133,0.1336l-0.134,0.4009l0,0.1337c0,0.1336 0,0.2672 0,0.4009l0,198.4497c0,2.138 1.603,4.945 3.472,6.014l113.926,65.749c0.401,0.134 0.534,0.267 0.668,0.267c0,0 0.133,0 0.267,0l0.134,0l0.133,-0.133l0.134,0c0.133,-0.134 0.267,-0.401 0.4,-0.669c0.268,-0.4 0.267,-0.935 0.267,-1.603l0,-197.381c0,-2.138 -1.602,-4.945 -3.472,-6.014l-113.926,-65.7488c-0.4,-0.1336 -0.801,-0.2673 -1.068,-0.2673zm0,-1.3363c0.534,0 1.202,0.1336 1.87,0.5345l113.925,65.7489c2.271,1.337 4.141,4.544 4.141,7.083l0,197.381c0,0.802 -0.134,1.47 -0.401,2.138c-0.134,0.401 -0.401,0.802 -0.801,1.069c-0.134,0.134 -0.134,0.134 -0.268,0.267c-0.133,0 -0.133,0.134 -0.267,0.134c-0.133,0 -0.133,0.134 -0.267,0.134c-0.133,0 -0.267,0.133 -0.4,0.133c-0.134,0 -0.401,0.134 -0.535,0.134c-0.4,0 -0.801,-0.134 -1.335,-0.401l-113.926,-65.749c-2.27,-1.336 -4.14,-4.544 -4.14,-7.083l0,-198.5834c0,-0.1337 0,-0.4009 0,-0.5346c0,-0.1336 0,-0.2673 0.133,-0.4009c0,0 0,0 0,-0.1336c0,-0.1337 0,-0.2673 0.134,-0.4009c0,-0.1337 0.134,-0.401 0.267,-0.5346c0,-0.1336 0.134,-0.1336 0.134,-0.2673l0.133,-0.1336c0.134,-0.1336 0.134,-0.1336 0.267,-0.2673c0.134,-0.1336 0.267,-0.1336 0.401,-0.2672c0.267,0 0.534,0 0.935,0z"
                          opacity="0.8"
                          id="Vector_132"
                        />
                      </g>
                    </g>
                    <g opacity="0.8" id="Group_14">
                      <g opacity="0.8" id="XMLID 296_3">
                        <path
                          fill="white"
                          d="m2.134,64.8859l0,198.4501c0,2.673 1.87,5.88 4.14,7.083l113.926,65.749c0.534,0.267 0.935,0.401 1.335,0.401c0.134,0 0.401,0 0.535,-0.134c0.133,0 0.267,-0.134 0.4,-0.134c0.134,0 0.134,-0.133 0.267,-0.133c0.134,0 0.134,-0.134 0.267,-0.134c0.134,-0.134 0.134,-0.133 0.268,-0.267c0.267,-0.267 0.534,-0.668 0.801,-1.069c0.267,-0.668 0.401,-1.337 0.401,-2.138l0,-197.248c0,-2.672 -1.87,-5.88 -4.141,-7.082l-114.059,-65.7495c-1.068,-0.6682 -2.003,-0.6682 -2.805,-0.4009c-0.133,0 -0.267,0.1336 -0.4,0.2673c-0.134,0.1336 -0.134,0.1336 -0.267,0.2672l-0.134,0.1337c0,0.1336 -0.134,0.1336 -0.134,0.2672c-0.133,0.1337 -0.133,0.4009 -0.267,0.5346c0,0.1336 -0.133,0.2672 -0.133,0.4009c0,0 0,0 0,0.1336c0,0.1337 0,0.2673 -0.134,0.4009c0.134,0 0.134,0.1337 0.134,0.4009z"
                          opacity="0.8"
                          id="Vector_133"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,170,133.406) "
                          rx="5"
                          height="92.9842"
                          width="130.481"
                          id="amazon_text"
                        />
                      </g>
                      <g id="Group 779_3">
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,179.602,115.003) "
                          rx="2.45832"
                          height="4.91665"
                          width="74.6672"
                          id="Rectangle 843_3"
                        />
                        <path
                          fill="#375AD7"
                          d="m57.253,121.148c-0.127,0 -0.254,0 -0.254,0c-0.127,0.127 -0.127,0.254 -0.127,0.381l0,2.16c0,0.127 0,0.381 0.127,0.508c0.127,0.127 0.127,0.254 0.254,0.381l1.269,0.763c0.127,0 0.254,0 0.254,0c0.127,-0.128 0.127,-0.128 0.127,-0.382l0,-2.16c0,-0.127 0,-0.381 -0.127,-0.508c-0.127,-0.127 -0.127,-0.254 -0.254,-0.381l-1.269,-0.762z"
                          id="XMLID 198_3"
                        />
                        <path
                          fill="#375AD7"
                          d="m52.807,118.606c-0.127,0 -0.254,0 -0.254,0c-0.127,0.127 -0.127,0.254 -0.127,0.381l0,2.16c0,0.127 0,0.381 0.127,0.508c0.127,0.127 0.127,0.254 0.254,0.381l1.27,0.763c0.127,0 0.254,0 0.254,0c0.127,-0.127 0.127,-0.127 0.127,-0.382l0,-2.16c0,-0.127 0,-0.381 -0.127,-0.508c-0.127,-0.127 -0.127,-0.254 -0.254,-0.381l-1.27,-0.762z"
                          id="XMLID 196_3"
                        />
                        <path
                          fill="#375AD7"
                          d="m48.364,116.065c-0.127,0 -0.254,0 -0.254,0c-0.127,0.127 -0.127,0.255 -0.127,0.382l0,2.16c0,0.127 0,0.381 0.127,0.508c0.127,0.127 0.127,0.254 0.254,0.381l1.27,0.762c0.127,0 0.254,0 0.254,0c0.127,-0.127 0.127,-0.127 0.127,-0.381l0,-2.16c0,-0.127 0,-0.381 -0.127,-0.508c-0.127,-0.127 -0.127,-0.254 -0.254,-0.381l-1.27,-0.763z"
                          id="XMLID 194_3"
                        />
                        <g id="&lt;/&gt;_3">
                          <path
                            id="svg_13"
                            fill="#375AD7"
                            d="m33.661,110.214c0,0.803 -0.78,0.827 -1.353,0.042l-3.757,-5.143c-0.338,-0.463 -0.54,-1.023 -0.54,-1.497l0,0c0,-0.444 0.177,-0.76 0.481,-0.857l3.828,-1.222c0.546,-0.174 1.341,0.734 1.341,1.532l0,0c0,0.308 -0.129,0.524 -0.346,0.579l-1.476,0.376c-0.992,0.252 -0.951,1.998 0.081,3.354l1.342,1.765c0.248,0.327 0.399,0.731 0.399,1.071l0,0z"
                          />
                          <path
                            id="svg_14"
                            fill="#375AD7"
                            d="m37.952,101.728c0.495,0.286 0.861,1.031 0.745,1.521l-2.733,11.584c-0.083,0.35 -0.391,0.453 -0.745,0.248l0,0c-0.496,-0.286 -0.861,-1.032 -0.746,-1.521l2.734,-11.584c0.082,-0.35 0.391,-0.453 0.745,-0.248l0,0z"
                          />
                          <path
                            id="svg_15"
                            fill="#375AD7"
                            d="m39.481,113.575c0,-0.341 0.151,-0.571 0.399,-0.611l1.343,-0.215c1.031,-0.165 1.073,-1.863 0.08,-3.262l-1.476,-2.08c-0.217,-0.305 -0.346,-0.669 -0.346,-0.978l0,0c0,-0.798 0.795,-0.788 1.341,0.016l3.828,5.643c0.304,0.447 0.481,0.969 0.481,1.413l0,0c0,0.473 -0.202,0.8 -0.54,0.873l-3.757,0.805c-0.573,0.123 -1.353,-0.801 -1.353,-1.604l0,0z"
                          />
                        </g>
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,225.748,153.936) "
                          rx="2.45832"
                          height="4.91665"
                          width="54.3034"
                          id="Rectangle 844_3"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,225.748,153.938) "
                          rx="2.45832"
                          height="4.91665"
                          width="54.3034"
                          opacity="0.5"
                          id="Rectangle 860_2"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,179.602,127.292) "
                          rx="2.45832"
                          height="4.91665"
                          width="33.9396"
                          id="Rectangle 845_3"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,208.7,161.301) "
                          rx="2.45832"
                          height="4.91665"
                          width="53.6246"
                          id="Rectangle 846_3"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,208.7,178.511) "
                          rx="2.45832"
                          height="4.91665"
                          width="39.0306"
                          id="Rectangle 847_3"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,179.602,161.711) "
                          rx="2.45832"
                          height="4.91665"
                          width="21.0426"
                          id="Rectangle 848_3"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,179.602,144.501) "
                          rx="2.45832"
                          height="4.91665"
                          width="21.0426"
                          id="Rectangle 858_3"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,179.602,178.919) "
                          rx="2.45832"
                          height="4.91665"
                          width="21.0426"
                          id="Rectangle 849_3"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,203.116,192.494) "
                          rx="2.45832"
                          height="4.91665"
                          width="43.1033"
                          id="Rectangle 851_3"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,203.116,209.702) "
                          rx="2.45832"
                          height="4.91665"
                          width="51.5882"
                          id="Rectangle 852_3"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,198.707,219.448) "
                          rx="2.45832"
                          height="4.91665"
                          width="51.5882"
                          id="Rectangle 853_3"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,247.205,247.448) "
                          rx="2.45832"
                          height="4.91665"
                          width="16.9698"
                          id="Rectangle 855_3"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,208.7,178.511) "
                          rx="2.45832"
                          height="4.91665"
                          width="39.0306"
                          opacity="0.5"
                          id="Rectangle 866_2"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,247.205,247.447) "
                          rx="2.45832"
                          height="4.91665"
                          width="16.9698"
                          opacity="0.5"
                          id="Rectangle 867_2"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,198.707,236.658) "
                          rx="2.45832"
                          height="4.91665"
                          width="51.5882"
                          id="Rectangle 854_3"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,198.707,253.864) "
                          rx="2.45832"
                          height="4.91665"
                          width="77.0429"
                          id="Rectangle 856_3"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,180.189,260.382) "
                          rx="2.45832"
                          height="4.91665"
                          width="36.648"
                          id="Rectangle 857_3"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,215.73,281.708) "
                          rx="2.45832"
                          height="4.91665"
                          width="48.3051"
                          id="Rectangle 859_2"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#375AD7"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,179.602,196.128) "
                          rx="2.45832"
                          height="4.91665"
                          width="21.0426"
                          id="Rectangle 850_3"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,208.7,161.302) "
                          rx="2.45832"
                          height="4.91665"
                          width="53.6246"
                          opacity="0.5"
                          id="Rectangle 861_2"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,179.602,161.711) "
                          rx="2.45832"
                          height="4.91665"
                          width="21.0426"
                          opacity="0.5"
                          id="Rectangle 862_2"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,179.602,144.503) "
                          rx="2.45832"
                          height="4.91665"
                          width="21.0426"
                          opacity="0.5"
                          id="Rectangle 863_2"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,179.602,178.919) "
                          rx="2.45832"
                          height="4.91665"
                          width="21.0426"
                          opacity="0.5"
                          id="Rectangle 864_3"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,179.602,196.128) "
                          rx="2.45832"
                          height="4.91665"
                          width="21.0426"
                          opacity="0.5"
                          id="Rectangle 865_2"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="XMLID 212_3">
                    <g id="XMLID 216_3">
                      <path
                        fill="#375AD7"
                        d="m3.603,62.08c0.802,-0.4009 1.736,-0.2673 2.805,0.4009l113.925,65.7491c2.271,1.336 4.141,4.544 4.141,7.083l0,13.497l5.209,-3.074l0,-13.363c0,-2.673 -1.87,-5.88 -4.141,-7.083l-113.925,-65.7491c-1.202,-0.6682 -2.271,-0.6682 -2.939,-0.2673c-0.801,0.4009 -4.273,2.4055 -5.075,2.8064z"
                        id="Vector_134"
                      />
                    </g>
                    <path
                      fill="#375AD7"
                      d="m2.134,64.7528c0,-2.5391 1.869,-3.6082 4.14,-2.4055l113.926,65.7487c2.27,1.337 4.14,4.544 4.14,7.083l0,13.497l-122.073,-70.4259l0,-13.4973l-0.133,0z"
                      id="XMLID 215_3"
                    />
                    <g opacity="0.4" id="XMLID 214_3">
                      <path
                        fill="white"
                        d="m6.274,62.6213l113.926,65.7487c1.068,0.669 2.137,1.738 2.938,3.074l5.209,-3.074c-0.802,-1.336 -1.737,-2.405 -2.805,-3.073l-113.926,-65.7493c-1.202,-0.6682 -2.27,-0.6682 -2.938,-0.2673c-0.801,0.4009 -4.14,2.4055 -5.075,2.8064c0.668,-0.1336 1.603,-0.1336 2.671,0.5345z"
                        opacity="0.4"
                        id="Vector_135"
                      />
                    </g>
                    <path
                      fill="black"
                      d="m129.549,145.736l0,-13.364c0,-1.336 -0.534,-2.806 -1.202,-4.142l-5.209,3.073c0.801,1.337 1.202,2.807 1.202,4.143l0,13.497l5.209,-3.207z"
                      opacity="0.15"
                      id="XMLID 213_3"
                    />
                  </g>
                  <g id="XMLID 208_3">
                    <path
                      fill="white"
                      d="m26.976,80.1204c1.335,0.8018 2.27,2.94 2.27,4.9445c0,2.0046 -1.068,2.94 -2.27,2.2719c-1.336,-0.8019 -2.271,-2.94 -2.271,-4.9446c0,-2.0045 1.069,-2.94 2.271,-2.2718z"
                      id="XMLID 211_3"
                    />
                    <path
                      fill="white"
                      d="m18.562,75.3095c1.335,0.8018 2.27,2.94 2.27,4.9446c0,2.0045 -1.068,2.94 -2.27,2.2718c-1.336,-0.8018 -2.271,-2.94 -2.271,-4.9446c0,-2.0045 1.069,-3.0736 2.271,-2.2718z"
                      id="XMLID 210_3"
                    />
                    <path
                      fill="white"
                      d="m10.28,70.4987c1.336,0.8018 2.271,2.94 2.271,4.9446c0,2.0045 -1.069,2.94 -2.271,2.2718c-1.335,-0.8018 -2.27,-2.94 -2.27,-4.9446c0,-2.0045 0.935,-3.0736 2.27,-2.2718z"
                      id="XMLID 209_3"
                    />
                  </g>
                  <path
                    fill="white"
                    d="m122.871,336.168c0.134,-0.134 0.267,-0.134 0.534,-0.267c0.401,-0.268 0.802,-0.535 1.336,-0.669c0.267,-0.133 0.401,-0.267 0.668,-0.4c0.4,-0.268 0.801,-0.401 1.068,-0.669c0.401,-0.267 0.668,-0.401 0.935,-0.534c0.267,-0.134 0.401,-0.267 0.534,-0.267c0.267,-0.134 0.535,-0.268 0.668,-0.535c0.267,-0.267 0.401,-0.535 0.534,-0.802c0.267,-0.668 0.535,-1.336 0.535,-2.138l0,-184.151l-5.209,3.074l0,183.883c0,0.802 -0.134,1.47 -0.401,2.139c-0.133,0.4 -0.401,0.801 -0.801,1.069c-0.134,0 -0.267,0.133 -0.401,0.267c0,-0.134 0,0 0,0z"
                    opacity="0.65"
                    id="XMLID 207_3"
                  />
                </g>
              </g>
            </g>
            <g id="react_doc">
              <g id="XMLID 161">
                <g id="XMLID 206_4">
                  <g opacity="0.8" id="XMLID 217_4">
                    <g opacity="0.8" id="Group_15">
                      <g opacity="0.8" id="XMLID 297_4">
                        <path
                          fill="#455A64"
                          d="m2.134,66.8859l0,198.4501c0,2.673 1.87,5.88 4.14,7.083l113.926,65.749c0.534,0.267 0.935,0.401 1.335,0.401c0.134,0 0.401,0 0.535,-0.134c0.133,0 0.267,-0.134 0.4,-0.134c0.134,0 0.134,-0.133 0.267,-0.133c0.134,0 0.134,-0.134 0.267,-0.134c0.134,-0.133 0.134,-0.133 0.268,-0.267c0.267,-0.267 0.534,-0.668 0.801,-1.069c0.267,-0.668 0.401,-1.337 0.401,-2.138l0,-197.248c0,-2.672 -1.87,-5.88 -4.141,-7.082l-114.059,-65.7495c-1.068,-0.6682 -2.003,-0.6682 -2.805,-0.4009c-0.133,0 -0.267,0.1336 -0.4,0.2673c-0.134,0.1336 -0.134,0.1336 -0.267,0.2672l-0.134,0.1337c0,0.1336 -0.133,0.1336 -0.133,0.2672c-0.134,0.1337 -0.134,0.4009 -0.268,0.5346c0,0.1336 -0.133,0.2672 -0.133,0.4009c0,0 0,0 0,0.1336c0,0.1337 0,0.2673 -0.134,0.4009c0.134,0 0.134,0.1337 0.134,0.4009z"
                          opacity="0.8"
                          id="Vector_136"
                        />
                        <path
                          fill="#455A64"
                          d="m4.538,65.2819c-0.134,0 -0.267,0 -0.401,0.1337l-0.133,0l-0.134,0.1336l-0.133,0.1336l-0.134,0.4009l0,0.1337c0,0.1336 0,0.2672 0,0.4009l0,198.4497c0,2.138 1.603,4.945 3.472,6.014l113.926,65.749c0.401,0.134 0.534,0.267 0.668,0.267c0,0 0.133,0 0.267,0l0.134,0l0.133,-0.133l0.134,0c0.133,-0.134 0.267,-0.401 0.4,-0.669c0.268,-0.4 0.267,-0.935 0.267,-1.603l0,-197.381c0,-2.138 -1.602,-4.945 -3.472,-6.014l-113.926,-65.7488c-0.4,-0.1336 -0.801,-0.2673 -1.068,-0.2673zm0,-1.3363c0.534,0 1.202,0.1336 1.87,0.5345l113.925,65.7489c2.271,1.337 4.141,4.544 4.141,7.083l0,197.381c0,0.802 -0.134,1.47 -0.401,2.138c-0.134,0.401 -0.401,0.802 -0.801,1.069c-0.134,0.134 -0.134,0.134 -0.268,0.267c-0.133,0 -0.133,0.134 -0.267,0.134c-0.133,0 -0.133,0.134 -0.267,0.134c-0.133,0 -0.267,0.133 -0.4,0.133c-0.134,0 -0.401,0.134 -0.535,0.134c-0.4,0 -0.801,-0.134 -1.335,-0.401l-113.926,-65.749c-2.27,-1.336 -4.14,-4.544 -4.14,-7.083l0,-198.5834c0,-0.1337 0,-0.4009 0,-0.5346c0,-0.1336 0,-0.2673 0.133,-0.4009c0,0 0,0 0,-0.1336c0,-0.1337 0,-0.2673 0.134,-0.4009c0,-0.1337 0.134,-0.401 0.267,-0.5346c0,-0.1336 0.134,-0.1336 0.134,-0.2673l0.133,-0.1336c0.134,-0.1336 0.134,-0.1336 0.267,-0.2673c0.134,-0.1336 0.267,-0.1336 0.401,-0.2672c0.267,0 0.534,0 0.935,0z"
                          opacity="0.8"
                          id="Vector_137"
                        />
                      </g>
                    </g>
                    <g opacity="0.8" id="Group_16">
                      <g opacity="0.8" id="XMLID 296_4">
                        <path
                          fill="white"
                          d="m2.134,66.8859l0,198.4501c0,2.673 1.87,5.88 4.14,7.083l113.926,65.749c0.534,0.267 0.935,0.401 1.335,0.401c0.134,0 0.401,0 0.535,-0.134c0.133,0 0.267,-0.134 0.4,-0.134c0.134,0 0.134,-0.133 0.267,-0.133c0.134,0 0.134,-0.134 0.267,-0.134c0.134,-0.133 0.134,-0.133 0.268,-0.267c0.267,-0.267 0.534,-0.668 0.801,-1.069c0.267,-0.668 0.401,-1.337 0.401,-2.138l0,-197.248c0,-2.672 -1.87,-5.88 -4.141,-7.082l-114.059,-65.7495c-1.068,-0.6682 -2.003,-0.6682 -2.805,-0.4009c-0.133,0 -0.267,0.1336 -0.4,0.2673c-0.134,0.1336 -0.134,0.1336 -0.267,0.2672l-0.134,0.1337c0,0.1336 -0.133,0.1336 -0.133,0.2672c-0.134,0.1337 -0.134,0.4009 -0.268,0.5346c0,0.1336 -0.133,0.2672 -0.133,0.4009c0,0 0,0 0,0.1336c0,0.1337 0,0.2673 -0.134,0.4009c0.134,0 0.134,0.1337 0.134,0.4009z"
                          opacity="0.8"
                          id="Vector_138"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#8ED0FF"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,170.001,139) "
                          rx="5"
                          height="85.1063"
                          width="130.481"
                          id="react_text"
                        />
                        <g id="Group 795">
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,113.927) "
                            rx="2.58564"
                            height="5.17128"
                            width="38.1051"
                            id="Rectangle 843_4"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,234.001,148.773) "
                            rx="2.58564"
                            height="5.17128"
                            width="27.7128"
                            id="Rectangle 876"
                          />
                          <path
                            fill="#375AD7"
                            d="m50.757,120.391c-0.134,0 -0.267,0 -0.267,0c-0.134,0.134 -0.134,0.267 -0.134,0.401l0,2.272c0,0.133 0,0.401 0.134,0.534c0.133,0.134 0.133,0.268 0.267,0.401l1.336,0.802c0.133,0 0.267,0 0.267,0c0.133,-0.134 0.133,-0.134 0.133,-0.401l0,-2.272c0,-0.133 0,-0.401 -0.133,-0.534c-0.134,-0.134 -0.134,-0.267 -0.267,-0.401l-1.336,-0.802z"
                            id="XMLID 198_4"
                          />
                          <path
                            fill="#375AD7"
                            d="m46.082,117.717c-0.133,0 -0.267,0 -0.267,0c-0.134,0.134 -0.134,0.268 -0.134,0.401l0,2.272c0,0.134 0,0.401 0.134,0.535c0.134,0.133 0.134,0.267 0.267,0.4l1.336,0.802c0.133,0 0.267,0 0.267,0c0.133,-0.133 0.133,-0.133 0.133,-0.401l0,-2.271c0,-0.134 0,-0.401 -0.133,-0.535c-0.134,-0.134 -0.134,-0.267 -0.267,-0.401l-1.336,-0.802z"
                            id="XMLID 196_4"
                          />
                          <path
                            fill="#375AD7"
                            d="m41.408,115.045c-0.133,0 -0.267,0 -0.267,0c-0.133,0.134 -0.133,0.268 -0.133,0.401l0,2.272c0,0.134 0,0.401 0.133,0.535c0.134,0.133 0.134,0.267 0.267,0.401l1.336,0.801c0.133,0 0.267,0 0.267,0c0.134,-0.133 0.134,-0.133 0.134,-0.401l0,-2.271c0,-0.134 0,-0.401 -0.134,-0.535c-0.134,-0.134 -0.134,-0.267 -0.267,-0.401l-1.336,-0.802z"
                            id="XMLID 194_4"
                          />
                          <g id="&lt;/&gt;_4">
                            <path
                              id="svg_16"
                              fill="#375AD7"
                              d="m25.944,108.891c0,0.845 -0.82,0.87 -1.423,0.044l-3.951,-5.409c-0.356,-0.487 -0.569,-1.076 -0.569,-1.575c0,-0.466 0.187,-0.799 0.506,-0.901l4.027,-1.2856c0.574,-0.1833 1.41,0.7716 1.41,1.6116c0,0.324 -0.136,0.551 -0.364,0.609l-1.659,0.422c-0.993,0.253 -0.951,1.999 0.08,3.355l1.523,2.002c0.261,0.344 0.42,0.769 0.42,1.127z"
                            />
                            <path
                              id="svg_17"
                              fill="#375AD7"
                              d="m30.457,99.965c0.521,0.301 0.905,1.085 0.784,1.6l-2.875,12.184c-0.087,0.368 -0.411,0.476 -0.784,0.261c-0.521,-0.301 -0.905,-1.085 -0.784,-1.6l2.875,-12.184c0.087,-0.3682 0.411,-0.4762 0.784,-0.261z"
                            />
                            <path
                              id="svg_18"
                              fill="#375AD7"
                              d="m32.066,112.426c0,-0.358 0.158,-0.601 0.419,-0.642l1.523,-0.244c1.032,-0.165 1.073,-1.863 0.081,-3.262l-1.66,-2.339c-0.228,-0.322 -0.363,-0.704 -0.363,-1.029c0,-0.839 0.835,-0.829 1.41,0.017l4.026,5.935c0.319,0.471 0.506,1.019 0.506,1.486c0,0.498 -0.213,0.842 -0.568,0.918l-3.951,0.847c-0.604,0.129 -1.423,-0.843 -1.423,-1.687z"
                            />
                          </g>
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#507AD0"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,207.001,147.64) "
                            rx="2.58564"
                            height="5.17128"
                            width="40.4145"
                            id="Rectangle 844_4"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#507AD0"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,248.001,171) "
                            rx="2.58564"
                            height="5.17128"
                            width="30.5578"
                            id="Rectangle 875"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,126.854) "
                            rx="2.58564"
                            height="5.17128"
                            width="35.6973"
                            id="Rectangle 845_4"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,219.001,172.072) "
                            rx="2.58564"
                            height="5.17128"
                            width="64.6632"
                            id="Rectangle 868_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,209.001,185) "
                            rx="2.58564"
                            height="5.17128"
                            width="50.8068"
                            id="Rectangle 847_4"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,163.055) "
                            rx="2.58564"
                            height="5.17128"
                            width="39.2598"
                            id="Rectangle 848_4"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,144.954) "
                            rx="2.58564"
                            height="5.17128"
                            width="50.8068"
                            id="Rectangle 858_4"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,181.154) "
                            rx="2.58564"
                            height="5.17128"
                            width="55.4256"
                            id="Rectangle 849_4"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,228.001,214) "
                            rx="2.58564"
                            height="5.17128"
                            width="47.6517"
                            id="Rectangle 851_4"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,205.001,218.882) "
                            rx="2.58564"
                            height="5.17128"
                            width="73.9008"
                            id="Rectangle 852_4"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,214) "
                            rx="2.58564"
                            height="5.17128"
                            width="58.9882"
                            id="Rectangle 853_4"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,227.001,245.846) "
                            rx="2.58564"
                            height="5.17128"
                            width="50.8068"
                            id="Rectangle 855_4"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,203.001,250) "
                            rx="2.58564"
                            height="5.17128"
                            width="50.8068"
                            id="Rectangle 870"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,250.001,277.083) "
                            rx="2.58564"
                            height="5.17128"
                            width="32.3316"
                            id="Rectangle 871"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,232) "
                            rx="2.58564"
                            height="5.17128"
                            width="31.1769"
                            id="Rectangle 854_4"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,249) "
                            rx="2.58564"
                            height="5.17128"
                            width="25.4034"
                            id="Rectangle 856_4"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,233.001,284.773) "
                            rx="2.58564"
                            height="5.17128"
                            width="43.8786"
                            id="Rectangle 872"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,197.001,264) "
                            rx="2.58564"
                            height="5.17128"
                            width="38.1051"
                            id="Rectangle 874"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,199.253) "
                            rx="2.58564"
                            height="5.17128"
                            width="34.641"
                            id="Rectangle 850_4"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID 212_4">
                    <g id="XMLID 216_4">
                      <path
                        fill="#375AD7"
                        d="m3.603,64.08c0.802,-0.4009 1.736,-0.2673 2.805,0.4009l113.925,65.7491c2.271,1.336 4.141,4.544 4.141,7.083l0,13.497l5.209,-3.074l0,-13.363c0,-2.673 -1.87,-5.88 -4.141,-7.083l-113.925,-65.7491c-1.202,-0.6682 -2.271,-0.6682 -2.939,-0.2673c-0.801,0.4009 -4.273,2.4055 -5.075,2.8064z"
                        id="Vector_139"
                      />
                    </g>
                    <path
                      fill="#375AD7"
                      d="m2.134,66.7528c0,-2.5391 1.869,-3.6082 4.14,-2.4055l113.926,65.7487c2.27,1.337 4.14,4.544 4.14,7.083l0,13.497l-122.073,-70.4259l0,-13.4973l-0.133,0z"
                      id="XMLID 215_4"
                    />
                    <g opacity="0.4" id="XMLID 214_4">
                      <path
                        fill="white"
                        d="m6.274,64.6213l113.926,65.7487c1.068,0.669 2.137,1.738 2.938,3.074l5.209,-3.074c-0.802,-1.336 -1.737,-2.405 -2.805,-3.073l-113.926,-65.7493c-1.202,-0.6682 -2.27,-0.6682 -2.938,-0.2673c-0.801,0.4009 -4.14,2.4055 -5.075,2.8064c0.668,-0.1336 1.603,-0.1336 2.671,0.5345z"
                        opacity="0.4"
                        id="Vector_140"
                      />
                    </g>
                    <path
                      fill="black"
                      d="m129.549,147.736l0,-13.364c0,-1.336 -0.534,-2.806 -1.202,-4.142l-5.209,3.073c0.801,1.337 1.202,2.807 1.202,4.143l0,13.497l5.209,-3.207z"
                      opacity="0.15"
                      id="XMLID 213_4"
                    />
                  </g>
                  <g id="XMLID 208_4">
                    <path
                      fill="white"
                      d="m26.976,82.1204c1.335,0.8018 2.27,2.94 2.27,4.9445c0,2.0046 -1.068,2.94 -2.27,2.2719c-1.336,-0.8019 -2.271,-2.94 -2.271,-4.9446c0,-2.0045 1.069,-2.94 2.271,-2.2718z"
                      id="XMLID 211_4"
                    />
                    <path
                      fill="white"
                      d="m18.562,77.3095c1.335,0.8018 2.27,2.94 2.27,4.9446c0,2.0045 -1.068,2.94 -2.27,2.2718c-1.336,-0.8018 -2.271,-2.94 -2.271,-4.9446c0,-2.0045 1.069,-3.0736 2.271,-2.2718z"
                      id="XMLID 210_4"
                    />
                    <path
                      fill="white"
                      d="m10.28,72.4987c1.336,0.8018 2.271,2.94 2.271,4.9446c0,2.0045 -1.069,2.94 -2.271,2.2718c-1.335,-0.8018 -2.27,-2.94 -2.27,-4.9446c0,-2.0045 0.935,-3.0736 2.27,-2.2718z"
                      id="XMLID 209_4"
                    />
                  </g>
                  <path
                    fill="white"
                    d="m122.871,338.168c0.134,-0.134 0.267,-0.134 0.534,-0.267c0.401,-0.268 0.802,-0.535 1.336,-0.669c0.267,-0.133 0.401,-0.267 0.668,-0.4c0.4,-0.268 0.801,-0.401 1.068,-0.669c0.401,-0.267 0.668,-0.401 0.935,-0.534c0.267,-0.134 0.401,-0.267 0.534,-0.267c0.267,-0.134 0.535,-0.268 0.668,-0.535c0.267,-0.267 0.401,-0.535 0.534,-0.802c0.267,-0.668 0.535,-1.336 0.535,-2.138l0,-184.151l-5.209,3.074l0,183.883c0,0.802 -0.134,1.47 -0.401,2.139c-0.133,0.4 -0.401,0.801 -0.801,1.069c-0.134,0 -0.267,0.133 -0.401,0.267c0,-0.134 0,0 0,0z"
                    opacity="0.65"
                    id="XMLID 207_4"
                  />
                </g>
              </g>
            </g>
            <g id="vue_doc">
              <g id="XMLID 159">
                <g id="XMLID 206_5">
                  <g opacity="0.8" id="XMLID 217_5">
                    <g opacity="0.8" id="Group_17">
                      <g opacity="0.8" id="XMLID 297_5">
                        <path
                          fill="#455A64"
                          d="m2.134,64.8859l0,198.4501c0,2.673 1.87,5.88 4.14,7.083l113.926,65.749c0.534,0.267 0.935,0.401 1.335,0.401c0.134,0 0.401,0 0.535,-0.134c0.133,0 0.267,-0.134 0.4,-0.134c0.134,0 0.134,-0.133 0.267,-0.133c0.134,0 0.134,-0.134 0.267,-0.134c0.134,-0.133 0.134,-0.133 0.268,-0.267c0.267,-0.267 0.534,-0.668 0.801,-1.069c0.267,-0.668 0.401,-1.337 0.401,-2.138l0,-197.248c0,-2.672 -1.87,-5.88 -4.141,-7.082l-114.059,-65.7495c-1.068,-0.6682 -2.003,-0.6682 -2.805,-0.4009c-0.133,0 -0.267,0.1336 -0.4,0.2673c-0.134,0.1336 -0.134,0.1336 -0.267,0.2672l-0.134,0.1337c0,0.1336 -0.133,0.1336 -0.133,0.2672c-0.134,0.1337 -0.134,0.4009 -0.268,0.5346c0,0.1336 -0.133,0.2672 -0.133,0.4009c0,0 0,0 0,0.1336c0,0.1337 0,0.2673 -0.134,0.4009c0.134,0 0.134,0.1337 0.134,0.4009z"
                          opacity="0.8"
                          id="Vector_141"
                        />
                        <path
                          fill="#455A64"
                          d="m4.538,63.2819c-0.134,0 -0.267,0 -0.401,0.1337l-0.133,0l-0.134,0.1336l-0.133,0.1336l-0.134,0.4009l0,0.1337c0,0.1336 0,0.2672 0,0.4009l0,198.4497c0,2.138 1.603,4.945 3.472,6.014l113.926,65.749c0.401,0.134 0.534,0.267 0.668,0.267c0,0 0.133,0 0.267,0l0.134,0l0.133,-0.133l0.134,0c0.133,-0.134 0.267,-0.401 0.4,-0.669c0.268,-0.4 0.267,-0.935 0.267,-1.603l0,-197.381c0,-2.138 -1.602,-4.945 -3.472,-6.014l-113.926,-65.7488c-0.4,-0.1336 -0.801,-0.2673 -1.068,-0.2673zm0,-1.3363c0.534,0 1.202,0.1336 1.87,0.5345l113.925,65.7489c2.271,1.337 4.141,4.544 4.141,7.083l0,197.381c0,0.802 -0.134,1.47 -0.401,2.138c-0.134,0.401 -0.401,0.802 -0.801,1.069c-0.134,0.134 -0.134,0.134 -0.268,0.267c-0.133,0 -0.133,0.134 -0.267,0.134c-0.133,0 -0.133,0.134 -0.267,0.134c-0.133,0 -0.267,0.133 -0.4,0.133c-0.134,0 -0.401,0.134 -0.535,0.134c-0.4,0 -0.801,-0.134 -1.335,-0.401l-113.926,-65.749c-2.27,-1.336 -4.14,-4.544 -4.14,-7.083l0,-198.5834c0,-0.1337 0,-0.4009 0,-0.5346c0,-0.1336 0,-0.2673 0.133,-0.4009c0,0 0,0 0,-0.1336c0,-0.1337 0,-0.2673 0.134,-0.4009c0,-0.1337 0.134,-0.401 0.267,-0.5346c0,-0.1336 0.134,-0.1336 0.134,-0.2673l0.133,-0.1336c0.134,-0.1336 0.134,-0.1336 0.267,-0.2673c0.134,-0.1336 0.267,-0.1336 0.401,-0.2672c0.267,0 0.534,0 0.935,0z"
                          opacity="0.8"
                          id="Vector_142"
                        />
                      </g>
                    </g>
                    <g opacity="0.8" id="Group_18">
                      <g opacity="0.8" id="XMLID 296_5">
                        <path
                          fill="white"
                          d="m2.134,64.8859l0,198.4501c0,2.673 1.87,5.88 4.14,7.083l113.926,65.749c0.534,0.267 0.935,0.401 1.335,0.401c0.134,0 0.401,0 0.535,-0.134c0.133,0 0.267,-0.134 0.4,-0.134c0.134,0 0.134,-0.133 0.267,-0.133c0.134,0 0.134,-0.134 0.267,-0.134c0.134,-0.133 0.134,-0.133 0.268,-0.267c0.267,-0.267 0.534,-0.668 0.801,-1.069c0.267,-0.668 0.401,-1.337 0.401,-2.138l0,-197.248c0,-2.672 -1.87,-5.88 -4.141,-7.082l-114.059,-65.7495c-1.068,-0.6682 -2.003,-0.6682 -2.805,-0.4009c-0.133,0 -0.267,0.1336 -0.4,0.2673c-0.134,0.1336 -0.134,0.1336 -0.267,0.2672l-0.134,0.1337c0,0.1336 -0.133,0.1336 -0.133,0.2672c-0.134,0.1337 -0.134,0.4009 -0.268,0.5346c0,0.1336 -0.133,0.2672 -0.133,0.4009c0,0 0,0 0,0.1336c0,0.1337 0,0.2673 -0.134,0.4009c0.134,0 0.134,0.1337 0.134,0.4009z"
                          opacity="0.8"
                          id="Vector_143"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="#21BDA2"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,170,110) "
                          height="56.0042"
                          width="130.481"
                          id="vue_text"
                        />
                        <g id="Group 795_2">
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,111.927) "
                            rx="2.58564"
                            height="5.17128"
                            width="100.459"
                            id="Rectangle 843_5"
                          />
                          <path
                            fill="#375AD7"
                            d="m50.757,118.391c-0.134,0 -0.267,0 -0.267,0c-0.134,0.134 -0.134,0.267 -0.134,0.401l0,2.272c0,0.133 0,0.401 0.134,0.534c0.133,0.134 0.133,0.268 0.267,0.401l1.336,0.802c0.133,0 0.267,0 0.267,0c0.133,-0.134 0.133,-0.134 0.133,-0.401l0,-2.272c0,-0.133 0,-0.401 -0.133,-0.534c-0.134,-0.134 -0.134,-0.267 -0.267,-0.401l-1.336,-0.802z"
                            id="XMLID 198_5"
                          />
                          <path
                            fill="#375AD7"
                            d="m46.082,115.717c-0.133,0 -0.267,0 -0.267,0c-0.134,0.134 -0.134,0.268 -0.134,0.401l0,2.272c0,0.134 0,0.401 0.134,0.535c0.134,0.133 0.134,0.267 0.267,0.4l1.336,0.802c0.133,0 0.267,0 0.267,0c0.133,-0.133 0.133,-0.133 0.133,-0.401l0,-2.271c0,-0.134 0,-0.401 -0.133,-0.535c-0.134,-0.134 -0.134,-0.267 -0.267,-0.401l-1.336,-0.802z"
                            id="XMLID 196_5"
                          />
                          <path
                            fill="#375AD7"
                            d="m41.408,113.045c-0.133,0 -0.267,0 -0.267,0c-0.133,0.134 -0.133,0.268 -0.133,0.401l0,2.272c0,0.134 0,0.401 0.133,0.535c0.134,0.133 0.134,0.267 0.267,0.401l1.336,0.801c0.133,0 0.267,0 0.267,0c0.134,-0.133 0.134,-0.133 0.134,-0.401l0,-2.271c0,-0.134 0,-0.401 -0.134,-0.535c-0.134,-0.134 -0.134,-0.267 -0.267,-0.401l-1.336,-0.802z"
                            id="XMLID 194_5"
                          />
                          <g id="&lt;/&gt;_5">
                            <path
                              id="svg_19"
                              fill="#375AD7"
                              d="m25.944,106.891c0,0.845 -0.82,0.87 -1.423,0.044l-3.951,-5.409c-0.356,-0.487 -0.569,-1.076 -0.569,-1.5746c0,-0.4669 0.187,-0.7996 0.506,-0.9016l4.027,-1.2854c0.574,-0.1833 1.41,0.7718 1.41,1.6112c0,0.3245 -0.136,0.5512 -0.364,0.6092l-1.659,0.4222c-0.993,0.253 -0.951,1.999 0.08,3.355l1.523,2.002c0.261,0.344 0.42,0.769 0.42,1.127z"
                            />
                            <path
                              id="svg_20"
                              fill="#375AD7"
                              d="m30.457,97.965c0.521,0.3009 0.905,1.0852 0.784,1.6003l-2.875,12.1837c-0.087,0.368 -0.411,0.476 -0.784,0.261c-0.521,-0.301 -0.905,-1.085 -0.784,-1.6l2.875,-12.1838c0.087,-0.3684 0.411,-0.4764 0.784,-0.2612z"
                            />
                            <path
                              id="svg_21"
                              fill="#375AD7"
                              d="m32.066,110.426c0,-0.358 0.158,-0.601 0.419,-0.642l1.523,-0.244c1.032,-0.165 1.073,-1.863 0.081,-3.262l-1.66,-2.339c-0.228,-0.322 -0.363,-0.704 -0.363,-1.029c0,-0.839 0.835,-0.829 1.41,0.017l4.026,5.935c0.319,0.471 0.506,1.019 0.506,1.486c0,0.498 -0.213,0.842 -0.568,0.918l-3.951,0.847c-0.604,0.129 -1.423,-0.843 -1.423,-1.687z"
                            />
                          </g>
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#507AD0"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,219.537,152.878) "
                            rx="2.58564"
                            height="5.17128"
                            width="30.5578"
                            id="Rectangle 844_5"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,124.854) "
                            rx="2.58564"
                            height="5.17128"
                            width="35.6973"
                            id="Rectangle 845_5"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#698BDA"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,195.001,156) "
                            rx="2.58564"
                            height="5.17128"
                            width="34.641"
                            id="Rectangle 846_4"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,231.001,177) "
                            rx="2.58564"
                            height="5.17128"
                            width="50.8068"
                            id="Rectangle 868_3"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,195.001,175) "
                            rx="2.58564"
                            height="5.17128"
                            width="50.8068"
                            id="Rectangle 847_5"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,247.001,205.083) "
                            rx="2.58564"
                            height="5.17128"
                            width="34.641"
                            id="Rectangle 869"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#507AD0"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,161.055) "
                            rx="2.58564"
                            height="5.17128"
                            width="22.1324"
                            id="Rectangle 848_5"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#507AD0"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,142.954) "
                            rx="2.58564"
                            height="5.17128"
                            width="22.1324"
                            id="Rectangle 858_5"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#507AD0"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,179.154) "
                            rx="2.58564"
                            height="5.17128"
                            width="22.1324"
                            id="Rectangle 849_5"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,195.733,193.432) "
                            rx="2.58564"
                            height="5.17128"
                            width="94.9944"
                            id="Rectangle 851_5"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,195.733,211.531) "
                            rx="2.58564"
                            height="5.17128"
                            width="84.6021"
                            id="Rectangle 852_5"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,212) "
                            rx="2.58564"
                            height="5.17128"
                            width="58.9882"
                            id="Rectangle 853_5"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,227.001,243.846) "
                            rx="2.58564"
                            height="5.17128"
                            width="50.8068"
                            id="Rectangle 855_5"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,230) "
                            rx="2.58564"
                            height="5.17128"
                            width="85.4478"
                            id="Rectangle 854_5"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,247) "
                            rx="2.58564"
                            height="5.17128"
                            width="95.8401"
                            id="Rectangle 856_5"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.619,259.837) "
                            rx="2.58564"
                            height="5.17128"
                            width="110.137"
                            id="Rectangle 857_4"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#507AD0"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,197.253) "
                            rx="2.58564"
                            height="5.17128"
                            width="22.1324"
                            id="Rectangle 850_5"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID 212_5">
                    <g id="XMLID 216_5">
                      <path
                        fill="#375AD7"
                        d="m3.603,62.08c0.802,-0.4009 1.736,-0.2673 2.805,0.4009l113.925,65.7491c2.271,1.336 4.141,4.544 4.141,7.083l0,13.497l5.209,-3.074l0,-13.363c0,-2.673 -1.87,-5.88 -4.141,-7.083l-113.925,-65.7491c-1.202,-0.6682 -2.271,-0.6682 -2.939,-0.2673c-0.801,0.4009 -4.273,2.4055 -5.075,2.8064z"
                        id="Vector_144"
                      />
                    </g>
                    <path
                      fill="#375AD7"
                      d="m2.134,64.7528c0,-2.5391 1.869,-3.6082 4.14,-2.4055l113.926,65.7487c2.27,1.337 4.14,4.544 4.14,7.083l0,13.497l-122.073,-70.4259l0,-13.4973l-0.133,0z"
                      id="XMLID 215_5"
                    />
                    <g opacity="0.4" id="XMLID 214_5">
                      <path
                        fill="white"
                        d="m6.274,62.6213l113.926,65.7487c1.068,0.669 2.137,1.738 2.938,3.074l5.209,-3.074c-0.802,-1.336 -1.737,-2.405 -2.805,-3.073l-113.926,-65.7493c-1.202,-0.6682 -2.27,-0.6682 -2.938,-0.2673c-0.801,0.4009 -4.14,2.4055 -5.075,2.8064c0.668,-0.1336 1.603,-0.1336 2.671,0.5345z"
                        opacity="0.4"
                        id="Vector_145"
                      />
                    </g>
                    <path
                      fill="black"
                      d="m129.549,145.736l0,-13.364c0,-1.336 -0.534,-2.806 -1.202,-4.142l-5.209,3.073c0.801,1.337 1.202,2.807 1.202,4.143l0,13.497l5.209,-3.207z"
                      opacity="0.15"
                      id="XMLID 213_5"
                    />
                  </g>
                  <g id="XMLID 208_5">
                    <path
                      fill="white"
                      d="m26.976,80.1204c1.335,0.8018 2.27,2.94 2.27,4.9445c0,2.0046 -1.068,2.94 -2.27,2.2719c-1.336,-0.8019 -2.271,-2.94 -2.271,-4.9446c0,-2.0045 1.069,-2.94 2.271,-2.2718z"
                      id="XMLID 211_5"
                    />
                    <path
                      fill="white"
                      d="m18.562,75.3095c1.335,0.8018 2.27,2.94 2.27,4.9446c0,2.0045 -1.068,2.94 -2.27,2.2718c-1.336,-0.8018 -2.271,-2.94 -2.271,-4.9446c0,-2.0045 1.069,-3.0736 2.271,-2.2718z"
                      id="XMLID 210_5"
                    />
                    <path
                      fill="white"
                      d="m10.28,70.4987c1.336,0.8018 2.271,2.94 2.271,4.9446c0,2.0045 -1.069,2.94 -2.271,2.2718c-1.335,-0.8018 -2.27,-2.94 -2.27,-4.9446c0,-2.0045 0.935,-3.0736 2.27,-2.2718z"
                      id="XMLID 209_5"
                    />
                  </g>
                  <path
                    fill="white"
                    d="m122.871,336.168c0.134,-0.134 0.267,-0.134 0.534,-0.267c0.401,-0.268 0.802,-0.535 1.336,-0.669c0.267,-0.133 0.401,-0.267 0.668,-0.4c0.4,-0.268 0.801,-0.401 1.068,-0.669c0.401,-0.267 0.668,-0.401 0.935,-0.534c0.267,-0.134 0.401,-0.267 0.534,-0.267c0.267,-0.134 0.535,-0.268 0.668,-0.535c0.267,-0.267 0.401,-0.535 0.534,-0.802c0.267,-0.668 0.535,-1.336 0.535,-2.138l0,-184.151l-5.209,3.074l0,183.883c0,0.802 -0.134,1.47 -0.401,2.139c-0.133,0.4 -0.401,0.801 -0.801,1.069c-0.134,0 -0.267,0.133 -0.401,0.267c0,-0.134 0,0 0,0z"
                    opacity="0.65"
                    id="XMLID 207_5"
                  />
                </g>
              </g>
            </g>
            <g id="angular_doc">
              <g id="XMLID 158">
                <g id="XMLID 206_6">
                  <g id="XMLID 217_6">
                    <g opacity="0.8" id="Group_19">
                      <g opacity="0.8" id="XMLID 297_6">
                        <path
                          fill="#455A64"
                          d="m2.134,64.8859l0,198.4501c0,2.673 1.87,5.88 4.14,7.083l113.926,65.749c0.534,0.267 0.935,0.401 1.335,0.401c0.134,0 0.401,0 0.535,-0.134c0.133,0 0.267,-0.134 0.4,-0.134c0.134,0 0.134,-0.133 0.267,-0.133c0.134,0 0.134,-0.134 0.267,-0.134c0.134,-0.133 0.134,-0.133 0.268,-0.267c0.267,-0.267 0.534,-0.668 0.801,-1.069c0.267,-0.668 0.401,-1.337 0.401,-2.138l0,-197.248c0,-2.672 -1.87,-5.88 -4.141,-7.082l-114.059,-65.7495c-1.068,-0.6682 -2.003,-0.6682 -2.805,-0.4009c-0.133,0 -0.267,0.1336 -0.4,0.2673c-0.134,0.1336 -0.134,0.1336 -0.267,0.2672l-0.134,0.1337c0,0.1336 -0.133,0.1336 -0.133,0.2672c-0.134,0.1337 -0.134,0.4009 -0.268,0.5346c0,0.1336 -0.133,0.2672 -0.133,0.4009c0,0 0,0 0,0.1336c0,0.1337 0,0.2673 -0.134,0.4009c0.134,0 0.134,0.1337 0.134,0.4009z"
                          opacity="0.8"
                          id="Vector_146"
                        />
                        <path
                          fill="#455A64"
                          d="m4.538,63.2819c-0.134,0 -0.267,0 -0.401,0.1337l-0.133,0l-0.134,0.1336l-0.133,0.1336l-0.134,0.4009l0,0.1337c0,0.1336 0,0.2672 0,0.4009l0,198.4497c0,2.138 1.603,4.945 3.472,6.014l113.926,65.749c0.401,0.134 0.534,0.267 0.668,0.267c0,0 0.133,0 0.267,0l0.134,0l0.133,-0.133l0.134,0c0.133,-0.134 0.267,-0.401 0.4,-0.669c0.268,-0.4 0.267,-0.935 0.267,-1.603l0,-197.381c0,-2.138 -1.602,-4.945 -3.472,-6.014l-113.926,-65.7488c-0.4,-0.1336 -0.801,-0.2673 -1.068,-0.2673zm0,-1.3363c0.534,0 1.202,0.1336 1.87,0.5345l113.925,65.7489c2.271,1.337 4.141,4.544 4.141,7.083l0,197.381c0,0.802 -0.134,1.47 -0.401,2.138c-0.134,0.401 -0.401,0.802 -0.801,1.069c-0.134,0.134 -0.134,0.134 -0.268,0.267c-0.133,0 -0.133,0.134 -0.267,0.134c-0.133,0 -0.133,0.134 -0.267,0.134c-0.133,0 -0.267,0.133 -0.4,0.133c-0.134,0 -0.401,0.134 -0.535,0.134c-0.4,0 -0.801,-0.134 -1.335,-0.401l-113.926,-65.749c-2.27,-1.336 -4.14,-4.544 -4.14,-7.083l0,-198.5834c0,-0.1337 0,-0.4009 0,-0.5346c0,-0.1336 0,-0.2673 0.133,-0.4009c0,0 0,0 0,-0.1336c0,-0.1337 0,-0.2673 0.134,-0.4009c0,-0.1337 0.134,-0.401 0.267,-0.5346c0,-0.1336 0.134,-0.1336 0.134,-0.2673l0.133,-0.1336c0.134,-0.1336 0.134,-0.1336 0.267,-0.2673c0.134,-0.1336 0.267,-0.1336 0.401,-0.2672c0.267,0 0.534,0 0.935,0z"
                          opacity="0.8"
                          id="Vector_147"
                        />
                      </g>
                    </g>
                    <g opacity="0.8" id="Group_20">
                      <g opacity="0.8" id="XMLID 296_6">
                        <path
                          fill="white"
                          d="m2.134,64.8859l0,198.4501c0,2.673 1.87,5.88 4.14,7.083l113.926,65.749c0.534,0.267 0.935,0.401 1.335,0.401c0.134,0 0.401,0 0.535,-0.134c0.133,0 0.267,-0.134 0.4,-0.134c0.134,0 0.134,-0.133 0.267,-0.133c0.134,0 0.134,-0.134 0.267,-0.134c0.134,-0.134 0.134,-0.133 0.268,-0.267c0.267,-0.267 0.534,-0.668 0.801,-1.069c0.267,-0.668 0.401,-1.337 0.401,-2.138l0,-197.248c0,-2.672 -1.87,-5.88 -4.141,-7.082l-114.059,-65.7495c-1.068,-0.6682 -2.003,-0.6682 -2.805,-0.4009c-0.133,0 -0.267,0.1336 -0.4,0.2673c-0.134,0.1336 -0.134,0.1336 -0.267,0.2672l-0.134,0.1337c0,0.1336 -0.134,0.1336 -0.134,0.2672c-0.133,0.1337 -0.133,0.4009 -0.267,0.5346c0,0.1336 -0.133,0.2672 -0.133,0.4009c0,0 0,0 0,0.1336c0,0.1337 0,0.2673 -0.134,0.4009c0.134,0 0.134,0.1337 0.134,0.4009z"
                          opacity="0.8"
                          id="Vector_148"
                        />
                        <rect
                          x="-188.216273"
                          y="94.108137"
                          fill="white"
                          transform="matrix(0.866025,0.5,-2.20305e-8,1,168.001,149.939) "
                          rx="5"
                          height="56.0042"
                          width="130.481"
                          id="angular_text"
                        />
                        <g id="Group 795_3">
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,111.927) "
                            rx="2.58564"
                            height="5.17128"
                            width="78.5342"
                            id="Rectangle 843_6"
                          />
                          <path
                            fill="#375AD7"
                            d="m50.757,118.391c-0.134,0 -0.267,0 -0.267,0c-0.134,0.134 -0.134,0.267 -0.134,0.401l0,2.272c0,0.133 0,0.401 0.134,0.534c0.133,0.134 0.133,0.268 0.267,0.401l1.336,0.802c0.133,0 0.267,0 0.267,0c0.133,-0.134 0.133,-0.134 0.133,-0.401l0,-2.272c0,-0.133 0,-0.401 -0.133,-0.534c-0.134,-0.134 -0.134,-0.267 -0.267,-0.401l-1.336,-0.802z"
                            id="XMLID 198_6"
                          />
                          <path
                            fill="#375AD7"
                            d="m46.082,115.717c-0.133,0 -0.267,0 -0.267,0c-0.134,0.134 -0.134,0.268 -0.134,0.401l0,2.272c0,0.134 0,0.401 0.134,0.535c0.134,0.133 0.134,0.267 0.267,0.4l1.336,0.802c0.133,0 0.267,0 0.267,0c0.133,-0.133 0.133,-0.133 0.133,-0.401l0,-2.271c0,-0.134 0,-0.401 -0.133,-0.535c-0.134,-0.134 -0.134,-0.267 -0.267,-0.401l-1.336,-0.802z"
                            id="XMLID 196_6"
                          />
                          <path
                            fill="#375AD7"
                            d="m41.408,113.045c-0.133,0 -0.267,0 -0.267,0c-0.133,0.134 -0.133,0.268 -0.133,0.401l0,2.272c0,0.134 0,0.401 0.133,0.535c0.134,0.133 0.134,0.267 0.267,0.401l1.336,0.801c0.133,0 0.267,0 0.267,0c0.134,-0.133 0.134,-0.133 0.134,-0.401l0,-2.271c0,-0.134 0,-0.401 -0.134,-0.535c-0.134,-0.134 -0.134,-0.267 -0.267,-0.401l-1.336,-0.802z"
                            id="XMLID 194_6"
                          />
                          <g id="&lt;/&gt;_6">
                            <path
                              id="svg_22"
                              fill="#375AD7"
                              d="m25.944,106.891c0,0.845 -0.82,0.87 -1.423,0.044l-3.951,-5.409c-0.356,-0.487 -0.569,-1.076 -0.569,-1.5746l0,0c0,-0.4669 0.187,-0.7996 0.506,-0.9016l4.027,-1.2854c0.574,-0.1833 1.41,0.7718 1.41,1.6112l0,0c0,0.3245 -0.136,0.5512 -0.364,0.6092l-1.659,0.4222c-0.993,0.253 -0.951,1.999 0.08,3.355l1.523,2.002c0.261,0.344 0.42,0.769 0.42,1.127l0,0z"
                            />
                            <path
                              id="svg_23"
                              fill="#375AD7"
                              d="m30.457,97.965c0.521,0.3009 0.905,1.0852 0.784,1.6003l-2.875,12.1837c-0.087,0.368 -0.411,0.476 -0.784,0.261l0,0c-0.521,-0.301 -0.905,-1.085 -0.784,-1.6l2.875,-12.1838c0.087,-0.3684 0.411,-0.4764 0.784,-0.2612l0,0z"
                            />
                            <path
                              id="svg_24"
                              fill="#375AD7"
                              d="m32.066,110.426c0,-0.358 0.158,-0.601 0.419,-0.642l1.523,-0.244c1.032,-0.165 1.073,-1.863 0.081,-3.262l-1.66,-2.339c-0.228,-0.322 -0.363,-0.704 -0.363,-1.029l0,0c0,-0.839 0.835,-0.829 1.41,0.017l4.026,5.935c0.319,0.471 0.506,1.019 0.506,1.486l0,0c0,0.498 -0.213,0.842 -0.568,0.918l-3.951,0.847c-0.604,0.129 -1.423,-0.843 -1.423,-1.687l0,0z"
                            />
                          </g>
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#507AD0"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,219.537,152.878) "
                            rx="2.58564"
                            height="5.17128"
                            width="30.5578"
                            id="Rectangle 844_6"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,124.854) "
                            rx="2.58564"
                            height="5.17128"
                            width="35.6973"
                            id="Rectangle 845_6"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,200.001,159.102) "
                            rx="2.58564"
                            height="5.17128"
                            width="86.6025"
                            id="Rectangle 868_4"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,209.001,183) "
                            rx="2.58564"
                            height="5.17128"
                            width="50.8068"
                            id="Rectangle 847_6"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,247.001,205.083) "
                            rx="2.58564"
                            height="5.17128"
                            width="34.641"
                            id="Rectangle 869_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#507AD0"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,161.055) "
                            rx="2.58564"
                            height="5.17128"
                            width="39.2598"
                            id="Rectangle 848_6"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#507AD0"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,142.954) "
                            rx="2.58564"
                            height="5.17128"
                            width="22.1324"
                            id="Rectangle 858_6"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#507AD0"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,179.154) "
                            rx="2.58564"
                            height="5.17128"
                            width="38.1051"
                            id="Rectangle 849_6"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,214.001,203) "
                            rx="2.58564"
                            height="5.17128"
                            width="47.6517"
                            id="Rectangle 851_6"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,205.001,216.882) "
                            rx="2.58564"
                            height="5.17128"
                            width="73.9008"
                            id="Rectangle 852_6"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,212) "
                            rx="2.58564"
                            height="5.17128"
                            width="58.9882"
                            id="Rectangle 853_6"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,227.001,243.846) "
                            rx="2.58564"
                            height="5.17128"
                            width="50.8068"
                            id="Rectangle 855_6"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,203.001,248) "
                            rx="2.58564"
                            height="5.17128"
                            width="50.8068"
                            id="Rectangle 870_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,250.001,275.083) "
                            rx="2.58564"
                            height="5.17128"
                            width="32.3316"
                            id="Rectangle 871_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,230) "
                            rx="2.58564"
                            height="5.17128"
                            width="31.1769"
                            id="Rectangle 854_6"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,247) "
                            rx="2.58564"
                            height="5.17128"
                            width="66.9726"
                            id="Rectangle 856_6"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="white"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,233.001,282.773) "
                            rx="2.58564"
                            height="5.17128"
                            width="43.8786"
                            id="Rectangle 872_2"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.619,259.837) "
                            rx="2.58564"
                            height="5.17128"
                            width="44.3195"
                            id="Rectangle 857_5"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#375AD7"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,212.001,283) "
                            rx="2.58564"
                            height="5.17128"
                            width="51.9615"
                            id="Rectangle 873"
                          />
                          <rect
                            x="-188.216273"
                            y="94.108137"
                            fill="#507AD0"
                            transform="matrix(0.866025,0.5,-2.20305e-8,1,171.001,197.253) "
                            rx="2.58564"
                            height="5.17128"
                            width="34.641"
                            id="Rectangle 850_6"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="XMLID 212_6">
                    <g id="XMLID 216_6">
                      <path
                        fill="#375AD7"
                        d="m3.603,62.08c0.802,-0.4009 1.736,-0.2673 2.805,0.4009l113.925,65.7491c2.271,1.336 4.141,4.544 4.141,7.083l0,13.497l5.209,-3.074l0,-13.363c0,-2.673 -1.87,-5.88 -4.141,-7.083l-113.925,-65.7491c-1.202,-0.6682 -2.271,-0.6682 -2.939,-0.2673c-0.801,0.4009 -4.273,2.4055 -5.075,2.8064z"
                        id="Vector_149"
                      />
                    </g>
                    <path
                      fill="#375AD7"
                      d="m2.134,64.7528c0,-2.5391 1.869,-3.6082 4.14,-2.4055l113.926,65.7487c2.27,1.337 4.14,4.544 4.14,7.083l0,13.497l-122.073,-70.4259l0,-13.4973l-0.133,0z"
                      id="XMLID 215_6"
                    />
                    <g opacity="0.4" id="XMLID 214_6">
                      <path
                        fill="white"
                        d="m6.274,62.6213l113.926,65.7487c1.068,0.669 2.137,1.738 2.938,3.074l5.209,-3.074c-0.802,-1.336 -1.737,-2.405 -2.805,-3.073l-113.926,-65.7493c-1.202,-0.6682 -2.27,-0.6682 -2.938,-0.2673c-0.801,0.4009 -4.14,2.4055 -5.075,2.8064c0.668,-0.1336 1.603,-0.1336 2.671,0.5345z"
                        opacity="0.4"
                        id="Vector_150"
                      />
                    </g>
                    <path
                      fill="black"
                      d="m129.549,145.736l0,-13.364c0,-1.336 -0.534,-2.806 -1.202,-4.142l-5.209,3.073c0.801,1.337 1.202,2.807 1.202,4.143l0,13.497l5.209,-3.207z"
                      opacity="0.15"
                      id="XMLID 213_6"
                    />
                  </g>
                  <g id="XMLID 208_6">
                    <path
                      fill="white"
                      d="m26.976,80.1204c1.335,0.8018 2.27,2.94 2.27,4.9445c0,2.0046 -1.068,2.94 -2.27,2.2719c-1.336,-0.8019 -2.271,-2.94 -2.271,-4.9446c0,-2.0045 1.069,-2.94 2.271,-2.2718z"
                      id="XMLID 211_6"
                    />
                    <path
                      fill="white"
                      d="m18.562,75.3095c1.335,0.8018 2.27,2.94 2.27,4.9446c0,2.0045 -1.068,2.94 -2.27,2.2718c-1.336,-0.8018 -2.271,-2.94 -2.271,-4.9446c0,-2.0045 1.069,-3.0736 2.271,-2.2718z"
                      id="XMLID 210_6"
                    />
                    <path
                      fill="white"
                      d="m10.28,70.4987c1.336,0.8018 2.271,2.94 2.271,4.9446c0,2.0045 -1.069,2.94 -2.271,2.2718c-1.335,-0.8018 -2.27,-2.94 -2.27,-4.9446c0,-2.0045 0.935,-3.0736 2.27,-2.2718z"
                      id="XMLID 209_6"
                    />
                  </g>
                  <path
                    fill="white"
                    d="m122.871,336.168c0.134,-0.134 0.267,-0.134 0.534,-0.267c0.401,-0.268 0.802,-0.535 1.336,-0.669c0.267,-0.133 0.401,-0.267 0.668,-0.4c0.4,-0.268 0.801,-0.401 1.068,-0.669c0.401,-0.267 0.668,-0.401 0.935,-0.534c0.267,-0.134 0.401,-0.267 0.534,-0.267c0.267,-0.134 0.535,-0.268 0.668,-0.535c0.267,-0.267 0.401,-0.535 0.534,-0.802c0.267,-0.668 0.535,-1.336 0.535,-2.138l0,-184.151l-5.209,3.074l0,183.883c0,0.802 -0.134,1.47 -0.401,2.139c-0.133,0.4 -0.401,0.801 -0.801,1.069c-0.134,0 -0.267,0.133 -0.401,0.267c0,-0.134 0,0 0,0z"
                    opacity="0.65"
                    id="XMLID 207_6"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    // <svg
    //   className='pc-screen-svg'
    //   width="414"
    //   height="530"
    //   viewBox="0 0 632 588"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   version="1.1">
    //   <g id="Graphic">
    //     <g>
    //       <g id="PC 1">
    //         <g id="Monitor 1">
    //           <g id="XMLID 274">
    //             <g id="XMLID 285">
    //               <path
    //                 id="XMLID 286"
    //                 d="M378.639 413.605L336.434 389.149C332.027 386.61 329.89 383.403 329.89 380.062C329.89 376.721 329.89 374.182 329.89 374.182L380.776 345.317C383.046 343.98 384.916 340.773 384.916 337.967V285.848L458.641 328.478V380.597C458.641 383.269 456.771 386.477 454.5 387.947L410.159 413.471C401.477 418.549 387.32 418.549 378.639 413.605Z"
    //                 fill="#375AD7" />
    //             </g>
    //             <g id="XMLID 283" opacity="0.5">
    //               <path
    //                 id="XMLID 284"
    //                 opacity="0.5"
    //                 d="M378.639 413.605L336.434 389.149C332.027 386.61 329.89 383.403 329.89 380.062C329.89 376.721 329.89 374.182 329.89 374.182L380.776 345.317C383.046 343.98 384.916 340.773 384.916 337.967V285.848L458.641 328.478V380.597C458.641 383.269 456.771 386.477 454.5 387.947L410.159 413.471C401.477 418.549 387.32 418.549 378.639 413.605Z"
    //                 fill="black" />
    //             </g>
    //             <path
    //               id="XMLID 282"
    //               d="M440.878 389.951V395.831L454.367 388.08C456.637 386.744 458.507 383.537 458.507 380.73V328.612L444.484 320.46C444.217 324.069 443.816 327.677 443.549 331.285L453.432 337.031V377.79C453.432 380.463 451.562 383.804 449.292 385.14L440.878 389.951Z"
    //               fill="#3252C4" />
    //             <path
    //               id="XMLID 281"
    //               opacity="0.7"
    //               d="M440.877 389.951V395.831L454.367 388.08C456.637 386.743 458.507 383.536 458.507 380.73V328.612L444.483 320.46C444.216 324.068 443.815 327.676 443.548 331.284L453.432 337.031V377.79C453.432 380.462 451.562 383.803 449.291 385.14L440.877 389.951Z"
    //               fill="white" />
    //             <path
    //               id="XMLID 280"
    //               d="M453.565 377.791C453.565 380.463 451.696 383.671 449.425 385.141L410.292 407.725C401.611 412.803 387.454 412.803 378.773 407.725L336.568 383.27C327.887 378.191 327.887 370.04 336.568 365.095L375.567 342.377C377.838 341.041 379.707 337.833 379.707 335.027V294.268L453.432 336.898V377.791H453.565Z"
    //               fill="#375AD7" />
    //             <path
    //               id="XMLID 279"
    //               opacity="0.3"
    //               d="M453.565 377.791C453.565 380.464 451.696 383.671 449.425 385.141L410.292 407.726C401.611 412.804 387.454 412.804 378.773 407.726L336.568 383.27C327.887 378.192 327.887 370.04 336.568 365.096L375.567 342.377C377.838 341.041 379.707 337.834 379.707 335.027V294.268L453.432 336.898V377.791H453.565Z"
    //               fill="white" />
    //             <path
    //               id="XMLID 278"
    //               opacity="0.7"
    //               d="M367.019 347.321L375.567 342.377C377.837 341.041 379.707 337.833 379.707 335.027V294.268L453.432 336.898V377.657C453.432 380.33 451.562 383.537 449.291 385.007L440.877 389.818L367.019 347.321Z"
    //               fill="#375AD7" />
    //             <path
    //               id="XMLID 277"
    //               opacity="0.6"
    //               d="M367.019 347.322L375.567 342.377C377.837 341.041 379.707 337.834 379.707 335.027V294.268L453.432 336.898V377.657C453.432 380.33 451.562 383.537 449.291 385.007L440.877 389.818L367.019 347.322Z"
    //               fill="white" />
    //             <path
    //               id="XMLID 276"
    //               d="M329.89 374.182C329.89 374.182 329.89 376.721 329.89 380.062C329.89 383.403 332.027 386.61 336.434 389.149L378.639 413.605C382.779 416.01 388.255 417.213 393.731 417.346V411.466C388.255 411.333 382.913 410.13 378.639 407.725L336.434 383.269C332.027 380.73 329.89 377.389 329.89 374.182Z"
    //               fill="#375AD7" />
    //             <path
    //               id="XMLID 275"
    //               opacity="0.2"
    //               d="M329.89 374.182C329.89 374.182 329.89 376.721 329.89 380.062C329.89 383.403 332.027 386.611 336.435 389.15L378.639 413.605C382.78 416.011 388.256 417.213 393.731 417.347V411.467C388.256 411.333 382.913 410.131 378.639 407.725L336.435 383.27C332.027 380.731 329.89 377.39 329.89 374.182Z"
    //               fill="black" />
    //           </g>
    //           <path
    //             id="XMLID 273"
    //             d="M571.898 433.918C569.227 435.521 564.552 435.388 561.748 433.784C525.553 412.803 312.794 289.858 276.466 269.01C273.528 267.273 271.257 263.264 271.257 259.923V10.023C271.257 6.68212 273.528 2.67303 276.199 1.06939C278.87 -0.534245 283.545 -0.400609 286.35 1.20303L571.631 166.11C574.569 167.848 576.84 171.857 576.84 175.198V424.964C576.84 428.438 574.703 432.314 571.898 433.918Z"
    //             fill="#375AD7" />
    //           <path
    //             id="XMLID 272"
    //             opacity="0.3"
    //             d="M571.898 433.918C569.227 435.521 564.552 435.388 561.748 433.784C525.553 412.803 312.794 289.858 276.466 269.01C273.528 267.273 271.257 263.264 271.257 259.923V10.023C271.257 6.68212 273.528 2.67303 276.199 1.06939C278.87 -0.534245 283.545 -0.400609 286.35 1.20303L571.631 166.11C574.569 167.848 576.84 171.857 576.84 175.198V424.964C576.84 428.438 574.703 432.314 571.898 433.918Z"
    //             fill="white" />
    //           <path
    //             id="XMLID 271"
    //             d="M561.748 433.784L276.466 269.01C273.528 267.273 271.258 263.264 271.258 259.923V10.0232C271.258 6.68228 273.662 5.34592 276.466 6.94956L561.748 171.723C564.686 173.46 566.957 177.47 566.957 180.81V430.577C567.09 434.051 564.686 435.388 561.748 433.784Z"
    //             fill="#27DEBF" />
    //           <path
    //             id="XMLID 270"
    //             d="M561.748 433.784L276.466 269.01C273.528 267.273 271.258 263.264 271.258 259.923V10.0232C271.258 6.68228 273.662 5.34592 276.466 6.94956L561.748 171.723C564.686 173.46 566.957 177.47 566.957 180.81V430.577C567.09 434.051 564.686 435.388 561.748 433.784Z"
    //             fill="#375AD7" />
    //           <path
    //             id="XMLID 269"
    //             opacity="0.5"
    //             d="M561.748 433.784L276.466 269.01C273.528 267.273 271.258 263.264 271.258 259.923V10.0232C271.258 6.68228 273.662 5.34592 276.466 6.94956L561.748 171.723C564.686 173.46 566.957 177.47 566.957 180.81V430.577C567.09 434.051 564.686 435.388 561.748 433.784Z"
    //             fill="white" />
    //           <path
    //             id="XMLID 268"
    //             d="M562.549 434.185C565.488 435.521 569.494 435.388 572.032 434.051C574.703 432.448 576.974 428.439 576.974 425.098V397.034L567.09 402.781V430.844C567.09 433.784 565.087 435.121 562.549 434.185Z"
    //             fill="#375AD7" />
    //           <path
    //             id="XMLID 267"
    //             opacity="0.7"
    //             d="M562.549 434.186C565.488 435.522 569.494 435.389 572.032 434.052C574.703 432.449 576.974 428.439 576.974 425.099V397.035L567.09 402.781V430.845C567.09 433.785 565.087 435.121 562.549 434.186Z"
    //             fill="white" />
    //           <path
    //             id="XMLID 266"
    //             d="M567.09 402.646V430.71C567.09 434.051 564.686 435.387 561.882 433.784L276.6 269.01C273.662 267.273 271.391 263.264 271.391 259.923V231.859L567.09 402.646Z"
    //             fill="#375AD7" />
    //           <g id="XMLID 265" opacity="0.5">
    //             <path
    //               id="Vector"
    //               opacity="0.5"
    //               d="M567.09 402.646V430.71C567.09 434.051 564.686 435.387 561.882 433.784L276.6 269.01C273.662 267.273 271.391 263.264 271.391 259.923V231.859L567.09 402.646Z"
    //               fill="white" />
    //           </g>
    //           <path
    //             id="XMLID 264"
    //             d="M557.207 381.933V184.151C557.207 182.147 555.738 179.741 554.002 178.672L284.213 22.8521C282.477 21.783 281.008 22.7184 281.008 24.723V222.505C281.008 224.509 282.477 226.915 284.213 227.984L554.002 383.804C555.738 384.739 557.207 383.938 557.207 381.933Z"
    //             fill="#DDF1FF" />
    //           <path
    //             id="XMLID 263"
    //             d="M419.107 328.612C421.778 330.216 424.049 333.958 424.049 337.165C424.049 340.372 421.778 341.575 419.107 339.972C416.436 338.368 414.165 334.626 414.165 331.419C414.165 328.345 416.436 327.009 419.107 328.612Z"
    //             fill="#375AD7" />
    //           <path
    //             id="XMLID 262"
    //             opacity="0.5"
    //             d="M419.107 328.612C421.778 330.216 424.049 333.958 424.049 337.165C424.049 340.372 421.778 341.575 419.107 339.971C416.436 338.368 414.165 334.626 414.165 331.419C414.165 328.345 416.436 327.009 419.107 328.612Z"
    //             fill="white" />
    //           <path
    //             id="XMLID 261"
    //             d="M565.488 175.732C566.422 177.336 566.957 179.207 567.09 180.944V402.647L576.974 396.9V175.198C576.974 173.46 576.306 171.589 575.371 169.986L565.488 175.732Z"
    //             fill="#375AD7" />
    //           <path
    //             id="XMLID 260"
    //             opacity="0.7"
    //             d="M565.488 175.732C566.422 177.336 566.957 179.207 567.09 180.944V402.647L576.974 396.901V175.198C576.974 173.461 576.306 171.59 575.371 169.986L565.488 175.732Z"
    //             fill="white" />
    //         </g>
    //       </g>
    //       <g id="Keyboard 2">
    //         <g id="Keyboard 3">
    //           <g id="XMLID 252">
    //             <path
    //               id="XMLID 258"
    //               d="M177.633 398.771C177.633 397.969 178.167 397.034 179.235 396.499L246.148 357.878C248.419 356.542 251.891 356.542 254.162 357.878L458.507 476.147C459.575 476.815 460.109 477.617 460.109 478.418C460.109 479.22 460.109 484.566 460.109 485.501C460.109 486.303 459.575 487.238 458.507 487.773L391.594 526.394C389.323 527.73 385.851 527.597 383.58 526.394L179.235 408.393C178.167 407.725 177.633 406.923 177.633 406.121C177.633 405.186 177.633 399.573 177.633 398.771Z"
    //               fill="#375AD7" />
    //             <path
    //               id="XMLID 257"
    //               opacity="0.5"
    //               d="M177.633 398.771C177.633 397.969 178.167 397.034 179.235 396.499L246.148 357.878C248.419 356.542 251.891 356.542 254.162 357.878L458.507 476.147C459.575 476.815 460.109 477.617 460.109 478.418C460.109 479.22 460.109 484.566 460.109 485.501C460.109 486.303 459.575 487.238 458.507 487.773L391.594 526.394C389.323 527.73 385.851 527.597 383.58 526.394L179.235 408.393C178.167 407.725 177.633 406.923 177.633 406.121C177.633 405.186 177.633 399.573 177.633 398.771Z"
    //               fill="white" />
    //             <path
    //               id="XMLID 256"
    //               d="M391.594 519.178L458.507 480.69C460.778 479.354 460.778 477.349 458.507 476.147L254.162 357.878C251.892 356.542 248.419 356.542 246.149 357.878L179.236 396.499C176.965 397.836 176.965 399.84 179.236 401.177L383.581 519.178C385.851 520.38 389.457 520.38 391.594 519.178Z"
    //               fill="#375AD7" />
    //             <path
    //               id="XMLID 255"
    //               opacity="0.3"
    //               d="M391.594 519.178L458.507 480.69C460.778 479.354 460.778 477.349 458.507 476.147L254.162 357.878C251.892 356.542 248.419 356.542 246.149 357.878L179.236 396.499C176.965 397.836 176.965 399.84 179.236 401.177L383.581 519.178C385.851 520.38 389.457 520.38 391.594 519.178Z"
    //               fill="white" />
    //             <path
    //               id="XMLID 254"
    //               d="M387.855 520.113V527.329C389.19 527.329 390.526 526.928 391.594 526.394L458.507 487.773C459.576 487.105 460.11 486.303 460.11 485.501V478.418C460.11 479.22 459.576 480.022 458.507 480.69L391.594 519.177C390.526 519.712 389.19 520.113 387.855 520.113Z"
    //               fill="#375AD7" />
    //             <path
    //               id="XMLID 253"
    //               opacity="0.7"
    //               d="M387.854 520.114V527.33C389.19 527.33 390.525 526.929 391.594 526.395L458.507 487.774C459.575 487.106 460.109 486.304 460.109 485.502V478.419C460.109 479.221 459.575 480.023 458.507 480.691L391.594 519.178C390.525 519.713 389.19 520.114 387.854 520.114Z"
    //               fill="white" />
    //           </g>
    //           <g id="Group">
    //             <path
    //               id="Vector_2"
    //               d="M302.377 407.591L294.764 412.001C294.631 412.135 294.363 412.135 294.23 412.135L287.151 408.259L295.432 403.582L302.377 407.591Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_3"
    //               d="M293.295 402.245L285.682 406.655C285.549 406.655 285.415 406.789 285.281 406.789H285.014L278.203 402.914L286.35 398.236L293.295 402.245Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_4"
    //               d="M317.87 416.545L310.257 420.955C310.124 420.955 309.99 421.088 309.857 421.088L298.504 414.674L306.651 409.997L317.87 416.545Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_5"
    //               d="M327.085 421.89L319.472 426.3C319.339 426.3 319.205 426.434 319.071 426.434L311.993 422.558L320.14 417.881L327.085 421.89Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_6"
    //               d="M369.023 491.247L361.944 487.372L370.091 482.694L377.036 486.703L369.423 491.113C369.29 491.247 369.156 491.247 369.023 491.247Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_7"
    //               d="M336.167 427.102L328.554 431.512C328.421 431.645 328.153 431.645 328.02 431.645L320.941 427.77L329.088 423.093L336.167 427.102Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_8"
    //               d="M285.148 409.595L292.093 413.605L284.48 418.015C284.346 418.148 284.213 418.148 283.946 418.148L276.867 414.273L285.148 409.595Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_9"
    //               d="M310.791 445.544L303.713 441.668L311.86 436.991L318.805 441L311.192 445.41C311.058 445.544 310.925 445.544 310.791 445.544Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_10"
    //               d="M274.731 412.803L267.652 408.927L275.799 404.25L282.744 408.259L275.131 412.669C275.131 412.803 274.864 412.803 274.731 412.803Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_11"
    //               d="M319.873 450.889L312.795 447.014L320.942 442.336L327.887 446.345L320.274 450.755C320.274 450.889 320.007 450.889 319.873 450.889Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_12"
    //               d="M329.088 456.235L322.009 452.359L330.157 447.682L337.102 451.691L329.489 456.101C329.489 456.235 329.222 456.235 329.088 456.235Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_13"
    //               d="M292.36 434.987L277.535 426.568L296.1 416.01L303.979 420.554L294.23 426.167C294.096 426.3 293.963 426.434 293.963 426.568C293.963 426.701 294.096 426.835 294.23 426.968L300.373 430.443L292.761 434.853C292.761 434.987 292.493 434.987 292.36 434.987Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_14"
    //               d="M354.599 437.793L346.986 442.203C346.852 442.203 346.719 442.336 346.585 442.336L339.506 438.461L347.654 433.784L354.599 437.793Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_15"
    //               d="M301.575 440.332L294.497 436.456L302.644 431.779L309.589 435.788L301.976 440.198C301.842 440.332 301.709 440.332 301.575 440.332Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_16"
    //               d="M406.152 488.709L399.073 484.833L407.22 480.156L414.166 484.165L406.553 488.575C406.419 488.709 406.286 488.709 406.152 488.709Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_17"
    //               d="M428.056 480.156L420.443 484.566C420.309 484.566 420.176 484.699 420.042 484.699H419.775L412.964 480.824L421.111 476.147L428.056 480.156Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_18"
    //               d="M393.33 493.386H393.063L386.252 489.51L394.399 484.833L401.344 488.842L393.731 493.252C393.731 493.252 393.464 493.386 393.33 493.386Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_19"
    //               d="M387.32 501.804L380.242 497.929L388.389 493.252L395.334 497.261L387.721 501.671C387.588 501.804 387.454 501.804 387.32 501.804Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_20"
    //               d="M418.84 474.81L411.227 479.22C411.093 479.22 410.96 479.354 410.826 479.354L403.748 475.479L411.895 470.801L418.84 474.81Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_21"
    //               d="M378.105 496.592L371.026 492.717L379.173 488.04L386.118 492.049L378.505 496.459C378.505 496.459 378.372 496.592 378.105 496.592Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_22"
    //               d="M391.327 458.908L383.714 463.318C383.581 463.318 383.447 463.451 383.314 463.451L376.235 459.576L384.382 454.898L391.327 458.908Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_23"
    //               d="M382.111 453.696L374.498 458.106C374.365 458.239 374.231 458.239 373.964 458.239L366.886 454.364L375.033 449.687L382.111 453.696Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_24"
    //               d="M372.896 448.35L365.283 452.76C365.149 452.894 364.882 452.894 364.748 452.894L357.67 449.018L365.817 444.341L372.896 448.35Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_25"
    //               d="M400.542 464.253L392.93 468.663C392.796 468.796 392.529 468.796 392.395 468.796L385.317 464.921L393.464 460.244L400.542 464.253Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_26"
    //               d="M363.813 443.005L356.201 447.415C356.201 447.415 355.934 447.548 355.8 447.548L348.721 443.673L356.868 438.996L363.813 443.005Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_27"
    //               d="M409.625 469.598L402.012 474.008C401.878 474.008 401.745 474.142 401.611 474.142L394.532 470.267L402.679 465.589L409.625 469.598Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_28"
    //               d="M345.383 432.447L337.77 436.857C337.636 436.857 337.503 436.991 337.369 436.991L330.291 433.116L338.438 428.438L345.383 432.447Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_29"
    //               d="M322.41 431.244L314.797 435.654C314.664 435.788 314.397 435.788 314.263 435.788H313.996L307.184 431.913L315.332 427.235L322.41 431.244Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_30"
    //               d="M272.994 390.619L265.381 395.029C265.248 395.163 264.981 395.163 264.847 395.163L257.768 391.288L265.915 386.61L272.994 390.619Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_31"
    //               d="M263.778 385.274L256.166 389.684C256.032 389.817 255.898 389.817 255.631 389.817L248.553 385.942L256.7 381.265L263.778 385.274Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_32"
    //               d="M331.626 436.456L324.013 440.866C323.88 441 323.612 441 323.479 441L316.4 437.125L324.547 432.447L331.626 436.456Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_33"
    //               d="M254.562 379.929L246.95 384.339C246.816 384.472 246.549 384.472 246.415 384.472L239.337 380.597L247.484 375.919L254.562 379.929Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_34"
    //               d="M305.182 430.443H304.915L298.103 426.567L306.25 421.89L313.195 425.899L305.582 430.309C305.449 430.443 305.315 430.443 305.182 430.443Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_35"
    //               d="M262.576 396.633L254.963 401.043C254.83 401.177 254.563 401.177 254.429 401.177L247.35 397.301L255.498 392.624L262.576 396.633Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_36"
    //               d="M244.278 385.942L236.665 390.352C236.532 390.486 236.265 390.486 236.131 390.486L229.053 386.61L237.2 381.933L244.278 385.942Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_37"
    //               d="M245.214 395.831L238.135 391.956L246.283 387.278L253.228 391.287L245.615 395.697C245.615 395.697 245.481 395.831 245.214 395.831Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_38"
    //               d="M217.033 391.154C216.899 391.154 216.766 391.287 216.632 391.287H216.365L209.554 387.412L228.118 376.854L235.063 380.864L217.033 391.154Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_39"
    //               d="M340.842 441.802L333.229 446.212C333.095 446.345 332.828 446.345 332.695 446.345H332.428L325.616 442.47L333.763 437.793L340.842 441.802Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_40"
    //               d="M234.93 401.845H234.663L227.851 397.969L235.998 393.292L242.943 397.301L235.33 401.711C235.197 401.711 235.063 401.845 234.93 401.845Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_41"
    //               d="M225.714 396.5L218.635 392.624L226.783 387.947L233.728 391.956L226.115 396.366C226.115 396.5 225.848 396.5 225.714 396.5Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_42"
    //               d="M386.786 468.262L379.173 472.672C379.04 472.806 378.773 472.806 378.639 472.806L371.56 468.93L379.708 464.253L386.786 468.262Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_43"
    //               d="M405.083 478.953L397.471 483.363C397.337 483.497 397.07 483.497 396.936 483.497H396.669L389.858 479.621L398.005 474.944L405.083 478.953Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_44"
    //               d="M375.032 482.695L367.954 478.819L376.101 474.142L383.046 478.151L375.433 482.561C375.433 482.695 375.166 482.695 375.032 482.695Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_45"
    //               d="M384.115 488.04L377.036 484.165L385.183 479.488L392.128 483.497L384.516 487.907C384.516 488.04 384.382 488.04 384.115 488.04Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_46"
    //               d="M365.818 477.483H365.55L358.739 473.607L366.886 468.93L373.831 472.939L366.218 477.349C366.085 477.349 365.951 477.483 365.818 477.483Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_47"
    //               d="M350.058 447.147L342.445 451.557C342.311 451.691 342.178 451.691 341.911 451.691H341.643L334.832 447.815L342.979 443.138L350.058 447.147Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_48"
    //               d="M347.52 466.792L340.441 462.917L348.588 458.239L355.533 462.248L347.92 466.658C347.787 466.792 347.52 466.792 347.52 466.792Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_49"
    //               d="M356.602 472.137L349.523 468.262L357.67 463.585L364.615 467.594L357.002 472.004C357.002 472.137 356.869 472.137 356.602 472.137Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_50"
    //               d="M368.354 457.705L360.742 462.115C360.608 462.248 360.341 462.248 360.207 462.248L353.129 458.373L361.276 453.696L368.354 457.705Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_51"
    //               d="M395.868 473.607L388.255 478.018C388.121 478.151 387.854 478.151 387.721 478.151L380.642 474.276L388.789 469.598L395.868 473.607Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_52"
    //               d="M359.14 452.359L351.527 456.769C351.393 456.903 351.126 456.903 350.993 456.903L343.914 453.027L352.061 448.35L359.14 452.359Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_53"
    //               d="M377.57 463.05L369.957 467.46C369.824 467.594 369.557 467.594 369.423 467.594H369.156L362.345 463.718L370.492 459.041L377.57 463.05Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_54"
    //               d="M338.304 461.58L331.225 457.705L339.506 453.027L346.451 457.036L338.838 461.446C338.571 461.446 338.438 461.58 338.304 461.58Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_55"
    //               d="M284.079 397.034L276.466 401.444C276.333 401.444 276.199 401.577 276.066 401.577L268.987 397.702L277.134 393.024L284.079 397.034Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_56"
    //               d="M430.193 481.492L437.138 485.501L429.525 489.911C429.391 489.911 429.258 490.045 429.124 490.045H428.857L422.046 486.169L430.193 481.492Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_57"
    //               d="M416.436 485.501L426.72 491.515L419.107 495.925C418.974 495.925 418.84 496.058 418.707 496.058H418.44L408.289 490.178L416.436 485.501Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_58"
    //               d="M441.411 473.34L449.826 478.151L442.213 482.561C442.079 482.694 441.812 482.694 441.679 482.694L433.131 477.883L441.411 473.34Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_59"
    //               d="M397.738 498.597L406.152 503.408L398.539 507.818C398.405 507.952 398.138 507.952 398.005 507.952L389.457 503.141L397.738 498.597Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_60"
    //               d="M403.748 490.044L416.436 497.394L408.823 501.804C408.69 501.804 408.556 501.938 408.423 501.938H408.155L395.601 494.722L403.748 490.044Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_61"
    //               d="M427.121 474.275L420.042 470.4L428.189 465.723L435.134 469.732L427.521 474.142C427.388 474.275 427.121 474.275 427.121 474.275Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_62"
    //               d="M395.734 509.422L388.122 513.832C387.988 513.965 387.854 513.965 387.587 513.965L377.17 508.085L385.317 503.408L395.734 509.422Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_63"
    //               d="M417.905 468.93L410.826 465.055L418.973 460.377L425.918 464.387L418.306 468.797C418.172 468.93 418.038 468.93 417.905 468.93Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_64"
    //               d="M377.17 445.41H376.903L370.091 441.535L378.239 436.857L385.184 440.866L377.571 445.276C377.571 445.41 377.304 445.41 377.17 445.41Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_65"
    //               d="M367.954 440.198L360.876 436.323L369.023 431.646L375.968 435.655L368.355 440.065C368.355 440.198 368.088 440.198 367.954 440.198Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_66"
    //               d="M386.385 450.756H386.118L379.307 446.88L387.454 442.203L394.399 446.212L386.786 450.622C386.652 450.756 386.385 450.756 386.385 450.756Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_67"
    //               d="M399.474 458.373H399.207L392.395 454.497L400.542 449.82L407.487 453.829L399.874 458.239C399.874 458.373 399.741 458.373 399.474 458.373Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_68"
    //               d="M408.69 463.718L401.611 459.843L409.758 455.166L416.703 459.175L409.09 463.585C409.09 463.585 408.823 463.718 408.69 463.718Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_69"
    //               d="M213.026 403.983L205.414 408.393C205.28 408.393 205.146 408.527 205.013 408.527H204.746L197.934 404.651L206.081 399.974L213.026 403.983Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_70"
    //               d="M260.974 431.646L253.361 436.056C253.227 436.189 252.96 436.189 252.827 436.189L245.748 432.314L254.029 427.636L260.974 431.646Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_71"
    //               d="M231.457 414.54L223.844 418.95C223.711 419.083 223.443 419.083 223.31 419.083H223.043L207.149 409.863L215.296 405.185L231.457 414.54Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_72"
    //               d="M251.758 426.3L244.145 430.71C244.011 430.844 243.744 430.844 243.611 430.844L236.532 426.968L244.679 422.291L251.758 426.3Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_73"
    //               d="M242.542 420.955L234.929 425.365C234.796 425.498 234.528 425.498 234.395 425.498L227.316 421.623L235.463 416.946L242.542 420.955Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_74"
    //               d="M358.872 434.853H358.605L351.794 430.977L359.941 426.3L366.886 430.309L359.273 434.719C359.139 434.853 358.872 434.853 358.872 434.853Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_75"
    //               d="M207.282 388.615L214.228 392.624L196.197 403.048C196.064 403.048 195.93 403.181 195.796 403.181H195.529L188.718 399.306L207.282 388.615Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_76"
    //               d="M372.495 496.058L364.882 500.468C364.749 500.602 364.482 500.602 364.348 500.602L355.8 495.791L363.947 491.114L372.495 496.058Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_77"
    //               d="M361.944 489.911L354.331 494.321C354.198 494.455 353.931 494.455 353.797 494.455H353.53L301.309 464.387L309.456 459.709L361.944 489.911Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_78"
    //               d="M359.807 485.902L352.728 482.026L360.875 477.349L367.82 481.358L360.207 485.768C360.074 485.902 359.94 485.902 359.807 485.902Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_79"
    //               d="M383.18 502.205L375.567 506.615C375.433 506.749 375.166 506.749 375.033 506.749L366.485 501.938L374.632 497.261L383.18 502.205Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_80"
    //               d="M307.318 458.373L299.706 462.783C299.572 462.917 299.305 462.917 299.171 462.917H298.904L290.757 458.239L298.904 453.562L307.318 458.373Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_81"
    //               d="M286.083 446.078L278.47 450.488C278.337 450.622 278.069 450.622 277.936 450.622H277.669L269.522 445.944L277.669 441.267L286.083 446.078Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_82"
    //               d="M296.634 452.225L289.021 456.635C288.887 456.635 288.754 456.769 288.62 456.769L280.073 451.958L288.22 447.281L296.634 452.225Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_83"
    //               d="M275.398 439.931L267.786 444.341C267.652 444.474 267.385 444.474 267.251 444.474H266.984L256.834 438.595L264.981 433.917L275.398 439.931Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_84"
    //               d="M265.515 407.457H265.248L258.436 403.582L266.583 398.905L273.528 402.914L265.915 407.324C265.915 407.457 265.782 407.457 265.515 407.457Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_85"
    //               d="M254.028 424.83H253.761L246.95 420.955L255.097 416.277L262.042 420.286L254.429 424.696C254.296 424.83 254.162 424.83 254.028 424.83Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_86"
    //               d="M286.35 443.539L267.251 432.581L275.398 427.904L294.363 438.862L286.75 443.272C286.75 443.405 286.617 443.539 286.35 443.539Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_87"
    //               d="M233.728 413.07H233.461L226.649 409.194L234.796 404.517L241.741 408.526L234.128 412.936C233.995 413.07 233.861 413.07 233.728 413.07Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_88"
    //               d="M224.512 407.724H224.245L217.433 403.849L225.58 399.172L232.525 403.181L224.913 407.591C224.779 407.724 224.645 407.724 224.512 407.724Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_89"
    //               d="M295.566 448.751L288.487 444.876L296.634 440.198L303.579 444.207L295.966 448.617C295.966 448.751 295.699 448.751 295.566 448.751Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_90"
    //               d="M244.146 407.056H243.879L237.067 403.181L245.214 398.503L252.159 402.512L244.546 406.922C244.413 407.056 244.146 407.056 244.146 407.056Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_91"
    //               d="M341.376 475.345L334.298 471.469L342.445 466.792L349.39 470.801L341.777 475.211C341.777 475.345 341.643 475.345 341.376 475.345Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_92"
    //               d="M304.781 454.097L297.703 450.221L305.85 445.544L312.795 449.553L305.182 453.963C305.049 454.097 304.915 454.097 304.781 454.097Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_93"
    //               d="M350.592 480.69H350.325L343.513 476.815L351.66 472.137L358.605 476.147L350.993 480.557C350.993 480.557 350.726 480.69 350.592 480.69Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_94"
    //               d="M332.294 470L325.215 466.124L333.363 461.447L340.308 465.456L332.561 469.866C332.561 470 332.428 470 332.294 470Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_95"
    //               d="M323.078 464.654H322.811L316 460.779L324.147 456.101L331.092 460.11L323.479 464.52C323.345 464.654 323.212 464.654 323.078 464.654Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_96"
    //               d="M313.862 459.442H313.595L306.784 455.566L314.931 450.889L321.876 454.898L314.263 459.308C314.263 459.442 314.13 459.442 313.862 459.442Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_97"
    //               d="M336.434 421.89L329.355 418.015L337.502 413.338L344.447 417.347L336.834 421.757C336.834 421.89 336.567 421.89 336.434 421.89Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_98"
    //               d="M215.297 402.513H215.03L208.218 398.638L216.365 393.96L223.311 397.97L215.698 402.38C215.698 402.513 215.564 402.513 215.297 402.513Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_99"
    //               d="M318.137 411.333L311.058 407.458L319.205 402.78L326.15 406.789L318.538 411.199C318.404 411.333 318.27 411.333 318.137 411.333Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_100"
    //               d="M297.702 399.573L290.624 395.697L298.771 391.02L305.716 395.029L298.103 399.439C297.969 399.573 297.836 399.573 297.702 399.573Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_101"
    //               d="M306.918 404.918L299.84 401.043L307.987 396.365L314.932 400.375L307.319 404.785C307.185 404.785 307.052 404.918 306.918 404.918Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_102"
    //               d="M345.65 427.236L338.571 423.36L346.718 418.683L353.663 422.692L346.05 427.102C345.917 427.236 345.783 427.236 345.65 427.236Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_103"
    //               d="M327.219 416.678H326.952L320.14 412.803L328.287 408.125L335.232 412.135L327.62 416.545C327.62 416.678 327.352 416.678 327.219 416.678Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_104"
    //               d="M288.486 394.227L281.408 390.352L289.555 385.675L296.5 389.684L288.887 394.094C288.887 394.227 288.62 394.227 288.486 394.227Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_105"
    //               d="M252.559 370.44L245.481 366.565L249.888 364.159L256.833 368.168L252.96 370.44C252.826 370.306 252.693 370.44 252.559 370.44Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_106"
    //               d="M237.334 379.26L230.256 375.385L238.403 370.708L245.348 374.717L237.735 379.127C237.601 379.127 237.334 379.26 237.334 379.26Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_107"
    //               d="M280.073 386.343H279.806L273.128 382.467L277.535 380.062L284.48 384.071L280.607 386.209C280.34 386.343 280.207 386.343 280.073 386.343Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_108"
    //               d="M270.857 380.997H270.59L263.912 377.122L268.32 374.717L275.265 378.726L271.391 380.997C271.258 380.997 271.124 380.997 270.857 380.997Z"
    //               fill="#8ABCF0" />
    //             <path
    //               id="Vector_109"
    //               d="M261.775 375.652L254.696 371.777L259.104 369.371L266.049 373.38L262.176 375.652C262.042 375.652 261.908 375.652 261.775 375.652Z"
    //               fill="#8ABCF0" />
    //           </g>
    //         </g>
    //       </g>
    //       <g id="Group_2">
    //         <g id="XMLID 239">
    //           <g id="XMLID 250" opacity="0.8">
    //             <g id="Group_3" opacity="0.8">
    //               <g id="XMLID 303" opacity="0.8">
    //                 <path
    //                   id="Vector_110"
    //                   opacity="0.6"
    //                   d="M251.224 29.6672V228.117C251.224 230.79 253.094 233.997 255.364 235.2L522.615 389.817C523.15 390.084 523.55 390.218 523.951 390.218C524.084 390.218 524.352 390.218 524.485 390.084C524.619 390.084 524.752 389.951 524.886 389.951C525.019 389.951 525.019 389.817 525.153 389.817C525.286 389.817 525.286 389.684 525.42 389.684C525.554 389.55 525.554 389.55 525.687 389.416C525.954 389.149 526.221 388.748 526.488 388.347C526.756 387.679 526.889 387.011 526.889 386.209V188.962C526.889 186.289 525.019 183.082 522.749 181.879L255.364 27.2617C254.296 26.5936 253.361 26.5936 252.559 26.8608C252.426 26.8608 252.292 26.9945 252.159 27.1281C252.025 27.2617 252.025 27.2617 251.892 27.3954L251.758 27.529C251.758 27.6626 251.625 27.6626 251.625 27.7963C251.491 27.9299 251.491 28.1972 251.357 28.3308C251.357 28.4645 251.224 28.5981 251.224 28.7317C251.224 28.7317 251.224 28.7317 251.224 28.8654C251.224 28.999 251.224 29.1326 251.09 29.2663C251.357 29.2663 251.224 29.3999 251.224 29.6672Z"
    //                   fill="#455A64" />
    //                 <path
    //                   id="Vector_111"
    //                   opacity="0.8"
    //                   d="M253.628 28.0637C253.494 28.0637 253.361 28.0637 253.227 28.1973H253.093L252.96 28.3309L252.826 28.4646L252.693 28.8655V28.9991C252.693 29.1328 252.693 29.2664 252.693 29.4V227.85C252.693 229.988 254.296 232.795 256.165 233.864L523.283 388.615C523.684 388.748 523.817 388.882 523.951 388.882C523.951 388.882 524.084 388.882 524.218 388.882H524.351L524.485 388.748H524.618C524.752 388.615 524.886 388.347 525.019 388.08C525.286 387.679 525.286 387.145 525.286 386.476V188.962C525.286 186.824 523.684 184.017 521.814 182.948L254.696 28.4646C254.296 28.1973 253.895 28.0637 253.628 28.0637ZM253.628 26.7273C254.162 26.7273 254.83 26.8609 255.498 27.2618L522.615 181.879C524.886 183.215 526.755 186.423 526.755 188.962V386.343C526.755 387.145 526.622 387.813 526.355 388.481C526.221 388.882 525.954 389.283 525.553 389.55C525.42 389.684 525.42 389.684 525.286 389.817C525.153 389.817 525.153 389.951 525.019 389.951C524.886 389.951 524.886 390.085 524.752 390.085C524.618 390.085 524.485 390.218 524.351 390.218C524.218 390.218 523.951 390.352 523.817 390.352C523.416 390.352 523.016 390.218 522.481 389.951L255.364 235.2C253.093 233.864 251.224 230.656 251.224 228.117V29.6673C251.224 29.5337 251.224 29.2664 251.224 29.1327C251.224 28.9991 251.224 28.8655 251.357 28.7318C251.357 28.7318 251.357 28.7318 251.357 28.5982C251.357 28.4646 251.357 28.3309 251.491 28.1973C251.491 28.0637 251.624 27.7964 251.758 27.6628C251.758 27.5291 251.891 27.5291 251.891 27.3955L252.025 27.2618C252.159 27.1282 252.159 27.1282 252.292 26.9946C252.426 26.8609 252.559 26.8609 252.693 26.7273C252.96 26.7273 253.227 26.7273 253.628 26.7273Z"
    //                   fill="#455A64" />
    //               </g>
    //             </g>
    //             <g id="Group_4" opacity="0.8">
    //               <g id="XMLID 302" opacity="0.7">
    //                 <path
    //                   id="Vector_112"
    //                   opacity="0.7"
    //                   d="M251.224 29.6672V228.117C251.224 230.79 253.094 233.997 255.364 235.2L522.615 389.817C523.15 390.084 523.55 390.218 523.951 390.218C524.084 390.218 524.352 390.218 524.485 390.084C524.619 390.084 524.752 389.951 524.886 389.951C525.019 389.951 525.019 389.817 525.153 389.817C525.286 389.817 525.286 389.684 525.42 389.684C525.554 389.55 525.554 389.55 525.687 389.416C525.954 389.149 526.221 388.748 526.488 388.347C526.756 387.679 526.889 387.011 526.889 386.209V188.962C526.889 186.289 525.019 183.082 522.749 181.879L255.364 27.2617C254.296 26.5936 253.361 26.5936 252.559 26.8608C252.426 26.8608 252.292 26.9945 252.159 27.1281C252.025 27.2617 252.025 27.2617 251.892 27.3954L251.758 27.529C251.758 27.6626 251.625 27.6626 251.625 27.7963C251.491 27.9299 251.491 28.1972 251.357 28.3308C251.357 28.4645 251.224 28.5981 251.224 28.7317C251.224 28.7317 251.224 28.7317 251.224 28.8654C251.224 28.999 251.224 29.1326 251.09 29.2663C251.357 29.2663 251.224 29.3999 251.224 29.6672Z"
    //                   fill="white" />
    //               </g>
    //             </g>
    //           </g>
    //           <g id="XMLID 245">
    //             <g id="XMLID 249">
    //               <path
    //                 id="Vector_113"
    //                 d="M252.694 26.861C253.495 26.4601 254.43 26.5937 255.498 27.2619L522.616 181.879C524.886 183.216 526.756 186.423 526.756 188.962V202.459L531.965 199.386V186.022C531.965 183.349 530.095 180.142 527.825 178.939L260.707 24.3219C259.505 23.6537 258.437 23.6537 257.769 24.0546C256.967 24.4555 253.495 26.4601 252.694 26.861Z"
    //                 fill="#375AD7" />
    //             </g>
    //             <path
    //               id="XMLID 248"
    //               d="M251.224 29.5336C251.224 26.9945 253.094 25.9254 255.364 27.1281L522.616 181.879C524.886 183.215 526.756 186.423 526.756 188.962V202.459L251.224 43.1645V29.5336Z"
    //               fill="#375AD7" />
    //             <g id="XMLID 247" opacity="0.4">
    //               <path
    //                 id="Vector_114"
    //                 opacity="0.4"
    //                 d="M255.365 27.2619L522.616 181.879C523.684 182.547 524.753 183.616 525.554 184.953L530.763 181.879C529.961 180.543 529.026 179.474 527.958 178.806L260.707 24.3219C259.505 23.6537 258.436 23.6537 257.769 24.0546C256.967 24.4555 253.628 26.4601 252.693 26.861C253.361 26.5937 254.296 26.5937 255.365 27.2619Z"
    //                 fill="white" />
    //             </g>
    //             <path
    //               id="XMLID 246"
    //               opacity="0.15"
    //               d="M531.965 199.386V186.022C531.965 184.686 531.431 183.216 530.763 181.879L525.554 184.953C526.355 186.289 526.756 187.759 526.756 189.096V202.593L531.965 199.386Z"
    //               fill="black" />
    //           </g>
    //           <g id="XMLID 241">
    //             <path
    //               id="XMLID 244"
    //               d="M276.066 44.9017C277.402 45.7035 278.337 47.8417 278.337 49.8462C278.337 51.8507 277.268 52.7862 276.066 52.118C274.731 51.3162 273.796 49.178 273.796 47.1735C273.796 45.1689 274.864 44.2335 276.066 44.9017Z"
    //               fill="white" />
    //             <path
    //               id="XMLID 243"
    //               d="M267.652 40.0908C268.988 40.8926 269.923 43.0308 269.923 45.0353C269.923 47.0398 268.854 47.9753 267.652 47.3071C266.317 46.5053 265.382 44.3671 265.382 42.3626C265.382 40.358 266.45 39.2889 267.652 40.0908Z"
    //               fill="white" />
    //             <path
    //               id="XMLID 242"
    //               d="M259.371 35.28C260.707 36.0818 261.642 38.22 261.642 40.2245C261.642 42.2291 260.573 43.1645 259.371 42.4963C258.035 41.6945 257.101 39.5563 257.101 37.5518C256.967 35.5472 258.035 34.4782 259.371 35.28Z"
    //               fill="white" />
    //           </g>
    //           <path
    //             id="XMLID 240"
    //             opacity="0.6"
    //             d="M525.287 389.817C525.42 389.683 525.554 389.683 525.821 389.55C526.222 389.283 526.622 389.015 527.156 388.882C527.424 388.748 527.557 388.614 527.824 388.481C528.225 388.213 528.626 388.08 528.893 387.813C529.293 387.545 529.561 387.412 529.828 387.278C530.095 387.144 530.228 387.011 530.362 387.011C530.629 386.877 530.896 386.743 531.03 386.476C531.297 386.209 531.43 385.942 531.564 385.674C531.831 385.006 532.098 384.338 532.098 383.536V199.385L526.889 202.459V386.343C526.889 387.144 526.756 387.813 526.489 388.481C526.355 388.882 526.088 389.283 525.687 389.55C525.554 389.55 525.42 389.683 525.287 389.817Z"
    //             fill="#455A64" />
    //         </g>
    //         <g id="XMLID 219">
    //           <g id="XMLID 233">
    //             <path
    //               id="XMLID 238"
    //               d="M437.405 273.959C437.405 273.959 447.155 267.678 448.357 267.678C449.559 267.678 476.003 266.742 476.003 266.742L465.052 272.622V273.157L437.405 273.959Z"
    //               fill="#2F4CB7" />
    //             <path
    //               id="XMLID 236"
    //               d="M484.818 292.935V275.563L437.405 216.629V239.614L465.052 272.623V273.157L437.405 274.093V297.078L484.818 292.935Z"
    //               fill="#FAFAFA" />
    //             <path
    //               id="XMLID 235"
    //               d="M437.405 216.629L448.357 210.348L495.77 269.282L484.818 275.563L437.405 216.629Z"
    //               fill="#5774DD" />
    //             <path
    //               id="XMLID 234"
    //               d="M495.77 269.281V286.521L484.818 292.935V275.562L495.77 269.281Z"
    //               fill="#375AD7" />
    //           </g>
    //           <g id="XMLID 228">
    //             <path
    //               id="XMLID 231"
    //               d="M408.689 285.585L423.381 294.138L386.652 159.7L372.495 151.548L408.689 285.585Z"
    //               fill="#FAFAFA" />
    //             <path
    //               id="XMLID 230"
    //               d="M372.629 151.281L383.581 145L397.738 153.152L386.786 159.566L372.629 151.281Z"
    //               fill="#5774DD" />
    //             <path
    //               id="XMLID 229"
    //               d="M397.738 153.152L386.786 159.566L423.514 294.004L434.466 287.723L397.738 153.152Z"
    //               fill="#2F4CB7" />
    //           </g>
    //           <g id="XMLID 220">
    //             <path
    //               id="XMLID 227"
    //               d="M369.557 222.108L358.605 228.389L330.958 195.114L341.91 188.833L369.557 222.108Z"
    //               fill="#2F4CB7" />
    //             <g id="XMLID 224">
    //               <path
    //                 id="XMLID 225"
    //                 d="M330.958 195.113L358.604 194.044V171.059L311.191 175.202V192.574L358.604 251.374V228.389L330.958 195.648V195.113Z"
    //                 fill="#FAFAFA" />
    //             </g>
    //             <path
    //               id="XMLID 223"
    //               d="M369.557 164.778L358.605 171.059L311.191 175.335L322.143 168.921L369.557 164.778Z"
    //               fill="#5774DD" />
    //             <path
    //               id="XMLID 222"
    //               d="M358.605 171.059V194.044L369.557 187.764V164.778"
    //               fill="#375AD7" />
    //             <path
    //               id="XMLID 221"
    //               d="M369.557 222.108L358.605 228.389V251.375L369.557 245.094V222.108Z"
    //               fill="#375AD7" />
    //           </g>
    //         </g>
    //       </g>
    //       <g id="Group_958">
    //         <g id="XMLID 150">
    //           <g id="XMLID 206">
    //             <g id="XMLID 217" opacity="0.8">
    //               <g id="Group_9" opacity="0.8">
    //                 <g id="XMLID 297" opacity="0.8">
    //                   <path
    //                     id="Vector_121"
    //                     opacity="0.8"
    //                     d="M163.944 64.8781V263.328C163.944 266.001 165.814 269.208 168.085 270.411L282.01 336.16C282.545 336.427 282.945 336.561 283.346 336.561C283.479 336.561 283.747 336.561 283.88 336.427C284.014 336.427 284.147 336.294 284.281 336.294C284.414 336.294 284.414 336.16 284.548 336.16C284.681 336.16 284.681 336.026 284.815 336.026C284.949 335.893 284.949 335.893 285.082 335.759C285.349 335.492 285.616 335.091 285.884 334.69C286.151 334.022 286.284 333.354 286.284 332.552V135.304C286.284 132.632 284.414 129.424 282.144 128.222L168.085 62.4727C167.016 61.8045 166.081 61.8045 165.28 62.0718C165.146 62.0718 165.013 62.2054 164.879 62.339C164.746 62.4727 164.746 62.4727 164.612 62.6063L164.479 62.7399C164.479 62.8736 164.345 62.8736 164.345 63.0072C164.211 63.1409 164.211 63.4081 164.078 63.5418C164.078 63.6754 163.944 63.809 163.944 63.9427C163.944 63.9427 163.944 63.9427 163.944 64.0763C163.944 64.2099 163.944 64.3436 163.811 64.4772C163.944 64.4772 163.944 64.6109 163.944 64.8781Z"
    //                     fill="#455A64" />
    //                   <path
    //                     id="Vector_122"
    //                     opacity="0.8"
    //                     d="M166.348 63.2741C166.215 63.2741 166.081 63.2741 165.948 63.4077H165.814L165.681 63.5414L165.547 63.675L165.413 64.0759V64.2096C165.413 64.3432 165.413 64.4768 165.413 64.6105V263.06C165.413 265.199 167.016 268.005 168.886 269.074L282.812 334.823C283.212 334.957 283.346 335.09 283.479 335.09C283.479 335.09 283.613 335.09 283.747 335.09H283.88L284.014 334.957H284.147C284.281 334.823 284.414 334.556 284.548 334.289C284.815 333.888 284.815 333.353 284.815 332.685V135.304C284.815 133.166 283.212 130.36 281.343 129.29L167.417 63.5414C167.016 63.4077 166.616 63.2741 166.348 63.2741ZM166.348 61.9377C166.883 61.9377 167.55 62.0714 168.218 62.4723L282.144 128.221C284.414 129.558 286.284 132.765 286.284 135.304V332.685C286.284 333.487 286.151 334.155 285.884 334.823C285.75 335.224 285.483 335.625 285.082 335.892C284.949 336.026 284.949 336.026 284.815 336.16C284.681 336.16 284.681 336.293 284.548 336.293C284.414 336.293 284.414 336.427 284.281 336.427C284.147 336.427 284.014 336.56 283.88 336.56C283.747 336.56 283.479 336.694 283.346 336.694C282.945 336.694 282.545 336.56 282.01 336.293L168.085 270.544C165.814 269.208 163.944 266 163.944 263.461V64.8777C163.944 64.7441 163.944 64.4768 163.944 64.3432C163.944 64.2096 163.944 64.0759 164.078 63.9423C164.078 63.9423 164.078 63.9423 164.078 63.8087C164.078 63.675 164.078 63.5414 164.211 63.4077C164.211 63.2741 164.345 63.0068 164.479 62.8732C164.479 62.7396 164.612 62.7396 164.612 62.6059L164.746 62.4723C164.879 62.3387 164.879 62.3387 165.013 62.205C165.146 62.0714 165.28 62.0714 165.413 61.9377C165.681 61.9377 165.948 61.9377 166.348 61.9377Z"
    //                     fill="#455A64" />
    //                 </g>
    //               </g>
    //               <g id="Group_10" opacity="0.8">
    //                 <g id="XMLID 296" opacity="0.8">
    //                   <path
    //                     id="Vector_123"
    //                     opacity="0.8"
    //                     d="M163.944 64.8781V263.328C163.944 266.001 165.814 269.208 168.085 270.411L282.01 336.16C282.545 336.427 282.945 336.561 283.346 336.561C283.479 336.561 283.747 336.561 283.88 336.427C284.014 336.427 284.147 336.294 284.281 336.294C284.414 336.294 284.414 336.16 284.548 336.16C284.681 336.16 284.681 336.026 284.815 336.026C284.949 335.893 284.949 335.893 285.082 335.759C285.349 335.492 285.616 335.091 285.884 334.69C286.151 334.022 286.284 333.354 286.284 332.552V135.304C286.284 132.632 284.414 129.424 282.144 128.222L168.085 62.4727C167.016 61.8045 166.081 61.8045 165.28 62.0718C165.146 62.0718 165.013 62.2054 164.879 62.339C164.746 62.4727 164.746 62.4727 164.612 62.6063L164.479 62.7399C164.479 62.8736 164.345 62.8736 164.345 63.0072C164.211 63.1409 164.211 63.4081 164.078 63.5418C164.078 63.6754 163.944 63.809 163.944 63.9427C163.944 63.9427 163.944 63.9427 163.944 64.0763C163.944 64.2099 163.944 64.3436 163.811 64.4772C163.944 64.4772 163.944 64.6109 163.944 64.8781Z"
    //                     fill="white" />
    //                 </g>
    //                 <g id="Group 779">
    //                   <rect
    //                     id="Rectangle 868"
    //                     width="120.089"
    //                     height="44.9452"
    //                     transform="matrix(-0.866025 -0.5 -2.20305e-08 1 277 167.045)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 843"
    //                     width="74.6672"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 178.412 114.995)"
    //                     fill="#375AD7" />
    //                   <path
    //                     id="XMLID 198"
    //                     d="M219.063 121.14C218.936 121.14 218.809 121.14 218.809 121.14C218.682 121.267 218.682 121.394 218.682 121.521V123.681C218.682 123.808 218.682 124.062 218.809 124.189C218.936 124.316 218.936 124.443 219.063 124.57L220.333 125.333C220.46 125.333 220.587 125.333 220.587 125.333C220.714 125.206 220.714 125.206 220.714 124.952V122.792C220.714 122.665 220.714 122.41 220.587 122.283C220.46 122.156 220.46 122.029 220.333 121.902L219.063 121.14Z"
    //                     fill="#375AD7" />
    //                   <path
    //                     id="XMLID 196"
    //                     d="M214.618 118.598C214.491 118.598 214.364 118.598 214.364 118.598C214.237 118.725 214.237 118.852 214.237 118.979V121.139C214.237 121.266 214.237 121.52 214.364 121.647C214.491 121.774 214.491 121.901 214.618 122.028L215.888 122.791C216.015 122.791 216.142 122.791 216.142 122.791C216.269 122.663 216.269 122.663 216.269 122.409V120.249C216.269 120.122 216.269 119.868 216.142 119.741C216.015 119.614 216.015 119.487 215.888 119.36L214.618 118.598Z"
    //                     fill="#375AD7" />
    //                   <path
    //                     id="XMLID 194"
    //                     d="M210.174 116.057C210.047 116.057 209.92 116.057 209.92 116.057C209.793 116.184 209.793 116.311 209.793 116.439V118.598C209.793 118.726 209.793 118.98 209.92 119.107C210.047 119.234 210.047 119.361 210.174 119.488L211.444 120.25C211.571 120.25 211.698 120.25 211.698 120.25C211.825 120.123 211.825 120.123 211.825 119.869V117.709C211.825 117.582 211.825 117.328 211.698 117.201C211.571 117.074 211.571 116.947 211.444 116.82L210.174 116.057Z"
    //                     fill="#375AD7" />
    //                   <g id="&#60;/&#62;">
    //                     <path
    //                       d="M195.471 110.206C195.471 111.009 194.692 111.033 194.118 110.248L190.362 105.105C190.024 104.642 189.821 104.082 189.821 103.608C189.821 103.164 189.999 102.848 190.303 102.751L194.131 101.529C194.677 101.355 195.471 102.263 195.471 103.061C195.471 103.369 195.343 103.585 195.126 103.64L193.65 104.016C192.657 104.268 192.699 106.014 193.73 107.37L195.072 109.135C195.321 109.462 195.471 109.866 195.471 110.206Z"
    //                       fill="#375AD7" />
    //                     <path
    //                       d="M199.763 101.72C200.258 102.006 200.623 102.751 200.508 103.241L197.774 114.825C197.692 115.175 197.383 115.278 197.029 115.073C196.534 114.787 196.168 114.041 196.284 113.552L199.017 101.968C199.1 101.618 199.408 101.515 199.763 101.72Z"
    //                       fill="#375AD7" />
    //                     <path
    //                       d="M201.292 113.567C201.292 113.226 201.442 112.996 201.691 112.956L203.033 112.741C204.064 112.576 204.106 110.878 203.113 109.479L201.637 107.399C201.42 107.094 201.292 106.73 201.292 106.421C201.292 105.623 202.087 105.633 202.633 106.437L206.461 112.08C206.764 112.527 206.942 113.049 206.942 113.493C206.942 113.966 206.74 114.293 206.401 114.366L202.645 115.171C202.071 115.294 201.292 114.37 201.292 113.567Z"
    //                       fill="#375AD7" />
    //                   </g>
    //                   <rect
    //                     id="Rectangle 844"
    //                     width="54.3034"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 224.559 153.928)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 860"
    //                     opacity="0.5"
    //                     width="54.3034"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 224.559 153.93)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 845"
    //                     width="33.9396"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 178.412 127.285)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 846"
    //                     width="53.6246"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 207.511 161.293)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 847"
    //                     width="39.0306"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 207.511 178.503)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 848"
    //                     width="21.0426"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 178.412 161.703)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 858"
    //                     width="21.0426"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 178.412 144.493)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 849"
    //                     width="21.0426"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 178.412 178.912)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 851"
    //                     width="43.1033"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 201.927 192.486)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 852"
    //                     width="51.5882"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 201.927 209.694)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 853"
    //                     width="51.5882"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 197.518 219.44)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 855"
    //                     width="16.9698"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 246.016 247.441)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 866"
    //                     opacity="0.5"
    //                     width="39.0306"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 207.511 178.503)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 867"
    //                     opacity="0.5"
    //                     width="16.9698"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 246.016 247.439)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 854"
    //                     width="51.5882"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 197.518 236.65)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 856"
    //                     width="77.0429"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 197.518 253.856)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 857"
    //                     width="36.648"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 179 260.374)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 859"
    //                     width="48.3051"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 214.541 281.699)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 850"
    //                     width="21.0426"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 178.412 196.12)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 861"
    //                     opacity="0.5"
    //                     width="53.6246"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 207.511 161.295)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 862"
    //                     opacity="0.5"
    //                     width="21.0426"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 178.412 161.703)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 863"
    //                     opacity="0.5"
    //                     width="21.0426"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 178.412 144.495)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 864"
    //                     opacity="0.5"
    //                     width="21.0426"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 178.412 178.912)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 865"
    //                     opacity="0.5"
    //                     width="21.0426"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 178.412 196.12)"
    //                     fill="white" />
    //                 </g>
    //               </g>
    //             </g>
    //             <g id="XMLID 212">
    //               <g id="XMLID 216">
    //                 <path
    //                   id="Vector_124"
    //                   d="M165.414 62.0722C166.215 61.6712 167.15 61.8049 168.218 62.4731L282.144 128.222C284.415 129.559 286.284 132.766 286.284 135.305V148.802L291.493 145.729V132.365C291.493 129.692 289.623 126.485 287.353 125.282L173.427 59.5331C172.225 58.8649 171.157 58.8649 170.489 59.2658C169.688 59.6667 166.215 61.6712 165.414 62.0722Z"
    //                   fill="#375AD7" />
    //               </g>
    //               <path
    //                 id="XMLID 215"
    //                 d="M163.944 64.745C163.944 62.2059 165.814 61.1368 168.084 62.3395L282.01 128.089C284.281 129.425 286.15 132.632 286.15 135.171V148.669L164.078 78.2423V64.745H163.944Z"
    //                 fill="#375AD7" />
    //               <g id="XMLID 214" opacity="0.4">
    //                 <path
    //                   id="Vector_125"
    //                   opacity="0.4"
    //                   d="M168.085 62.6135L282.01 128.363C283.079 129.031 284.147 130.1 284.948 131.436L290.157 128.363C289.356 127.026 288.421 125.957 287.353 125.289L173.427 59.5399C172.225 58.8717 171.156 58.8717 170.489 59.2726C169.687 59.6735 166.348 61.6781 165.413 62.079C166.081 61.9454 167.016 61.9454 168.085 62.6135Z"
    //                   fill="white" />
    //               </g>
    //               <path
    //                 id="XMLID 213"
    //                 opacity="0.15"
    //                 d="M291.359 145.728V132.365C291.359 131.028 290.825 129.558 290.157 128.222L284.949 131.296C285.75 132.632 286.151 134.102 286.151 135.438V148.936L291.359 145.728Z"
    //                 fill="black" />
    //             </g>
    //             <g id="XMLID 208">
    //               <path
    //                 id="XMLID 211"
    //                 d="M188.786 80.1126C190.122 80.9144 191.057 83.0526 191.057 85.0571C191.057 87.0617 189.988 87.9971 188.786 87.3289C187.451 86.5271 186.516 84.389 186.516 82.3844C186.516 80.3799 187.584 79.4444 188.786 80.1126Z"
    //                 fill="white" />
    //               <path
    //                 id="XMLID 210"
    //                 d="M180.372 75.3017C181.708 76.1035 182.643 78.2417 182.643 80.2462C182.643 82.2508 181.574 83.1862 180.372 82.5181C179.037 81.7162 178.102 79.5781 178.102 77.5735C178.102 75.569 179.17 74.4999 180.372 75.3017Z"
    //                 fill="white" />
    //               <path
    //                 id="XMLID 209"
    //                 d="M172.091 70.4909C173.426 71.2927 174.361 73.4309 174.361 75.4355C174.361 77.44 173.293 78.3755 172.091 77.7073C170.755 76.9055 169.82 74.7673 169.82 72.7627C169.82 70.7582 170.755 69.6891 172.091 70.4909Z"
    //                 fill="white" />
    //             </g>
    //             <path
    //               id="XMLID 207"
    //               opacity="0.65"
    //               d="M284.682 336.16C284.815 336.026 284.949 336.026 285.216 335.893C285.617 335.626 286.017 335.358 286.552 335.225C286.819 335.091 286.952 334.957 287.219 334.824C287.62 334.556 288.021 334.423 288.288 334.156C288.688 333.888 288.956 333.755 289.223 333.621C289.49 333.487 289.623 333.354 289.757 333.354C290.024 333.22 290.291 333.086 290.425 332.819C290.692 332.552 290.825 332.285 290.959 332.017C291.226 331.349 291.493 330.681 291.493 329.879V145.728L286.284 148.802V332.686C286.284 333.487 286.151 334.156 285.884 334.824C285.75 335.225 285.483 335.626 285.082 335.893C284.949 335.893 284.815 336.026 284.682 336.16C284.682 336.026 284.682 336.16 284.682 336.16Z"
    //               fill="white" />
    //           </g>
    //         </g>
    //       </g>
    //       <g id="python_doc">
    //         <g id="XMLID 163">
    //           <g id="XMLID 206_2">
    //             <g id="XMLID 217_2" opacity="0.8">
    //               <g id="Group_11" opacity="0.8">
    //                 <g id="XMLID 297_2" opacity="0.8">
    //                   <path
    //                     id="Vector_126"
    //                     opacity="0.8"
    //                     d="M165.134 64.8859V263.336C165.134 266.009 167.004 269.216 169.274 270.419L283.2 336.168C283.734 336.435 284.135 336.569 284.535 336.569C284.669 336.569 284.936 336.569 285.07 336.435C285.203 336.435 285.337 336.301 285.47 336.301C285.604 336.301 285.604 336.168 285.737 336.168C285.871 336.168 285.871 336.034 286.004 336.034C286.138 335.901 286.138 335.901 286.272 335.767C286.539 335.5 286.806 335.099 287.073 334.698C287.34 334.03 287.474 333.361 287.474 332.56V135.312C287.474 132.64 285.604 129.432 283.333 128.23L169.274 62.4805C168.206 61.8123 167.271 61.8123 166.469 62.0796C166.336 62.0796 166.202 62.2132 166.069 62.3469C165.935 62.4805 165.935 62.4805 165.802 62.6141L165.668 62.7478C165.668 62.8814 165.535 62.8814 165.535 63.015C165.401 63.1487 165.401 63.4159 165.267 63.5496C165.267 63.6832 165.134 63.8168 165.134 63.9505C165.134 63.9505 165.134 63.9505 165.134 64.0841C165.134 64.2178 165.134 64.3514 165 64.485C165.134 64.485 165.134 64.6187 165.134 64.8859Z"
    //                     fill="#455A64" />
    //                   <path
    //                     id="Vector_127"
    //                     opacity="0.8"
    //                     d="M167.538 63.2819C167.404 63.2819 167.271 63.2819 167.137 63.4156H167.004L166.87 63.5492L166.737 63.6828L166.603 64.0837V64.2174C166.603 64.351 166.603 64.4846 166.603 64.6183V263.068C166.603 265.206 168.206 268.013 170.075 269.082L284.001 334.831C284.402 334.965 284.535 335.098 284.669 335.098C284.669 335.098 284.802 335.098 284.936 335.098H285.07L285.203 334.965H285.337C285.47 334.831 285.604 334.564 285.737 334.296C286.005 333.896 286.004 333.361 286.004 332.693V135.312C286.004 133.174 284.402 130.367 282.532 129.298L168.606 63.5492C168.206 63.4156 167.805 63.2819 167.538 63.2819ZM167.538 61.9456C168.072 61.9456 168.74 62.0792 169.408 62.4801L283.333 128.229C285.604 129.566 287.474 132.773 287.474 135.312V332.693C287.474 333.495 287.34 334.163 287.073 334.831C286.939 335.232 286.672 335.633 286.272 335.9C286.138 336.034 286.138 336.034 286.004 336.167C285.871 336.167 285.871 336.301 285.737 336.301C285.604 336.301 285.604 336.435 285.47 336.435C285.337 336.435 285.203 336.568 285.07 336.568C284.936 336.568 284.669 336.702 284.535 336.702C284.135 336.702 283.734 336.568 283.2 336.301L169.274 270.552C167.004 269.216 165.134 266.008 165.134 263.469V64.8856C165.134 64.7519 165.134 64.4847 165.134 64.351C165.134 64.2174 165.134 64.0837 165.267 63.9501C165.267 63.9501 165.267 63.9501 165.267 63.8165C165.267 63.6828 165.267 63.5492 165.401 63.4156C165.401 63.2819 165.535 63.0146 165.668 62.881C165.668 62.7474 165.802 62.7474 165.802 62.6137L165.935 62.4801C166.069 62.3465 166.069 62.3465 166.202 62.2128C166.336 62.0792 166.469 62.0792 166.603 61.9456C166.87 61.9456 167.137 61.9456 167.538 61.9456Z"
    //                     fill="#455A64" />
    //                 </g>
    //               </g>
    //               <g id="Group_12" opacity="0.8">
    //                 <g id="XMLID 296_2" opacity="0.8">
    //                   <path
    //                     id="Vector_128"
    //                     opacity="0.8"
    //                     d="M165.134 64.8859V263.336C165.134 266.009 167.004 269.216 169.274 270.419L283.2 336.168C283.734 336.435 284.135 336.569 284.535 336.569C284.669 336.569 284.936 336.569 285.07 336.435C285.203 336.435 285.337 336.301 285.47 336.301C285.604 336.301 285.604 336.168 285.737 336.168C285.871 336.168 285.871 336.034 286.004 336.034C286.138 335.901 286.138 335.901 286.272 335.767C286.539 335.5 286.806 335.099 287.073 334.698C287.34 334.03 287.474 333.361 287.474 332.56V135.312C287.474 132.64 285.604 129.432 283.333 128.23L169.274 62.4805C168.206 61.8123 167.271 61.8123 166.469 62.0796C166.336 62.0796 166.202 62.2132 166.069 62.3469C165.935 62.4805 165.935 62.4805 165.802 62.6141L165.668 62.7478C165.668 62.8814 165.535 62.8814 165.535 63.015C165.401 63.1487 165.401 63.4159 165.267 63.5496C165.267 63.6832 165.134 63.8168 165.134 63.9505C165.134 63.9505 165.134 63.9505 165.134 64.0841C165.134 64.2178 165.134 64.3514 165 64.485C165.134 64.485 165.134 64.6187 165.134 64.8859Z"
    //                     fill="white" />
    //                   <rect
    //                     id="python_text"
    //                     width="130.481"
    //                     height="86.1756"
    //                     rx="5"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 170 170.796)"
    //                     fill="#8ED0FF" />
    //                   <g id="Group 779_2">
    //                     <rect
    //                       id="Rectangle 843_2"
    //                       width="78.5342"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 177 115.927)"
    //                       fill="#375AD7" />
    //                     <path
    //                       id="XMLID 198_2"
    //                       d="M219.756 122.391C219.622 122.391 219.489 122.391 219.489 122.391C219.355 122.525 219.355 122.658 219.355 122.792V125.064C219.355 125.197 219.355 125.465 219.489 125.598C219.622 125.732 219.622 125.866 219.756 125.999L221.092 126.801C221.225 126.801 221.359 126.801 221.359 126.801C221.492 126.667 221.492 126.667 221.492 126.4V124.128C221.492 123.995 221.492 123.727 221.359 123.594C221.225 123.46 221.225 123.327 221.092 123.193L219.756 122.391Z"
    //                       fill="#375AD7" />
    //                     <path
    //                       id="XMLID 196_2"
    //                       d="M215.081 119.717C214.948 119.717 214.814 119.717 214.814 119.717C214.68 119.851 214.68 119.985 214.68 120.118V122.39C214.68 122.524 214.68 122.791 214.814 122.925C214.948 123.058 214.948 123.192 215.081 123.325L216.417 124.127C216.55 124.127 216.684 124.127 216.684 124.127C216.817 123.994 216.817 123.994 216.817 123.726V121.455C216.817 121.321 216.817 121.054 216.684 120.92C216.55 120.786 216.55 120.653 216.417 120.519L215.081 119.717Z"
    //                       fill="#375AD7" />
    //                     <path
    //                       id="XMLID 194_2"
    //                       d="M210.407 117.045C210.274 117.045 210.14 117.045 210.14 117.045C210.007 117.179 210.007 117.313 210.007 117.446V119.718C210.007 119.852 210.007 120.119 210.14 120.253C210.274 120.386 210.274 120.52 210.407 120.654L211.743 121.455C211.876 121.455 212.01 121.455 212.01 121.455C212.144 121.322 212.144 121.322 212.144 121.054V118.783C212.144 118.649 212.144 118.382 212.01 118.248C211.876 118.114 211.876 117.981 211.743 117.847L210.407 117.045Z"
    //                       fill="#375AD7" />
    //                     <g id="&#60;/&#62;_2">
    //                       <path
    //                         d="M194.943 110.891C194.943 111.736 194.123 111.761 193.52 110.935L189.569 105.526C189.213 105.039 189 104.45 189 103.951C189 103.485 189.187 103.152 189.506 103.05L193.533 101.764C194.107 101.581 194.943 102.536 194.943 103.376C194.943 103.7 194.807 103.927 194.579 103.985L192.92 104.407C191.927 104.66 191.969 106.406 193 107.762L194.523 109.764C194.784 110.108 194.943 110.533 194.943 110.891Z"
    //                         fill="#375AD7" />
    //                       <path
    //                         d="M199.456 101.965C199.977 102.266 200.361 103.05 200.24 103.565L197.365 115.749C197.278 116.117 196.954 116.225 196.581 116.01C196.06 115.709 195.676 114.925 195.797 114.41L198.672 102.226C198.759 101.858 199.083 101.75 199.456 101.965Z"
    //                         fill="#375AD7" />
    //                       <path
    //                         d="M201.065 114.426C201.065 114.068 201.223 113.825 201.484 113.784L203.007 113.54C204.039 113.375 204.08 111.677 203.088 110.278L201.428 107.939C201.2 107.617 201.065 107.235 201.065 106.91C201.065 106.071 201.901 106.081 202.475 106.927L206.501 112.862C206.82 113.333 207.007 113.881 207.007 114.348C207.007 114.846 206.795 115.19 206.439 115.266L202.488 116.113C201.884 116.242 201.065 115.27 201.065 114.426Z"
    //                         fill="#375AD7" />
    //                     </g>
    //                     <rect
    //                       id="Rectangle 844_2"
    //                       opacity="0.5"
    //                       width="57.1158"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 225.536 156.878)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 845_2"
    //                       width="35.6973"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 177 128.854)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 846_2"
    //                       opacity="0.5"
    //                       width="56.4018"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 207.605 164.624)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 847_2"
    //                       opacity="0.5"
    //                       width="41.052"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 207.605 182.725)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 848_2"
    //                       opacity="0.5"
    //                       width="22.1324"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 177 165.055)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 858_2"
    //                       opacity="0.5"
    //                       width="22.1324"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 177 146.954)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 849_2"
    //                       width="22.1324"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 177 183.154)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 851_2"
    //                       width="45.3356"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 201.732 197.432)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 852_2"
    //                       width="54.26"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 201.732 215.531)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 853_2"
    //                       width="54.26"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 197.095 225.781)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 855_2"
    //                       opacity="0.5"
    //                       width="17.8487"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 248.104 255.232)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 854_2"
    //                       width="54.26"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 197.095 243.882)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 856_2"
    //                       width="81.033"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 177 251)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 857_2"
    //                       width="76.6511"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 177.618 263)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 850_2"
    //                       opacity="0.5"
    //                       width="22.1324"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 177 201.253)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 864_2"
    //                       opacity="0.5"
    //                       width="22.1324"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 177 183.153)"
    //                       fill="white" />
    //                   </g>
    //                 </g>
    //               </g>
    //             </g>
    //             <g id="XMLID 212_2">
    //               <g id="XMLID 216_2">
    //                 <path
    //                   id="Vector_129"
    //                   d="M166.603 62.08C167.405 61.6791 168.339 61.8127 169.408 62.4809L283.333 128.23C285.604 129.566 287.474 132.774 287.474 135.313V148.81L292.683 145.736V132.373C292.683 129.7 290.813 126.493 288.542 125.29L174.617 59.5409C173.415 58.8727 172.346 58.8727 171.678 59.2736C170.877 59.6745 167.405 61.6791 166.603 62.08Z"
    //                   fill="#375AD7" />
    //               </g>
    //               <path
    //                 id="XMLID 215_2"
    //                 d="M165.134 64.7528C165.134 62.2137 167.003 61.1446 169.274 62.3473L283.2 128.096C285.47 129.433 287.34 132.64 287.34 135.179V148.676L165.267 78.2501V64.7528H165.134Z"
    //                 fill="#375AD7" />
    //               <g id="XMLID 214_2" opacity="0.4">
    //                 <path
    //                   id="Vector_130"
    //                   opacity="0.4"
    //                   d="M169.274 62.6213L283.2 128.37C284.268 129.039 285.337 130.108 286.138 131.444L291.347 128.37C290.545 127.034 289.61 125.965 288.542 125.297L174.616 59.5477C173.414 58.8795 172.346 58.8795 171.678 59.2804C170.877 59.6813 167.538 61.6859 166.603 62.0868C167.271 61.9532 168.206 61.9532 169.274 62.6213Z"
    //                   fill="white" />
    //               </g>
    //               <path
    //                 id="XMLID 213_2"
    //                 opacity="0.15"
    //                 d="M292.549 145.736V132.372C292.549 131.036 292.015 129.566 291.347 128.23L286.138 131.303C286.939 132.64 287.34 134.11 287.34 135.446V148.943L292.549 145.736Z"
    //                 fill="black" />
    //             </g>
    //             <g id="XMLID 208_2">
    //               <path
    //                 id="XMLID 211_2"
    //                 d="M189.976 80.1204C191.311 80.9222 192.246 83.0604 192.246 85.0649C192.246 87.0695 191.178 88.0049 189.976 87.3368C188.64 86.5349 187.705 84.3968 187.705 82.3922C187.705 80.3877 188.774 79.4522 189.976 80.1204Z"
    //                 fill="white" />
    //               <path
    //                 id="XMLID 210_2"
    //                 d="M181.562 75.3095C182.897 76.1113 183.832 78.2495 183.832 80.2541C183.832 82.2586 182.764 83.1941 181.562 82.5259C180.226 81.7241 179.291 79.5859 179.291 77.5813C179.291 75.5768 180.36 74.5077 181.562 75.3095Z"
    //                 fill="white" />
    //               <path
    //                 id="XMLID 209_2"
    //                 d="M173.28 70.4987C174.616 71.3005 175.551 73.4387 175.551 75.4433C175.551 77.4478 174.482 78.3833 173.28 77.7151C171.945 76.9133 171.01 74.7751 171.01 72.7705C171.01 70.766 171.945 69.6969 173.28 70.4987Z"
    //                 fill="white" />
    //             </g>
    //             <path
    //               id="XMLID 207_2"
    //               opacity="0.65"
    //               d="M285.871 336.168C286.005 336.034 286.138 336.034 286.405 335.901C286.806 335.633 287.207 335.366 287.741 335.232C288.008 335.099 288.142 334.965 288.409 334.832C288.809 334.564 289.21 334.431 289.477 334.163C289.878 333.896 290.145 333.762 290.412 333.629C290.679 333.495 290.813 333.362 290.946 333.362C291.213 333.228 291.481 333.094 291.614 332.827C291.881 332.56 292.015 332.292 292.148 332.025C292.415 331.357 292.683 330.689 292.683 329.887V145.736L287.474 148.81V332.693C287.474 333.495 287.34 334.163 287.073 334.832C286.94 335.232 286.672 335.633 286.272 335.901C286.138 335.901 286.005 336.034 285.871 336.168C285.871 336.034 285.871 336.168 285.871 336.168Z"
    //               fill="white" />
    //           </g>
    //         </g>
    //       </g>
    //       <g id="aws_doc">
    //         <g id="XMLID 162">
    //           <g id="XMLID 206_3">
    //             <g id="XMLID 217_3" opacity="0.8">
    //               <g id="Group_13" opacity="0.8">
    //                 <g id="XMLID 297_3" opacity="0.8">
    //                   <path
    //                     id="Vector_131"
    //                     opacity="0.8"
    //                     d="M165.134 64.8859V263.336C165.134 266.009 167.004 269.216 169.274 270.419L283.2 336.168C283.734 336.435 284.135 336.569 284.535 336.569C284.669 336.569 284.936 336.569 285.07 336.435C285.203 336.435 285.337 336.301 285.47 336.301C285.604 336.301 285.604 336.168 285.737 336.168C285.871 336.168 285.871 336.034 286.004 336.034C286.138 335.901 286.138 335.901 286.272 335.767C286.539 335.5 286.806 335.099 287.073 334.698C287.34 334.03 287.474 333.361 287.474 332.56V135.312C287.474 132.64 285.604 129.432 283.333 128.23L169.274 62.4805C168.206 61.8123 167.271 61.8123 166.469 62.0796C166.336 62.0796 166.202 62.2132 166.069 62.3469C165.935 62.4805 165.935 62.4805 165.802 62.6141L165.668 62.7478C165.668 62.8814 165.535 62.8814 165.535 63.015C165.401 63.1487 165.401 63.4159 165.267 63.5496C165.267 63.6832 165.134 63.8168 165.134 63.9505C165.134 63.9505 165.134 63.9505 165.134 64.0841C165.134 64.2178 165.134 64.3514 165 64.485C165.134 64.485 165.134 64.6187 165.134 64.8859Z"
    //                     fill="#455A64" />
    //                   <path
    //                     id="Vector_132"
    //                     opacity="0.8"
    //                     d="M167.538 63.2819C167.404 63.2819 167.271 63.2819 167.137 63.4156H167.004L166.87 63.5492L166.737 63.6828L166.603 64.0837V64.2174C166.603 64.351 166.603 64.4846 166.603 64.6183V263.068C166.603 265.206 168.206 268.013 170.075 269.082L284.001 334.831C284.402 334.965 284.535 335.098 284.669 335.098C284.669 335.098 284.802 335.098 284.936 335.098H285.07L285.203 334.965H285.337C285.47 334.831 285.604 334.564 285.737 334.296C286.005 333.896 286.004 333.361 286.004 332.693V135.312C286.004 133.174 284.402 130.367 282.532 129.298L168.606 63.5492C168.206 63.4156 167.805 63.2819 167.538 63.2819ZM167.538 61.9456C168.072 61.9456 168.74 62.0792 169.408 62.4801L283.333 128.229C285.604 129.566 287.474 132.773 287.474 135.312V332.693C287.474 333.495 287.34 334.163 287.073 334.831C286.939 335.232 286.672 335.633 286.272 335.9C286.138 336.034 286.138 336.034 286.004 336.167C285.871 336.167 285.871 336.301 285.737 336.301C285.604 336.301 285.604 336.435 285.47 336.435C285.337 336.435 285.203 336.568 285.07 336.568C284.936 336.568 284.669 336.702 284.535 336.702C284.135 336.702 283.734 336.568 283.2 336.301L169.274 270.552C167.004 269.216 165.134 266.008 165.134 263.469V64.8856C165.134 64.7519 165.134 64.4847 165.134 64.351C165.134 64.2174 165.134 64.0837 165.267 63.9501C165.267 63.9501 165.267 63.9501 165.267 63.8165C165.267 63.6828 165.267 63.5492 165.401 63.4156C165.401 63.2819 165.535 63.0146 165.668 62.881C165.668 62.7474 165.802 62.7474 165.802 62.6137L165.935 62.4801C166.069 62.3465 166.069 62.3465 166.202 62.2128C166.336 62.0792 166.469 62.0792 166.603 61.9456C166.87 61.9456 167.137 61.9456 167.538 61.9456Z"
    //                     fill="#455A64" />
    //                 </g>
    //               </g>
    //               <g id="Group_14" opacity="0.8">
    //                 <g id="XMLID 296_3" opacity="0.8">
    //                   <path
    //                     id="Vector_133"
    //                     opacity="0.8"
    //                     d="M165.134 64.8859V263.336C165.134 266.009 167.004 269.216 169.274 270.419L283.2 336.168C283.734 336.435 284.135 336.569 284.535 336.569C284.669 336.569 284.936 336.569 285.07 336.435C285.203 336.435 285.337 336.301 285.47 336.301C285.604 336.301 285.604 336.168 285.737 336.168C285.871 336.168 285.871 336.034 286.004 336.034C286.138 335.9 286.138 335.901 286.272 335.767C286.539 335.5 286.806 335.099 287.073 334.698C287.34 334.03 287.474 333.361 287.474 332.56V135.312C287.474 132.64 285.604 129.432 283.333 128.23L169.274 62.4805C168.206 61.8123 167.271 61.8123 166.469 62.0796C166.336 62.0796 166.202 62.2132 166.069 62.3469C165.935 62.4805 165.935 62.4805 165.802 62.6141L165.668 62.7478C165.668 62.8814 165.534 62.8814 165.534 63.015C165.401 63.1487 165.401 63.4159 165.267 63.5496C165.267 63.6832 165.134 63.8168 165.134 63.9505C165.134 63.9505 165.134 63.9505 165.134 64.0841C165.134 64.2178 165.134 64.3514 165 64.485C165.134 64.485 165.134 64.6187 165.134 64.8859Z"
    //                     fill="white" />
    //                   <rect
    //                     id="amazon_text"
    //                     width="130.481"
    //                     height="92.9842"
    //                     rx="5"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 170 133.406)"
    //                     fill="white" />
    //                 </g>
    //                 <g id="Group 779_3">
    //                   <rect
    //                     id="Rectangle 843_3"
    //                     width="74.6672"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 179.602 115.003)"
    //                     fill="#375AD7" />
    //                   <path
    //                     id="XMLID 198_3"
    //                     d="M220.253 121.148C220.126 121.148 219.999 121.148 219.999 121.148C219.872 121.275 219.872 121.402 219.872 121.529V123.689C219.872 123.816 219.872 124.07 219.999 124.197C220.126 124.324 220.126 124.451 220.253 124.578L221.522 125.341C221.649 125.341 221.776 125.341 221.776 125.341C221.903 125.213 221.903 125.213 221.903 124.959V122.799C221.903 122.672 221.903 122.418 221.776 122.291C221.649 122.164 221.649 122.037 221.522 121.91L220.253 121.148Z"
    //                     fill="#375AD7" />
    //                   <path
    //                     id="XMLID 196_3"
    //                     d="M215.807 118.606C215.68 118.606 215.553 118.606 215.553 118.606C215.426 118.733 215.426 118.86 215.426 118.987V121.147C215.426 121.274 215.426 121.528 215.553 121.655C215.68 121.782 215.68 121.909 215.807 122.036L217.077 122.799C217.204 122.799 217.331 122.799 217.331 122.799C217.458 122.672 217.458 122.672 217.458 122.417V120.257C217.458 120.13 217.458 119.876 217.331 119.749C217.204 119.622 217.204 119.495 217.077 119.368L215.807 118.606Z"
    //                     fill="#375AD7" />
    //                   <path
    //                     id="XMLID 194_3"
    //                     d="M211.364 116.065C211.237 116.065 211.11 116.065 211.11 116.065C210.983 116.192 210.983 116.32 210.983 116.447V118.607C210.983 118.734 210.983 118.988 211.11 119.115C211.237 119.242 211.237 119.369 211.364 119.496L212.634 120.258C212.761 120.258 212.888 120.258 212.888 120.258C213.015 120.131 213.015 120.131 213.015 119.877V117.717C213.015 117.59 213.015 117.336 212.888 117.209C212.761 117.082 212.761 116.955 212.634 116.828L211.364 116.065Z"
    //                     fill="#375AD7" />
    //                   <g id="&#60;/&#62;_3">
    //                     <path
    //                       d="M196.661 110.214C196.661 111.017 195.881 111.041 195.308 110.256L191.551 105.113C191.213 104.65 191.011 104.09 191.011 103.616V103.616C191.011 103.172 191.188 102.856 191.492 102.759L195.32 101.537C195.866 101.363 196.661 102.271 196.661 103.069V103.069C196.661 103.377 196.532 103.593 196.315 103.648L194.839 104.024C193.847 104.276 193.888 106.022 194.92 107.378L196.262 109.143C196.51 109.47 196.661 109.874 196.661 110.214V110.214Z"
    //                       fill="#375AD7" />
    //                     <path
    //                       d="M200.952 101.728C201.447 102.014 201.813 102.759 201.697 103.249L198.964 114.833C198.881 115.183 198.573 115.286 198.219 115.081V115.081C197.723 114.795 197.358 114.049 197.473 113.56L200.207 101.976C200.289 101.626 200.598 101.523 200.952 101.728V101.728Z"
    //                       fill="#375AD7" />
    //                     <path
    //                       d="M202.481 113.575C202.481 113.234 202.632 113.004 202.88 112.964L204.223 112.749C205.254 112.584 205.296 110.886 204.303 109.487L202.827 107.407C202.61 107.102 202.481 106.738 202.481 106.429V106.429C202.481 105.631 203.276 105.641 203.822 106.445L207.65 112.088C207.954 112.535 208.131 113.057 208.131 113.501V113.501C208.131 113.974 207.929 114.301 207.591 114.374L203.834 115.179C203.261 115.302 202.481 114.378 202.481 113.575V113.575Z"
    //                       fill="#375AD7" />
    //                   </g>
    //                   <rect
    //                     id="Rectangle 844_3"
    //                     width="54.3034"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 225.748 153.936)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 860_2"
    //                     opacity="0.5"
    //                     width="54.3034"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 225.748 153.938)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 845_3"
    //                     width="33.9396"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 179.602 127.292)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 846_3"
    //                     width="53.6246"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 208.7 161.301)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 847_3"
    //                     width="39.0306"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 208.7 178.511)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 848_3"
    //                     width="21.0426"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 179.602 161.711)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 858_3"
    //                     width="21.0426"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 179.602 144.501)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 849_3"
    //                     width="21.0426"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 179.602 178.919)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 851_3"
    //                     width="43.1033"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 203.116 192.494)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 852_3"
    //                     width="51.5882"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 203.116 209.702)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 853_3"
    //                     width="51.5882"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 198.707 219.448)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 855_3"
    //                     width="16.9698"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 247.205 247.448)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 866_2"
    //                     opacity="0.5"
    //                     width="39.0306"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 208.7 178.511)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 867_2"
    //                     opacity="0.5"
    //                     width="16.9698"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 247.205 247.447)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 854_3"
    //                     width="51.5882"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 198.707 236.658)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 856_3"
    //                     width="77.0429"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 198.707 253.864)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 857_3"
    //                     width="36.648"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 180.189 260.382)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 859_2"
    //                     width="48.3051"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 215.73 281.708)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 850_3"
    //                     width="21.0426"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 179.602 196.128)"
    //                     fill="#375AD7" />
    //                   <rect
    //                     id="Rectangle 861_2"
    //                     opacity="0.5"
    //                     width="53.6246"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 208.7 161.302)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 862_2"
    //                     opacity="0.5"
    //                     width="21.0426"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 179.602 161.711)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 863_2"
    //                     opacity="0.5"
    //                     width="21.0426"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 179.602 144.503)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 864_3"
    //                     opacity="0.5"
    //                     width="21.0426"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 179.602 178.919)"
    //                     fill="white" />
    //                   <rect
    //                     id="Rectangle 865_2"
    //                     opacity="0.5"
    //                     width="21.0426"
    //                     height="4.91665"
    //                     rx="2.45832"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 179.602 196.128)"
    //                     fill="white" />
    //                 </g>
    //               </g>
    //             </g>
    //             <g id="XMLID 212_3">
    //               <g id="XMLID 216_3">
    //                 <path
    //                   id="Vector_134"
    //                   d="M166.603 62.08C167.405 61.6791 168.339 61.8127 169.408 62.4809L283.333 128.23C285.604 129.566 287.474 132.774 287.474 135.313V148.81L292.683 145.736V132.373C292.683 129.7 290.813 126.493 288.542 125.29L174.617 59.5409C173.415 58.8727 172.346 58.8727 171.678 59.2736C170.877 59.6745 167.405 61.6791 166.603 62.08Z"
    //                   fill="#375AD7" />
    //               </g>
    //               <path
    //                 id="XMLID 215_3"
    //                 d="M165.134 64.7528C165.134 62.2137 167.003 61.1446 169.274 62.3473L283.2 128.096C285.47 129.433 287.34 132.64 287.34 135.179V148.676L165.267 78.2501V64.7528H165.134Z"
    //                 fill="#375AD7" />
    //               <g id="XMLID 214_3" opacity="0.4">
    //                 <path
    //                   id="Vector_135"
    //                   opacity="0.4"
    //                   d="M169.274 62.6213L283.2 128.37C284.268 129.039 285.337 130.108 286.138 131.444L291.347 128.37C290.545 127.034 289.61 125.965 288.542 125.297L174.616 59.5477C173.414 58.8795 172.346 58.8795 171.678 59.2804C170.877 59.6813 167.538 61.6859 166.603 62.0868C167.271 61.9532 168.206 61.9532 169.274 62.6213Z"
    //                   fill="white" />
    //               </g>
    //               <path
    //                 id="XMLID 213_3"
    //                 opacity="0.15"
    //                 d="M292.549 145.736V132.372C292.549 131.036 292.015 129.566 291.347 128.23L286.138 131.303C286.939 132.64 287.34 134.11 287.34 135.446V148.943L292.549 145.736Z"
    //                 fill="black" />
    //             </g>
    //             <g id="XMLID 208_3">
    //               <path
    //                 id="XMLID 211_3"
    //                 d="M189.976 80.1204C191.311 80.9222 192.246 83.0604 192.246 85.0649C192.246 87.0695 191.178 88.0049 189.976 87.3368C188.64 86.5349 187.705 84.3968 187.705 82.3922C187.705 80.3877 188.774 79.4522 189.976 80.1204Z"
    //                 fill="white" />
    //               <path
    //                 id="XMLID 210_3"
    //                 d="M181.562 75.3095C182.897 76.1113 183.832 78.2495 183.832 80.2541C183.832 82.2586 182.764 83.1941 181.562 82.5259C180.226 81.7241 179.291 79.5859 179.291 77.5813C179.291 75.5768 180.36 74.5077 181.562 75.3095Z"
    //                 fill="white" />
    //               <path
    //                 id="XMLID 209_3"
    //                 d="M173.28 70.4987C174.616 71.3005 175.551 73.4387 175.551 75.4433C175.551 77.4478 174.482 78.3833 173.28 77.7151C171.945 76.9133 171.01 74.7751 171.01 72.7705C171.01 70.766 171.945 69.6969 173.28 70.4987Z"
    //                 fill="white" />
    //             </g>
    //             <path
    //               id="XMLID 207_3"
    //               opacity="0.65"
    //               d="M285.871 336.168C286.005 336.034 286.138 336.034 286.405 335.901C286.806 335.633 287.207 335.366 287.741 335.232C288.008 335.099 288.142 334.965 288.409 334.832C288.809 334.564 289.21 334.431 289.477 334.163C289.878 333.896 290.145 333.762 290.412 333.629C290.679 333.495 290.813 333.362 290.946 333.362C291.213 333.228 291.481 333.094 291.614 332.827C291.881 332.56 292.015 332.292 292.148 332.025C292.415 331.357 292.683 330.689 292.683 329.887V145.736L287.474 148.81V332.693C287.474 333.495 287.34 334.163 287.073 334.832C286.94 335.232 286.672 335.633 286.272 335.901C286.138 335.901 286.005 336.034 285.871 336.168C285.871 336.034 285.871 336.168 285.871 336.168Z"
    //               fill="white" />
    //           </g>
    //         </g>
    //       </g>
    //       <g id="react_doc">
    //         <g id="XMLID 161">
    //           <g id="XMLID 206_4">
    //             <g id="XMLID 217_4" opacity="0.8">
    //               <g id="Group_15" opacity="0.8">
    //                 <g id="XMLID 297_4" opacity="0.8">
    //                   <path
    //                     id="Vector_136"
    //                     opacity="0.8"
    //                     d="M165.134 66.8859V265.336C165.134 268.009 167.004 271.216 169.274 272.419L283.2 338.168C283.734 338.435 284.135 338.569 284.535 338.569C284.669 338.569 284.936 338.569 285.07 338.435C285.203 338.435 285.337 338.301 285.47 338.301C285.604 338.301 285.604 338.168 285.737 338.168C285.871 338.168 285.871 338.034 286.004 338.034C286.138 337.901 286.138 337.901 286.272 337.767C286.539 337.5 286.806 337.099 287.073 336.698C287.34 336.03 287.474 335.361 287.474 334.56V137.312C287.474 134.64 285.604 131.432 283.333 130.23L169.274 64.4805C168.206 63.8123 167.271 63.8123 166.469 64.0796C166.336 64.0796 166.202 64.2132 166.069 64.3469C165.935 64.4805 165.935 64.4805 165.802 64.6141L165.668 64.7478C165.668 64.8814 165.535 64.8814 165.535 65.015C165.401 65.1487 165.401 65.4159 165.267 65.5496C165.267 65.6832 165.134 65.8168 165.134 65.9505C165.134 65.9505 165.134 65.9505 165.134 66.0841C165.134 66.2178 165.134 66.3514 165 66.485C165.134 66.485 165.134 66.6187 165.134 66.8859Z"
    //                     fill="#455A64" />
    //                   <path
    //                     id="Vector_137"
    //                     opacity="0.8"
    //                     d="M167.538 65.2819C167.404 65.2819 167.271 65.2819 167.137 65.4156H167.004L166.87 65.5492L166.737 65.6828L166.603 66.0837V66.2174C166.603 66.351 166.603 66.4846 166.603 66.6183V265.068C166.603 267.206 168.206 270.013 170.075 271.082L284.001 336.831C284.402 336.965 284.535 337.098 284.669 337.098C284.669 337.098 284.802 337.098 284.936 337.098H285.07L285.203 336.965H285.337C285.47 336.831 285.604 336.564 285.737 336.296C286.005 335.896 286.004 335.361 286.004 334.693V137.312C286.004 135.174 284.402 132.367 282.532 131.298L168.606 65.5492C168.206 65.4156 167.805 65.2819 167.538 65.2819ZM167.538 63.9456C168.072 63.9456 168.74 64.0792 169.408 64.4801L283.333 130.229C285.604 131.566 287.474 134.773 287.474 137.312V334.693C287.474 335.495 287.34 336.163 287.073 336.831C286.939 337.232 286.672 337.633 286.272 337.9C286.138 338.034 286.138 338.034 286.004 338.167C285.871 338.167 285.871 338.301 285.737 338.301C285.604 338.301 285.604 338.435 285.47 338.435C285.337 338.435 285.203 338.568 285.07 338.568C284.936 338.568 284.669 338.702 284.535 338.702C284.135 338.702 283.734 338.568 283.2 338.301L169.274 272.552C167.004 271.216 165.134 268.008 165.134 265.469V66.8856C165.134 66.7519 165.134 66.4847 165.134 66.351C165.134 66.2174 165.134 66.0837 165.267 65.9501C165.267 65.9501 165.267 65.9501 165.267 65.8165C165.267 65.6828 165.267 65.5492 165.401 65.4156C165.401 65.2819 165.535 65.0146 165.668 64.881C165.668 64.7474 165.802 64.7474 165.802 64.6137L165.935 64.4801C166.069 64.3465 166.069 64.3465 166.202 64.2128C166.336 64.0792 166.469 64.0792 166.603 63.9456C166.87 63.9456 167.137 63.9456 167.538 63.9456Z"
    //                     fill="#455A64" />
    //                 </g>
    //               </g>
    //               <g id="Group_16" opacity="0.8">
    //                 <g id="XMLID 296_4" opacity="0.8">
    //                   <path
    //                     id="Vector_138"
    //                     opacity="0.8"
    //                     d="M165.134 66.8859V265.336C165.134 268.009 167.004 271.216 169.274 272.419L283.2 338.168C283.734 338.435 284.135 338.569 284.535 338.569C284.669 338.569 284.936 338.569 285.07 338.435C285.203 338.435 285.337 338.301 285.47 338.301C285.604 338.301 285.604 338.168 285.737 338.168C285.871 338.168 285.871 338.034 286.004 338.034C286.138 337.901 286.138 337.901 286.272 337.767C286.539 337.5 286.806 337.099 287.073 336.698C287.34 336.03 287.474 335.361 287.474 334.56V137.312C287.474 134.64 285.604 131.432 283.333 130.23L169.274 64.4805C168.206 63.8123 167.271 63.8123 166.469 64.0796C166.336 64.0796 166.202 64.2132 166.069 64.3469C165.935 64.4805 165.935 64.4805 165.802 64.6141L165.668 64.7478C165.668 64.8814 165.535 64.8814 165.535 65.015C165.401 65.1487 165.401 65.4159 165.267 65.5496C165.267 65.6832 165.134 65.8168 165.134 65.9505C165.134 65.9505 165.134 65.9505 165.134 66.0841C165.134 66.2178 165.134 66.3514 165 66.485C165.134 66.485 165.134 66.6187 165.134 66.8859Z"
    //                     fill="white" />
    //                   <rect
    //                     id="react_text"
    //                     width="130.481"
    //                     height="85.1063"
    //                     rx="5"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 170.001 139)"
    //                     fill="#8ED0FF" />
    //                   <g id="Group 795">
    //                     <rect
    //                       id="Rectangle 843_4"
    //                       width="38.1051"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 113.927)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 876"
    //                       width="27.7128"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 234.001 148.773)"
    //                       fill="#375AD7" />
    //                     <path
    //                       id="XMLID 198_4"
    //                       d="M213.757 120.391C213.623 120.391 213.49 120.391 213.49 120.391C213.356 120.525 213.356 120.658 213.356 120.792V123.064C213.356 123.197 213.356 123.465 213.49 123.598C213.623 123.732 213.623 123.866 213.757 123.999L215.093 124.801C215.226 124.801 215.36 124.801 215.36 124.801C215.493 124.667 215.493 124.667 215.493 124.4V122.128C215.493 121.995 215.493 121.727 215.36 121.594C215.226 121.46 215.226 121.327 215.093 121.193L213.757 120.391Z"
    //                       fill="#375AD7" />
    //                     <path
    //                       id="XMLID 196_4"
    //                       d="M209.082 117.717C208.949 117.717 208.815 117.717 208.815 117.717C208.681 117.851 208.681 117.985 208.681 118.118V120.39C208.681 120.524 208.681 120.791 208.815 120.925C208.949 121.058 208.949 121.192 209.082 121.325L210.418 122.127C210.551 122.127 210.685 122.127 210.685 122.127C210.818 121.994 210.818 121.994 210.818 121.726V119.455C210.818 119.321 210.818 119.054 210.685 118.92C210.551 118.786 210.551 118.653 210.418 118.519L209.082 117.717Z"
    //                       fill="#375AD7" />
    //                     <path
    //                       id="XMLID 194_4"
    //                       d="M204.408 115.045C204.275 115.045 204.141 115.045 204.141 115.045C204.008 115.179 204.008 115.313 204.008 115.446V117.718C204.008 117.852 204.008 118.119 204.141 118.253C204.275 118.386 204.275 118.52 204.408 118.654L205.744 119.455C205.877 119.455 206.011 119.455 206.011 119.455C206.145 119.322 206.145 119.322 206.145 119.054V116.783C206.145 116.649 206.145 116.382 206.011 116.248C205.877 116.114 205.877 115.981 205.744 115.847L204.408 115.045Z"
    //                       fill="#375AD7" />
    //                     <g id="&#60;/&#62;_4">
    //                       <path
    //                         d="M188.944 108.891C188.944 109.736 188.124 109.761 187.521 108.935L183.57 103.526C183.214 103.039 183.001 102.45 183.001 101.951C183.001 101.485 183.188 101.152 183.507 101.05L187.534 99.7644C188.108 99.5811 188.944 100.536 188.944 101.376C188.944 101.7 188.808 101.927 188.58 101.985L186.921 102.407C185.928 102.66 185.97 104.406 187.001 105.762L188.524 107.764C188.785 108.108 188.944 108.533 188.944 108.891Z"
    //                         fill="#375AD7" />
    //                       <path
    //                         d="M193.457 99.965C193.978 100.266 194.362 101.05 194.241 101.565L191.366 113.749C191.279 114.117 190.955 114.225 190.582 114.01C190.061 113.709 189.677 112.925 189.798 112.41L192.673 100.226C192.76 99.8578 193.084 99.7498 193.457 99.965Z"
    //                         fill="#375AD7" />
    //                       <path
    //                         d="M195.066 112.426C195.066 112.068 195.224 111.825 195.485 111.784L197.008 111.54C198.04 111.375 198.081 109.677 197.089 108.278L195.429 105.939C195.201 105.617 195.066 105.235 195.066 104.91C195.066 104.071 195.901 104.081 196.476 104.927L200.502 110.862C200.821 111.333 201.008 111.881 201.008 112.348C201.008 112.846 200.795 113.19 200.44 113.266L196.489 114.113C195.885 114.242 195.066 113.27 195.066 112.426Z"
    //                         fill="#375AD7" />
    //                     </g>
    //                     <rect
    //                       id="Rectangle 844_4"
    //                       width="40.4145"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 207.001 147.64)"
    //                       fill="#507AD0" />
    //                     <rect
    //                       id="Rectangle 875"
    //                       width="30.5578"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 248.001 171)"
    //                       fill="#507AD0" />
    //                     <rect
    //                       id="Rectangle 845_4"
    //                       width="35.6973"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 126.854)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 868_2"
    //                       width="64.6632"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 219.001 172.072)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 847_4"
    //                       width="50.8068"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 209.001 185)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 848_4"
    //                       width="39.2598"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 163.055)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 858_4"
    //                       width="50.8068"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 144.954)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 849_4"
    //                       width="55.4256"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 181.154)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 851_4"
    //                       width="47.6517"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 228.001 214)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 852_4"
    //                       width="73.9008"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 205.001 218.882)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 853_4"
    //                       width="58.9882"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 214)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 855_4"
    //                       width="50.8068"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 227.001 245.846)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 870"
    //                       width="50.8068"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 203.001 250)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 871"
    //                       width="32.3316"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 250.001 277.083)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 854_4"
    //                       width="31.1769"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 232)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 856_4"
    //                       width="25.4034"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 249)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 872"
    //                       width="43.8786"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 233.001 284.773)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 874"
    //                       width="38.1051"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 197.001 264)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 850_4"
    //                       width="34.641"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 199.253)"
    //                       fill="#375AD7" />
    //                   </g>
    //                 </g>
    //               </g>
    //             </g>
    //             <g id="XMLID 212_4">
    //               <g id="XMLID 216_4">
    //                 <path
    //                   id="Vector_139"
    //                   d="M166.603 64.08C167.405 63.6791 168.339 63.8127 169.408 64.4809L283.333 130.23C285.604 131.566 287.474 134.774 287.474 137.313V150.81L292.683 147.736V134.373C292.683 131.7 290.813 128.493 288.542 127.29L174.617 61.5409C173.415 60.8727 172.346 60.8727 171.678 61.2736C170.877 61.6745 167.405 63.6791 166.603 64.08Z"
    //                   fill="#375AD7" />
    //               </g>
    //               <path
    //                 id="XMLID 215_4"
    //                 d="M165.134 66.7528C165.134 64.2137 167.003 63.1446 169.274 64.3473L283.2 130.096C285.47 131.433 287.34 134.64 287.34 137.179V150.676L165.267 80.2501V66.7528H165.134Z"
    //                 fill="#375AD7" />
    //               <g id="XMLID 214_4" opacity="0.4">
    //                 <path
    //                   id="Vector_140"
    //                   opacity="0.4"
    //                   d="M169.274 64.6213L283.2 130.37C284.268 131.039 285.337 132.108 286.138 133.444L291.347 130.37C290.545 129.034 289.61 127.965 288.542 127.297L174.616 61.5477C173.414 60.8795 172.346 60.8795 171.678 61.2804C170.877 61.6813 167.538 63.6859 166.603 64.0868C167.271 63.9532 168.206 63.9532 169.274 64.6213Z"
    //                   fill="white" />
    //               </g>
    //               <path
    //                 id="XMLID 213_4"
    //                 opacity="0.15"
    //                 d="M292.549 147.736V134.372C292.549 133.036 292.015 131.566 291.347 130.23L286.138 133.303C286.939 134.64 287.34 136.11 287.34 137.446V150.943L292.549 147.736Z"
    //                 fill="black" />
    //             </g>
    //             <g id="XMLID 208_4">
    //               <path
    //                 id="XMLID 211_4"
    //                 d="M189.976 82.1204C191.311 82.9222 192.246 85.0604 192.246 87.0649C192.246 89.0695 191.178 90.0049 189.976 89.3368C188.64 88.5349 187.705 86.3968 187.705 84.3922C187.705 82.3877 188.774 81.4522 189.976 82.1204Z"
    //                 fill="white" />
    //               <path
    //                 id="XMLID 210_4"
    //                 d="M181.562 77.3095C182.897 78.1113 183.832 80.2495 183.832 82.2541C183.832 84.2586 182.764 85.1941 181.562 84.5259C180.226 83.7241 179.291 81.5859 179.291 79.5813C179.291 77.5768 180.36 76.5077 181.562 77.3095Z"
    //                 fill="white" />
    //               <path
    //                 id="XMLID 209_4"
    //                 d="M173.28 72.4987C174.616 73.3005 175.551 75.4387 175.551 77.4433C175.551 79.4478 174.482 80.3833 173.28 79.7151C171.945 78.9133 171.01 76.7751 171.01 74.7705C171.01 72.766 171.945 71.6969 173.28 72.4987Z"
    //                 fill="white" />
    //             </g>
    //             <path
    //               id="XMLID 207_4"
    //               opacity="0.65"
    //               d="M285.871 338.168C286.005 338.034 286.138 338.034 286.405 337.901C286.806 337.633 287.207 337.366 287.741 337.232C288.008 337.099 288.142 336.965 288.409 336.832C288.809 336.564 289.21 336.431 289.477 336.163C289.878 335.896 290.145 335.762 290.412 335.629C290.679 335.495 290.813 335.362 290.946 335.362C291.213 335.228 291.481 335.094 291.614 334.827C291.881 334.56 292.015 334.292 292.148 334.025C292.415 333.357 292.683 332.689 292.683 331.887V147.736L287.474 150.81V334.693C287.474 335.495 287.34 336.163 287.073 336.832C286.94 337.232 286.672 337.633 286.272 337.901C286.138 337.901 286.005 338.034 285.871 338.168C285.871 338.034 285.871 338.168 285.871 338.168Z"
    //               fill="white" />
    //           </g>
    //         </g>
    //       </g>
    //       <g id="vue_doc">
    //         <g id="XMLID 159">
    //           <g id="XMLID 206_5">
    //             <g id="XMLID 217_5" opacity="0.8">
    //               <g id="Group_17" opacity="0.8">
    //                 <g id="XMLID 297_5" opacity="0.8">
    //                   <path
    //                     id="Vector_141"
    //                     opacity="0.8"
    //                     d="M165.134 64.8859V263.336C165.134 266.009 167.004 269.216 169.274 270.419L283.2 336.168C283.734 336.435 284.135 336.569 284.535 336.569C284.669 336.569 284.936 336.569 285.07 336.435C285.203 336.435 285.337 336.301 285.47 336.301C285.604 336.301 285.604 336.168 285.737 336.168C285.871 336.168 285.871 336.034 286.004 336.034C286.138 335.901 286.138 335.901 286.272 335.767C286.539 335.5 286.806 335.099 287.073 334.698C287.34 334.03 287.474 333.361 287.474 332.56V135.312C287.474 132.64 285.604 129.432 283.333 128.23L169.274 62.4805C168.206 61.8123 167.271 61.8123 166.469 62.0796C166.336 62.0796 166.202 62.2132 166.069 62.3469C165.935 62.4805 165.935 62.4805 165.802 62.6141L165.668 62.7478C165.668 62.8814 165.535 62.8814 165.535 63.015C165.401 63.1487 165.401 63.4159 165.267 63.5496C165.267 63.6832 165.134 63.8168 165.134 63.9505C165.134 63.9505 165.134 63.9505 165.134 64.0841C165.134 64.2178 165.134 64.3514 165 64.485C165.134 64.485 165.134 64.6187 165.134 64.8859Z"
    //                     fill="#455A64" />
    //                   <path
    //                     id="Vector_142"
    //                     opacity="0.8"
    //                     d="M167.538 63.2819C167.404 63.2819 167.271 63.2819 167.137 63.4156H167.004L166.87 63.5492L166.737 63.6828L166.603 64.0837V64.2174C166.603 64.351 166.603 64.4846 166.603 64.6183V263.068C166.603 265.206 168.206 268.013 170.075 269.082L284.001 334.831C284.402 334.965 284.535 335.098 284.669 335.098C284.669 335.098 284.802 335.098 284.936 335.098H285.07L285.203 334.965H285.337C285.47 334.831 285.604 334.564 285.737 334.296C286.005 333.896 286.004 333.361 286.004 332.693V135.312C286.004 133.174 284.402 130.367 282.532 129.298L168.606 63.5492C168.206 63.4156 167.805 63.2819 167.538 63.2819ZM167.538 61.9456C168.072 61.9456 168.74 62.0792 169.408 62.4801L283.333 128.229C285.604 129.566 287.474 132.773 287.474 135.312V332.693C287.474 333.495 287.34 334.163 287.073 334.831C286.939 335.232 286.672 335.633 286.272 335.9C286.138 336.034 286.138 336.034 286.004 336.167C285.871 336.167 285.871 336.301 285.737 336.301C285.604 336.301 285.604 336.435 285.47 336.435C285.337 336.435 285.203 336.568 285.07 336.568C284.936 336.568 284.669 336.702 284.535 336.702C284.135 336.702 283.734 336.568 283.2 336.301L169.274 270.552C167.004 269.216 165.134 266.008 165.134 263.469V64.8856C165.134 64.7519 165.134 64.4847 165.134 64.351C165.134 64.2174 165.134 64.0837 165.267 63.9501C165.267 63.9501 165.267 63.9501 165.267 63.8165C165.267 63.6828 165.267 63.5492 165.401 63.4156C165.401 63.2819 165.535 63.0146 165.668 62.881C165.668 62.7474 165.802 62.7474 165.802 62.6137L165.935 62.4801C166.069 62.3465 166.069 62.3465 166.202 62.2128C166.336 62.0792 166.469 62.0792 166.603 61.9456C166.87 61.9456 167.137 61.9456 167.538 61.9456Z"
    //                     fill="#455A64" />
    //                 </g>
    //               </g>
    //               <g id="Group_18" opacity="0.8">
    //                 <g id="XMLID 296_5" opacity="0.8">
    //                   <path
    //                     id="Vector_143"
    //                     opacity="0.8"
    //                     d="M165.134 64.8859V263.336C165.134 266.009 167.004 269.216 169.274 270.419L283.2 336.168C283.734 336.435 284.135 336.569 284.535 336.569C284.669 336.569 284.936 336.569 285.07 336.435C285.203 336.435 285.337 336.301 285.47 336.301C285.604 336.301 285.604 336.168 285.737 336.168C285.871 336.168 285.871 336.034 286.004 336.034C286.138 335.901 286.138 335.901 286.272 335.767C286.539 335.5 286.806 335.099 287.073 334.698C287.34 334.03 287.474 333.361 287.474 332.56V135.312C287.474 132.64 285.604 129.432 283.333 128.23L169.274 62.4805C168.206 61.8123 167.271 61.8123 166.469 62.0796C166.336 62.0796 166.202 62.2132 166.069 62.3469C165.935 62.4805 165.935 62.4805 165.802 62.6141L165.668 62.7478C165.668 62.8814 165.535 62.8814 165.535 63.015C165.401 63.1487 165.401 63.4159 165.267 63.5496C165.267 63.6832 165.134 63.8168 165.134 63.9505C165.134 63.9505 165.134 63.9505 165.134 64.0841C165.134 64.2178 165.134 64.3514 165 64.485C165.134 64.485 165.134 64.6187 165.134 64.8859Z"
    //                     fill="white" />
    //                   <rect
    //                     id="vue_text"
    //                     width="130.481"
    //                     height="56.0042"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 170 110)"
    //                     fill="#21BDA2" />
    //                   <g id="Group 795_2">
    //                     <rect
    //                       id="Rectangle 843_5"
    //                       width="100.459"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 111.927)"
    //                       fill="#375AD7" />
    //                     <path
    //                       id="XMLID 198_5"
    //                       d="M213.757 118.391C213.623 118.391 213.49 118.391 213.49 118.391C213.356 118.525 213.356 118.658 213.356 118.792V121.064C213.356 121.197 213.356 121.465 213.49 121.598C213.623 121.732 213.623 121.866 213.757 121.999L215.093 122.801C215.226 122.801 215.36 122.801 215.36 122.801C215.493 122.667 215.493 122.667 215.493 122.4V120.128C215.493 119.995 215.493 119.727 215.36 119.594C215.226 119.46 215.226 119.327 215.093 119.193L213.757 118.391Z"
    //                       fill="#375AD7" />
    //                     <path
    //                       id="XMLID 196_5"
    //                       d="M209.082 115.717C208.949 115.717 208.815 115.717 208.815 115.717C208.681 115.851 208.681 115.985 208.681 116.118V118.39C208.681 118.524 208.681 118.791 208.815 118.925C208.949 119.058 208.949 119.192 209.082 119.325L210.418 120.127C210.551 120.127 210.685 120.127 210.685 120.127C210.818 119.994 210.818 119.994 210.818 119.726V117.455C210.818 117.321 210.818 117.054 210.685 116.92C210.551 116.786 210.551 116.653 210.418 116.519L209.082 115.717Z"
    //                       fill="#375AD7" />
    //                     <path
    //                       id="XMLID 194_5"
    //                       d="M204.408 113.045C204.275 113.045 204.141 113.045 204.141 113.045C204.008 113.179 204.008 113.313 204.008 113.446V115.718C204.008 115.852 204.008 116.119 204.141 116.253C204.275 116.386 204.275 116.52 204.408 116.654L205.744 117.455C205.877 117.455 206.011 117.455 206.011 117.455C206.145 117.322 206.145 117.322 206.145 117.054V114.783C206.145 114.649 206.145 114.382 206.011 114.248C205.877 114.114 205.877 113.981 205.744 113.847L204.408 113.045Z"
    //                       fill="#375AD7" />
    //                     <g id="&#60;/&#62;_5">
    //                       <path
    //                         d="M188.944 106.891C188.944 107.736 188.124 107.761 187.521 106.935L183.57 101.526C183.214 101.039 183.001 100.45 183.001 99.9514C183.001 99.4845 183.188 99.1518 183.507 99.0498L187.534 97.7644C188.108 97.5811 188.944 98.5362 188.944 99.3756C188.944 99.7001 188.808 99.9268 188.58 99.9848L186.921 100.407C185.928 100.66 185.97 102.406 187.001 103.762L188.524 105.764C188.785 106.108 188.944 106.533 188.944 106.891Z"
    //                         fill="#375AD7" />
    //                       <path
    //                         d="M193.457 97.965C193.978 98.2659 194.362 99.0502 194.241 99.5653L191.366 111.749C191.279 112.117 190.955 112.225 190.582 112.01C190.061 111.709 189.677 110.925 189.798 110.41L192.673 98.2262C192.76 97.8578 193.084 97.7498 193.457 97.965Z"
    //                         fill="#375AD7" />
    //                       <path
    //                         d="M195.066 110.426C195.066 110.068 195.224 109.825 195.485 109.784L197.008 109.54C198.04 109.375 198.081 107.677 197.089 106.278L195.429 103.939C195.201 103.617 195.066 103.235 195.066 102.91C195.066 102.071 195.901 102.081 196.476 102.927L200.502 108.862C200.821 109.333 201.008 109.881 201.008 110.348C201.008 110.846 200.795 111.19 200.44 111.266L196.489 112.113C195.885 112.242 195.066 111.27 195.066 110.426Z"
    //                         fill="#375AD7" />
    //                     </g>
    //                     <rect
    //                       id="Rectangle 844_5"
    //                       width="30.5578"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 219.537 152.878)"
    //                       fill="#507AD0" />
    //                     <rect
    //                       id="Rectangle 845_5"
    //                       width="35.6973"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 124.854)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 846_4"
    //                       width="34.641"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 195.001 156)"
    //                       fill="#698BDA" />
    //                     <rect
    //                       id="Rectangle 868_3"
    //                       width="50.8068"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 231.001 177)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 847_5"
    //                       width="50.8068"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 195.001 175)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 869"
    //                       width="34.641"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 247.001 205.083)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 848_5"
    //                       width="22.1324"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 161.055)"
    //                       fill="#507AD0" />
    //                     <rect
    //                       id="Rectangle 858_5"
    //                       width="22.1324"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 142.954)"
    //                       fill="#507AD0" />
    //                     <rect
    //                       id="Rectangle 849_5"
    //                       width="22.1324"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 179.154)"
    //                       fill="#507AD0" />
    //                     <rect
    //                       id="Rectangle 851_5"
    //                       width="94.9944"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 195.733 193.432)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 852_5"
    //                       width="84.6021"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 195.733 211.531)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 853_5"
    //                       width="58.9882"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 212)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 855_5"
    //                       width="50.8068"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 227.001 243.846)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 854_5"
    //                       width="85.4478"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 230)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 856_5"
    //                       width="95.8401"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 247)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 857_4"
    //                       width="110.137"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.619 259.837)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 850_5"
    //                       width="22.1324"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 197.253)"
    //                       fill="#507AD0" />
    //                   </g>
    //                 </g>
    //               </g>
    //             </g>
    //             <g id="XMLID 212_5">
    //               <g id="XMLID 216_5">
    //                 <path
    //                   id="Vector_144"
    //                   d="M166.603 62.08C167.405 61.6791 168.339 61.8127 169.408 62.4809L283.333 128.23C285.604 129.566 287.474 132.774 287.474 135.313V148.81L292.683 145.736V132.373C292.683 129.7 290.813 126.493 288.542 125.29L174.617 59.5409C173.415 58.8727 172.346 58.8727 171.678 59.2736C170.877 59.6745 167.405 61.6791 166.603 62.08Z"
    //                   fill="#375AD7" />
    //               </g>
    //               <path
    //                 id="XMLID 215_5"
    //                 d="M165.134 64.7528C165.134 62.2137 167.003 61.1446 169.274 62.3473L283.2 128.096C285.47 129.433 287.34 132.64 287.34 135.179V148.676L165.267 78.2501V64.7528H165.134Z"
    //                 fill="#375AD7" />
    //               <g id="XMLID 214_5" opacity="0.4">
    //                 <path
    //                   id="Vector_145"
    //                   opacity="0.4"
    //                   d="M169.274 62.6213L283.2 128.37C284.268 129.039 285.337 130.108 286.138 131.444L291.347 128.37C290.545 127.034 289.61 125.965 288.542 125.297L174.616 59.5477C173.414 58.8795 172.346 58.8795 171.678 59.2804C170.877 59.6813 167.538 61.6859 166.603 62.0868C167.271 61.9532 168.206 61.9532 169.274 62.6213Z"
    //                   fill="white" />
    //               </g>
    //               <path
    //                 id="XMLID 213_5"
    //                 opacity="0.15"
    //                 d="M292.549 145.736V132.372C292.549 131.036 292.015 129.566 291.347 128.23L286.138 131.303C286.939 132.64 287.34 134.11 287.34 135.446V148.943L292.549 145.736Z"
    //                 fill="black" />
    //             </g>
    //             <g id="XMLID 208_5">
    //               <path
    //                 id="XMLID 211_5"
    //                 d="M189.976 80.1204C191.311 80.9222 192.246 83.0604 192.246 85.0649C192.246 87.0695 191.178 88.0049 189.976 87.3368C188.64 86.5349 187.705 84.3968 187.705 82.3922C187.705 80.3877 188.774 79.4522 189.976 80.1204Z"
    //                 fill="white" />
    //               <path
    //                 id="XMLID 210_5"
    //                 d="M181.562 75.3095C182.897 76.1113 183.832 78.2495 183.832 80.2541C183.832 82.2586 182.764 83.1941 181.562 82.5259C180.226 81.7241 179.291 79.5859 179.291 77.5813C179.291 75.5768 180.36 74.5077 181.562 75.3095Z"
    //                 fill="white" />
    //               <path
    //                 id="XMLID 209_5"
    //                 d="M173.28 70.4987C174.616 71.3005 175.551 73.4387 175.551 75.4433C175.551 77.4478 174.482 78.3833 173.28 77.7151C171.945 76.9133 171.01 74.7751 171.01 72.7705C171.01 70.766 171.945 69.6969 173.28 70.4987Z"
    //                 fill="white" />
    //             </g>
    //             <path
    //               id="XMLID 207_5"
    //               opacity="0.65"
    //               d="M285.871 336.168C286.005 336.034 286.138 336.034 286.405 335.901C286.806 335.633 287.207 335.366 287.741 335.232C288.008 335.099 288.142 334.965 288.409 334.832C288.809 334.564 289.21 334.431 289.477 334.163C289.878 333.896 290.145 333.762 290.412 333.629C290.679 333.495 290.813 333.362 290.946 333.362C291.213 333.228 291.481 333.094 291.614 332.827C291.881 332.56 292.015 332.292 292.148 332.025C292.415 331.357 292.683 330.689 292.683 329.887V145.736L287.474 148.81V332.693C287.474 333.495 287.34 334.163 287.073 334.832C286.94 335.232 286.672 335.633 286.272 335.901C286.138 335.901 286.005 336.034 285.871 336.168C285.871 336.034 285.871 336.168 285.871 336.168Z"
    //               fill="white" />
    //           </g>
    //         </g>
    //       </g>
    //       <g id="angular_doc">
    //         <g id="XMLID 158">
    //           <g id="XMLID 206_6">
    //             <g id="XMLID 217_6" opacity="01">
    //               <g id="Group_19" opacity="0.8">
    //                 <g id="XMLID 297_6" opacity="0.8">
    //                   <path
    //                     id="Vector_146"
    //                     opacity="0.8"
    //                     d="M165.134 64.8859V263.336C165.134 266.009 167.004 269.216 169.274 270.419L283.2 336.168C283.734 336.435 284.135 336.569 284.535 336.569C284.669 336.569 284.936 336.569 285.07 336.435C285.203 336.435 285.337 336.301 285.47 336.301C285.604 336.301 285.604 336.168 285.737 336.168C285.871 336.168 285.871 336.034 286.004 336.034C286.138 335.901 286.138 335.901 286.272 335.767C286.539 335.5 286.806 335.099 287.073 334.698C287.34 334.03 287.474 333.361 287.474 332.56V135.312C287.474 132.64 285.604 129.432 283.333 128.23L169.274 62.4805C168.206 61.8123 167.271 61.8123 166.469 62.0796C166.336 62.0796 166.202 62.2132 166.069 62.3469C165.935 62.4805 165.935 62.4805 165.802 62.6141L165.668 62.7478C165.668 62.8814 165.535 62.8814 165.535 63.015C165.401 63.1487 165.401 63.4159 165.267 63.5496C165.267 63.6832 165.134 63.8168 165.134 63.9505C165.134 63.9505 165.134 63.9505 165.134 64.0841C165.134 64.2178 165.134 64.3514 165 64.485C165.134 64.485 165.134 64.6187 165.134 64.8859Z"
    //                     fill="#455A64" />
    //                   <path
    //                     id="Vector_147"
    //                     opacity="0.8"
    //                     d="M167.538 63.2819C167.404 63.2819 167.271 63.2819 167.137 63.4156H167.004L166.87 63.5492L166.737 63.6828L166.603 64.0837V64.2174C166.603 64.351 166.603 64.4846 166.603 64.6183V263.068C166.603 265.206 168.206 268.013 170.075 269.082L284.001 334.831C284.402 334.965 284.535 335.098 284.669 335.098C284.669 335.098 284.802 335.098 284.936 335.098H285.07L285.203 334.965H285.337C285.47 334.831 285.604 334.564 285.737 334.296C286.005 333.896 286.004 333.361 286.004 332.693V135.312C286.004 133.174 284.402 130.367 282.532 129.298L168.606 63.5492C168.206 63.4156 167.805 63.2819 167.538 63.2819ZM167.538 61.9456C168.072 61.9456 168.74 62.0792 169.408 62.4801L283.333 128.229C285.604 129.566 287.474 132.773 287.474 135.312V332.693C287.474 333.495 287.34 334.163 287.073 334.831C286.939 335.232 286.672 335.633 286.272 335.9C286.138 336.034 286.138 336.034 286.004 336.167C285.871 336.167 285.871 336.301 285.737 336.301C285.604 336.301 285.604 336.435 285.47 336.435C285.337 336.435 285.203 336.568 285.07 336.568C284.936 336.568 284.669 336.702 284.535 336.702C284.135 336.702 283.734 336.568 283.2 336.301L169.274 270.552C167.004 269.216 165.134 266.008 165.134 263.469V64.8856C165.134 64.7519 165.134 64.4847 165.134 64.351C165.134 64.2174 165.134 64.0837 165.267 63.9501C165.267 63.9501 165.267 63.9501 165.267 63.8165C165.267 63.6828 165.267 63.5492 165.401 63.4156C165.401 63.2819 165.535 63.0146 165.668 62.881C165.668 62.7474 165.802 62.7474 165.802 62.6137L165.935 62.4801C166.069 62.3465 166.069 62.3465 166.202 62.2128C166.336 62.0792 166.469 62.0792 166.603 61.9456C166.87 61.9456 167.137 61.9456 167.538 61.9456Z"
    //                     fill="#455A64" />
    //                 </g>
    //               </g>
    //               <g id="Group_20" opacity="0.8">
    //                 <g id="XMLID 296_6" opacity="0.8">
    //                   <path
    //                     id="Vector_148"
    //                     opacity="0.8"
    //                     d="M165.134 64.8859V263.336C165.134 266.009 167.004 269.216 169.274 270.419L283.2 336.168C283.734 336.435 284.135 336.569 284.535 336.569C284.669 336.569 284.936 336.569 285.07 336.435C285.203 336.435 285.337 336.301 285.47 336.301C285.604 336.301 285.604 336.168 285.737 336.168C285.871 336.168 285.871 336.034 286.004 336.034C286.138 335.9 286.138 335.901 286.272 335.767C286.539 335.5 286.806 335.099 287.073 334.698C287.34 334.03 287.474 333.361 287.474 332.56V135.312C287.474 132.64 285.604 129.432 283.333 128.23L169.274 62.4805C168.206 61.8123 167.271 61.8123 166.469 62.0796C166.336 62.0796 166.202 62.2132 166.069 62.3469C165.935 62.4805 165.935 62.4805 165.802 62.6141L165.668 62.7478C165.668 62.8814 165.534 62.8814 165.534 63.015C165.401 63.1487 165.401 63.4159 165.267 63.5496C165.267 63.6832 165.134 63.8168 165.134 63.9505C165.134 63.9505 165.134 63.9505 165.134 64.0841C165.134 64.2178 165.134 64.3514 165 64.485C165.134 64.485 165.134 64.6187 165.134 64.8859Z"
    //                     fill="white" />
    //                   <rect
    //                     id="angular_text"
    //                     width="130.481"
    //                     height="56.0042"
    //                     rx="5"
    //                     transform="matrix(0.866025 0.5 -2.20305e-08 1 168.001 149.939)"
    //                     fill="white" />
    //                   <g id="Group 795_3">
    //                     <rect
    //                       id="Rectangle 843_6"
    //                       width="78.5342"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 111.927)"
    //                       fill="#375AD7" />
    //                     <path
    //                       id="XMLID 198_6"
    //                       d="M213.757 118.391C213.623 118.391 213.49 118.391 213.49 118.391C213.356 118.525 213.356 118.658 213.356 118.792V121.064C213.356 121.197 213.356 121.465 213.49 121.598C213.623 121.732 213.623 121.866 213.757 121.999L215.093 122.801C215.226 122.801 215.36 122.801 215.36 122.801C215.493 122.667 215.493 122.667 215.493 122.4V120.128C215.493 119.995 215.493 119.727 215.36 119.594C215.226 119.46 215.226 119.327 215.093 119.193L213.757 118.391Z"
    //                       fill="#375AD7" />
    //                     <path
    //                       id="XMLID 196_6"
    //                       d="M209.082 115.717C208.949 115.717 208.815 115.717 208.815 115.717C208.681 115.851 208.681 115.985 208.681 116.118V118.39C208.681 118.524 208.681 118.791 208.815 118.925C208.949 119.058 208.949 119.192 209.082 119.325L210.418 120.127C210.551 120.127 210.685 120.127 210.685 120.127C210.818 119.994 210.818 119.994 210.818 119.726V117.455C210.818 117.321 210.818 117.054 210.685 116.92C210.551 116.786 210.551 116.653 210.418 116.519L209.082 115.717Z"
    //                       fill="#375AD7" />
    //                     <path
    //                       id="XMLID 194_6"
    //                       d="M204.408 113.045C204.275 113.045 204.141 113.045 204.141 113.045C204.008 113.179 204.008 113.313 204.008 113.446V115.718C204.008 115.852 204.008 116.119 204.141 116.253C204.275 116.386 204.275 116.52 204.408 116.654L205.744 117.455C205.877 117.455 206.011 117.455 206.011 117.455C206.145 117.322 206.145 117.322 206.145 117.054V114.783C206.145 114.649 206.145 114.382 206.011 114.248C205.877 114.114 205.877 113.981 205.744 113.847L204.408 113.045Z"
    //                       fill="#375AD7" />
    //                     <g id="&#60;/&#62;_6">
    //                       <path
    //                         d="M188.944 106.891C188.944 107.736 188.124 107.761 187.521 106.935L183.57 101.526C183.214 101.039 183.001 100.45 183.001 99.9514V99.9514C183.001 99.4845 183.188 99.1518 183.507 99.0498L187.534 97.7644C188.108 97.5811 188.944 98.5362 188.944 99.3756V99.3756C188.944 99.7001 188.808 99.9268 188.58 99.9848L186.921 100.407C185.928 100.66 185.97 102.406 187.001 103.762L188.524 105.764C188.785 106.108 188.944 106.533 188.944 106.891V106.891Z"
    //                         fill="#375AD7" />
    //                       <path
    //                         d="M193.457 97.965C193.978 98.2659 194.362 99.0502 194.241 99.5653L191.366 111.749C191.279 112.117 190.955 112.225 190.582 112.01V112.01C190.061 111.709 189.677 110.925 189.798 110.41L192.673 98.2262C192.76 97.8578 193.084 97.7498 193.457 97.965V97.965Z"
    //                         fill="#375AD7" />
    //                       <path
    //                         d="M195.066 110.426C195.066 110.068 195.224 109.825 195.485 109.784L197.008 109.54C198.04 109.375 198.081 107.677 197.089 106.278L195.429 103.939C195.201 103.617 195.066 103.235 195.066 102.91V102.91C195.066 102.071 195.901 102.081 196.476 102.927L200.502 108.862C200.821 109.333 201.008 109.881 201.008 110.348V110.348C201.008 110.846 200.795 111.19 200.44 111.266L196.489 112.113C195.885 112.242 195.066 111.27 195.066 110.426V110.426Z"
    //                         fill="#375AD7" />
    //                     </g>
    //                     <rect
    //                       id="Rectangle 844_6"
    //                       width="30.5578"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 219.537 152.878)"
    //                       fill="#507AD0" />
    //                     <rect
    //                       id="Rectangle 845_6"
    //                       width="35.6973"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 124.854)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 868_4"
    //                       width="86.6025"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 200.001 159.102)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 847_6"
    //                       width="50.8068"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 209.001 183)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 869_2"
    //                       width="34.641"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 247.001 205.083)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 848_6"
    //                       width="39.2598"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 161.055)"
    //                       fill="#507AD0" />
    //                     <rect
    //                       id="Rectangle 858_6"
    //                       width="22.1324"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 142.954)"
    //                       fill="#507AD0" />
    //                     <rect
    //                       id="Rectangle 849_6"
    //                       width="38.1051"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 179.154)"
    //                       fill="#507AD0" />
    //                     <rect
    //                       id="Rectangle 851_6"
    //                       width="47.6517"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 214.001 203)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 852_6"
    //                       width="73.9008"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 205.001 216.882)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 853_6"
    //                       width="58.9882"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 212)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 855_6"
    //                       width="50.8068"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 227.001 243.846)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 870_2"
    //                       width="50.8068"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 203.001 248)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 871_2"
    //                       width="32.3316"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 250.001 275.083)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 854_6"
    //                       width="31.1769"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 230)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 856_6"
    //                       width="66.9726"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 247)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 872_2"
    //                       width="43.8786"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 233.001 282.773)"
    //                       fill="white" />
    //                     <rect
    //                       id="Rectangle 857_5"
    //                       width="44.3195"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.619 259.837)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 873"
    //                       width="51.9615"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 212.001 283)"
    //                       fill="#375AD7" />
    //                     <rect
    //                       id="Rectangle 850_6"
    //                       width="34.641"
    //                       height="5.17128"
    //                       rx="2.58564"
    //                       transform="matrix(0.866025 0.5 -2.20305e-08 1 171.001 197.253)"
    //                       fill="#507AD0" />
    //                   </g>
    //                 </g>
    //               </g>
    //             </g>
    //             <g id="XMLID 212_6">
    //               <g id="XMLID 216_6">
    //                 <path
    //                   id="Vector_149"
    //                   d="M166.603 62.08C167.405 61.6791 168.339 61.8127 169.408 62.4809L283.333 128.23C285.604 129.566 287.474 132.774 287.474 135.313V148.81L292.683 145.736V132.373C292.683 129.7 290.813 126.493 288.542 125.29L174.617 59.5409C173.415 58.8727 172.346 58.8727 171.678 59.2736C170.877 59.6745 167.405 61.6791 166.603 62.08Z"
    //                   fill="#375AD7" />
    //               </g>
    //               <path
    //                 id="XMLID 215_6"
    //                 d="M165.134 64.7528C165.134 62.2137 167.003 61.1446 169.274 62.3473L283.2 128.096C285.47 129.433 287.34 132.64 287.34 135.179V148.676L165.267 78.2501V64.7528H165.134Z"
    //                 fill="#375AD7" />
    //               <g id="XMLID 214_6" opacity="0.4">
    //                 <path
    //                   id="Vector_150"
    //                   opacity="0.4"
    //                   d="M169.274 62.6213L283.2 128.37C284.268 129.039 285.337 130.108 286.138 131.444L291.347 128.37C290.545 127.034 289.61 125.965 288.542 125.297L174.616 59.5477C173.414 58.8795 172.346 58.8795 171.678 59.2804C170.877 59.6813 167.538 61.6859 166.603 62.0868C167.271 61.9532 168.206 61.9532 169.274 62.6213Z"
    //                   fill="white" />
    //               </g>
    //               <path
    //                 id="XMLID 213_6"
    //                 opacity="0.15"
    //                 d="M292.549 145.736V132.372C292.549 131.036 292.015 129.566 291.347 128.23L286.138 131.303C286.939 132.64 287.34 134.11 287.34 135.446V148.943L292.549 145.736Z"
    //                 fill="black" />
    //             </g>
    //             <g id="XMLID 208_6">
    //               <path
    //                 id="XMLID 211_6"
    //                 d="M189.976 80.1204C191.311 80.9222 192.246 83.0604 192.246 85.0649C192.246 87.0695 191.178 88.0049 189.976 87.3368C188.64 86.5349 187.705 84.3968 187.705 82.3922C187.705 80.3877 188.774 79.4522 189.976 80.1204Z"
    //                 fill="white" />
    //               <path
    //                 id="XMLID 210_6"
    //                 d="M181.562 75.3095C182.897 76.1113 183.832 78.2495 183.832 80.2541C183.832 82.2586 182.764 83.1941 181.562 82.5259C180.226 81.7241 179.291 79.5859 179.291 77.5813C179.291 75.5768 180.36 74.5077 181.562 75.3095Z"
    //                 fill="white" />
    //               <path
    //                 id="XMLID 209_6"
    //                 d="M173.28 70.4987C174.616 71.3005 175.551 73.4387 175.551 75.4433C175.551 77.4478 174.482 78.3833 173.28 77.7151C171.945 76.9133 171.01 74.7751 171.01 72.7705C171.01 70.766 171.945 69.6969 173.28 70.4987Z"
    //                 fill="white" />
    //             </g>
    //             <path
    //               id="XMLID 207_6"
    //               opacity="0.65"
    //               d="M285.871 336.168C286.005 336.034 286.138 336.034 286.405 335.901C286.806 335.633 287.207 335.366 287.741 335.232C288.008 335.099 288.142 334.965 288.409 334.832C288.809 334.564 289.21 334.431 289.477 334.163C289.878 333.896 290.145 333.762 290.412 333.629C290.679 333.495 290.813 333.362 290.946 333.362C291.213 333.228 291.481 333.094 291.614 332.827C291.881 332.56 292.015 332.292 292.148 332.025C292.415 331.357 292.683 330.689 292.683 329.887V145.736L287.474 148.81V332.693C287.474 333.495 287.34 334.163 287.073 334.832C286.94 335.232 286.672 335.633 286.272 335.901C286.138 335.901 286.005 336.034 285.871 336.168C285.871 336.034 285.871 336.168 285.871 336.168Z"
    //               fill="white" />
    //           </g>
    //         </g>
    //       </g>
    //     </g>
    //   </g>
    //   <defs>
    //     <linearGradient
    //       id="paint0_linear_main"
    //       x1="108.746"
    //       y1="427.075"
    //       x2="89.6221"
    //       y2="457.532"
    //       gradientUnits="userSpaceOnUse">
    //       <stop stopColor="#FFE052" />
    //       <stop offset="1" stopColor="#FFC331" />
    //     </linearGradient>
    //     <clipPath id="clip0">
    //       <rect width="632" height="588" transform="matrix(-1 0 0 1 632 0)" fill="white" />
    //     </clipPath>
    //     <clipPath id="clip1">
    //       <rect
    //         width="41.5692"
    //         height="25.1603"
    //         transform="matrix(0.866025 -0.5 2.20305e-08 1 86 343.785)"
    //         fill="white" />
    //     </clipPath>
    //     <clipPath id="clip2">
    //       <rect
    //         width="41.5692"
    //         height="25.1603"
    //         transform="matrix(0.866025 -0.5 2.20305e-08 1 86 343.785)"
    //         fill="white" />
    //     </clipPath>
    //     <clipPath id="clip3">
    //       <rect
    //         width="39.945"
    //         height="39.737"
    //         transform="matrix(-0.866025 0.5 2.20305e-08 1 122.594 403)"
    //         fill="white" />
    //     </clipPath>
    //     <clipPath id="clip4">
    //       <rect
    //         width="39.945"
    //         height="39.737"
    //         transform="matrix(-0.866025 0.5 2.20305e-08 1 122.594 403)"
    //         fill="white" />
    //     </clipPath>
    //   </defs>
    // </svg>
  )
}
